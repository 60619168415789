import React from "react";
import FAQ from "./FAQ";
import Precios from "./Precios";
import { useLanguage } from "./useLanguaje";

export default function VerPrecios() {
  const { currentLanguage } = useLanguage();
  const texts = {
    es: {
      userOpinions: "Lo que opinan nuestros usuarios",
    },
    en: {
      userOpinions: "What our users think",
    },
    it: {
      userOpinions: "Cosa pensano i nostri utenti",
    },
  };

  return (
    <>
      <Precios />
      <FAQ />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">
          {texts[currentLanguage].userOpinions}
        </h2>
        <div>
          <div
            className="senja-embed"
            data-id="960749eb-7604-4965-b221-d325ecf87cbb"
            data-mode="shadow"
            data-lazyload="false"
            style={{ display: "block" }}
          ></div>
          <script
            src="https://widget.senja.io/widget/960749eb-7604-4965-b221-d325ecf87cbb/platform.js"
            type="text/javascript"
            async
          ></script>
        </div>
      </div>
    </>
  );
}
