import { useLanguage } from "../useLanguaje";

const SEOSumarize = () => {
  const { currentLanguage } = useLanguage();

const texts = {
  es: {
    title1: "Generador de Resúmenes Inteligente",
    desc1: "Obtén resúmenes concisos y eficientes de tus documentos con nuestro Generador de Resúmenes. Sube un documento en PDF, varias imágenes o introduce texto directamente para recibir un resumen claro y preciso. Esta herramienta es ideal para estudiantes, profesionales y cualquier persona que necesite sintetizar información rápidamente.",
    list1: [
      "Soporte para documentos en PDF y texto directo",
      "Resúmenes rápidos y precisos",
      "Ideal para diversas necesidades de información",
    ],
    title2: "Fácil Uso y Flexibilidad",
    desc2: "Nuestro generador de resúmenes es extremadamente fácil de usar. Simplemente sube tu documento en PDF, imágenes o escribe el texto directamente en nuestro editor. La herramienta procesará el contenido y generará un resumen que captura los puntos clave de manera efectiva.",
    list2: [
      "Interfaz intuitiva y fácil de navegar",
      "Procesamiento rápido de documentos",
      "Resumen generado en segundos",
    ],
    title3: "Versatilidad para Diferentes Tipos de Documentos",
    desc3: "Nuestro generador de resúmenes es versátil y admite una amplia gama de documentos. Ya sea que estés trabajando con textos académicos, informes de trabajo o cualquier otro tipo de contenido, nuestra herramienta puede ayudarte a obtener un resumen preciso y útil.",
    list3: [
      "Compatible con PDFs y múltiples formatos de imagen",
      "Adaptado para diferentes áreas y usos",
      "Perfecto para estudiantes, profesionales y más",
    ],
    title4: "Beneficios del Generador de Resúmenes",
    list4: [
      "Ahorro de tiempo al sintetizar información",
      "Mejora en la comprensión de los temas clave",
      "Reducción de la sobrecarga de información",
      "Acceso rápido a los puntos más importantes",
      "Facilidad de uso en cualquier dispositivo",
    ],
    title5: "Optimiza Tu Procesamiento de Información",
    desc5: "Nuestro Generador de Resúmenes es la herramienta ideal para optimizar el procesamiento de información. Ya sea que necesites resumir material académico, documentos de trabajo o cualquier otro tipo de contenido, nuestra plataforma está diseñada para ayudarte a extraer los puntos clave de manera rápida y eficiente. ¡Comienza a utilizar el Generador de Resúmenes hoy mismo y transforma la manera en que manejas la información!",
  },
  it: {
    title1: "Generatore di Riepiloghi Intelligente",
    desc1: "Ottieni riepiloghi concisi ed efficienti dei tuoi documenti con il nostro Generatore di Riepiloghi. Carica un documento PDF, più immagini o inserisci direttamente il testo per ricevere un riepilogo chiaro e preciso. Questo strumento è ideale per studenti, professionisti e chiunque abbia bisogno di sintetizzare rapidamente le informazioni.",
    list1: [
      "Supporto per documenti PDF e testo diretto",
      "Riepiloghi rapidi e precisi",
      "Ideale per diverse esigenze informative",
    ],
    title2: "Facilità d'uso e flessibilità",
    desc2: "Il nostro generatore di riepiloghi è estremamente facile da usare. Carica semplicemente il tuo documento PDF, le immagini o scrivi il testo direttamente nel nostro editor. Lo strumento elaborerà il contenuto e genererà un riepilogo che cattura efficacemente i punti chiave.",
    list2: [
      "Interfaccia intuitiva e facile da navigare",
      "Elaborazione rapida dei documenti",
      "Riepilogo generato in pochi secondi",
    ],
    title3: "Versatilità per diversi tipi di documenti",
    desc3: "Il nostro generatore di riepiloghi è versatile e supporta un'ampia gamma di documenti. Che tu stia lavorando con testi accademici, rapporti di lavoro o qualsiasi altro tipo di contenuto, il nostro strumento può aiutarti a ottenere un riepilogo preciso e utile.",
    list3: [
      "Compatibile con PDF e più formati di immagine",
      "Adattato per diverse aree e usi",
      "Perfetto per studenti, professionisti e altro ancora",
    ],
    title4: "Vantaggi del Generatore di Riepiloghi",
    list4: [
      "Risparmio di tempo nella sintesi delle informazioni",
      "Miglioramento nella comprensione degli argomenti chiave",
      "Riduzione del sovraccarico di informazioni",
      "Accesso rapido ai punti più importanti",
      "Facilità d'uso su qualsiasi dispositivo",
    ],
    title5: "Ottimizza l'elaborazione delle informazioni",
    desc5: "Il nostro Generatore di Riepiloghi è lo strumento ideale per ottimizzare l'elaborazione delle informazioni. Che tu debba riassumere materiale accademico, documenti di lavoro o qualsiasi altro tipo di contenuto, la nostra piattaforma è progettata per aiutarti a estrarre rapidamente ed efficientemente i punti chiave. Inizia a utilizzare il Generatore di Riepiloghi oggi stesso e trasforma il modo in cui gestisci le informazioni!",
  },
  en: {
    title1: "Intelligent Summary Generator",
    desc1: "Get concise and efficient summaries of your documents with our Summary Generator. Upload a PDF document, multiple images, or enter text directly to receive a clear and precise summary. This tool is ideal for students, professionals, and anyone who needs to quickly synthesize information.",
    list1: [
      "Support for PDF documents and direct text",
      "Fast and accurate summaries",
      "Ideal for various information needs",
    ],
    title2: "Easy Use and Flexibility",
    desc2: "Our summary generator is extremely easy to use. Simply upload your PDF document, images, or write the text directly in our editor. The tool will process the content and generate a summary that effectively captures the key points.",
    list2: [
      "Intuitive and easy-to-navigate interface",
      "Fast document processing",
      "Summary generated in seconds",
    ],
    title3: "Versatility for Different Types of Documents",
    desc3: "Our summary generator is versatile and supports a wide range of documents. Whether you're working with academic texts, work reports, or any other type of content, our tool can help you obtain a precise and useful summary.",
    list3: [
      "Compatible with PDFs and multiple image formats",
      "Adapted for different areas and uses",
      "Perfect for students, professionals, and more",
    ],
    title4: "Benefits of the Summary Generator",
    list4: [
      "Time-saving by synthesizing information",
      "Improvement in understanding key topics",
      "Reduction of information overload",
      "Quick access to the most important points",
      "Ease of use on any device",
    ],
    title5: "Optimize Your Information Processing",
    desc5: "Our Summary Generator is the ideal tool to optimize information processing. Whether you need to summarize academic material, work documents, or any other type of content, our platform is designed to help you extract key points quickly and efficiently. Start using the Summary Generator today and transform the way you handle information!",
  },
};

  const content = texts[currentLanguage] || texts.es;

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title1}</h2>
        <p className="mb-4 text-gray-600">{content.desc1}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list1.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title2}</h2>
        <p className="mb-4 text-gray-600">{content.desc2}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list2.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title3}</h2>
        <p className="mb-4 text-gray-600">{content.desc3}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list3.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title4}</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list4.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title5}</h2>
        <p className="text-gray-600">{content.desc5}</p>
      </section>
    </div>
  );
};

export default SEOSumarize;