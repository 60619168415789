import { useLanguage } from "../useLanguaje";

const SEOCorrect = () => {
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      title: "Corrige tu Examen con IA",
      description:
        "Simplifica y mejora el proceso de corrección de exámenes con nuestra innovadora inteligencia artificial. Sube una imagen o un PDF de tu examen resuelto y deja que nuestra IA personalizada lo corrija por ti. Edita cualquier error detectado en el texto y presiona 'corregir examen' cuando estés listo. Obtén resultados precisos y descarga el examen corregido en formato PDF.",
      listItems: [
        "Sube imágenes o documentos PDF de exámenes resueltos",
        "Corrección precisa y rápida con IA",
        "Opción para editar texto antes de la corrección final",
        "Resultados disponibles para ver y descargar en PDF",
      ],
      howItWorksTitle: "Cómo Funciona",
      howItWorksDescription:
        "Utilizar nuestro sistema de corrección de exámenes con IA es sencillo y efectivo. Sube una imagen o un documento PDF de tu examen resuelto. La IA analizará y corregirá el contenido automáticamente. Puedes revisar y editar cualquier error detectado antes de confirmar la corrección final. Una vez listo, podrás ver los resultados y descargar el examen corregido en formato PDF.",
      advantagesTitle: "Ventajas de Corregir con IA",
      advantagesList: [
        "Ahorro de tiempo en la corrección de exámenes",
        "Precisión y consistencia en las correcciones",
        "Reducción de errores humanos",
        "Facilidad para editar y ajustar correcciones",
        "Resultados rápidos y accesibles en formato PDF",
      ],
      applicationsTitle: "Aplicaciones del Corrector de Exámenes con IA",
      applicationsDescription:
        "Nuestro corrector de exámenes con IA es una herramienta versátil, útil en diversos contextos educativos y profesionales. Ya sea que seas estudiante, profesor o profesional que necesita verificar respuestas en documentos, esta herramienta te ofrece una solución eficiente y precisa para la corrección de exámenes.",
      applicationsList: [
        "Estudiantes que desean verificar sus respuestas",
        "Profesores que necesitan corregir exámenes rápidamente",
        "Profesionales que requieren revisar documentos con precisión",
        "Cualquiera que necesite una corrección rápida y precisa",
      ],
      improveProcessTitle: "Mejora tu Proceso de Corrección con IA",
      improveProcessDescription:
        "Nuestro corrector de exámenes con IA está diseñado para transformar tu forma de corregir y revisar exámenes. Olvídate del tedioso proceso manual y aprovecha la precisión y rapidez de nuestra inteligencia artificial. Sube tu examen resuelto, edita cualquier error detectado y obtén resultados exactos y descargables en PDF. ¡Comienza a usar nuestro corrector de exámenes con IA hoy mismo y mejora tu eficiencia en la corrección de exámenes!",
    },
    it: {
      title: "Correggi il tuo Esame con IA",
      description:
        "Semplifica e migliora il processo di correzione degli esami con la nostra innovativa intelligenza artificiale. Carica un'immagine o un PDF del tuo esame completato e lascia che la nostra IA personalizzata lo corregga per te. Modifica eventuali errori rilevati nel testo e premi 'correggi esame' quando sei pronto. Ottieni risultati accurati e scarica l'esame corretto in formato PDF.",
      listItems: [
        "Carica immagini o documenti PDF di esami completati",
        "Correzione precisa e veloce con IA",
        "Opzione per modificare il testo prima della correzione finale",
        "Risultati disponibili per la visualizzazione e il download in PDF",
      ],
      howItWorksTitle: "Come Funziona",
      howItWorksDescription:
        "Utilizzare il nostro sistema di correzione degli esami con IA è semplice ed efficace. Carica un'immagine o un documento PDF del tuo esame completato. L'IA analizzerà e correggerà automaticamente il contenuto. Puoi rivedere e modificare eventuali errori rilevati prima di confermare la correzione finale. Una volta pronto, puoi visualizzare i risultati e scaricare l'esame corretto in formato PDF.",
      advantagesTitle: "Vantaggi della Correzione con IA",
      advantagesList: [
        "Risparmio di tempo nella correzione degli esami",
        "Precisione e coerenza nelle correzioni",
        "Riduzione degli errori umani",
        "Facilità di modifica e regolazione delle correzioni",
        "Risultati rapidi e accessibili in formato PDF",
      ],
      applicationsTitle: "Applicazioni del Correttore di Esami con IA",
      applicationsDescription:
        "Il nostro correttore di esami con IA è uno strumento versatile, utile in vari contesti educativi e professionali. Che tu sia uno studente, un insegnante o un professionista che ha bisogno di verificare le risposte nei documenti, questo strumento ti offre una soluzione efficiente e precisa per la correzione degli esami.",
      applicationsList: [
        "Studenti che vogliono verificare le loro risposte",
        "Insegnanti che devono correggere gli esami rapidamente",
        "Professionisti che richiedono una revisione precisa dei documenti",
        "Chiunque abbia bisogno di una correzione rapida e precisa",
      ],
      improveProcessTitle: "Migliora il tuo Processo di Correzione con IA",
      improveProcessDescription:
        "Il nostro correttore di esami con IA è progettato per trasformare il tuo modo di correggere e rivedere gli esami. Dimentica il tedioso processo manuale e approfitta della precisione e velocità della nostra intelligenza artificiale. Carica il tuo esame completato, modifica eventuali errori rilevati e ottieni risultati esatti e scaricabili in PDF. Inizia a usare il nostro correttore di esami con IA oggi stesso e migliora la tua efficienza nella correzione degli esami!",
    },
    en: {
      title: "Correct Your Exam with AI",
      description:
        "Simplify and enhance the exam correction process with our innovative artificial intelligence. Upload an image or PDF of your completed exam and let our personalized AI correct it for you. Edit any detected errors in the text and press 'correct exam' when you're ready. Get accurate results and download the corrected exam in PDF format.",
      listItems: [
        "Upload images or PDF documents of completed exams",
        "Accurate and fast correction with AI",
        "Option to edit text before final correction",
        "Results available to view and download in PDF",
      ],
      howItWorksTitle: "How It Works",
      howItWorksDescription:
        "Using our AI exam correction system is simple and effective. Upload an image or PDF document of your completed exam. The AI will automatically analyze and correct the content. You can review and edit any detected errors before confirming the final correction. Once ready, you can view the results and download the corrected exam in PDF format.",
      advantagesTitle: "Advantages of Correcting with AI",
      advantagesList: [
        "Time-saving in exam correction",
        "Accuracy and consistency in corrections",
        "Reduction of human errors",
        "Ease of editing and adjusting corrections",
        "Fast and accessible results in PDF format",
      ],
      applicationsTitle: "Applications of the AI Exam Corrector",
      applicationsDescription:
        "Our AI exam corrector is a versatile tool, useful in various educational and professional contexts. Whether you are a student, teacher, or professional needing to verify answers in documents, this tool offers you an efficient and accurate solution for exam correction.",
      applicationsList: [
        "Students who want to verify their answers",
        "Teachers who need to correct exams quickly",
        "Professionals who require precise document review",
        "Anyone needing a quick and accurate correction",
      ],
      improveProcessTitle: "Improve Your Correction Process with AI",
      improveProcessDescription:
        "Our AI exam corrector is designed to transform your way of correcting and reviewing exams. Forget the tedious manual process and take advantage of the precision and speed of our artificial intelligence. Upload your completed exam, edit any detected errors, and get exact and downloadable results in PDF. Start using our AI exam corrector today and improve your efficiency in exam correction!",
    },
  };

  const content = texts[currentLanguage];

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title}
        </h2>
        <p className="mb-4 text-gray-600">{content.description}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.listItems.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.howItWorksTitle}
        </h2>
        <p className="mb-4 text-gray-600">{content.howItWorksDescription}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.listItems.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.advantagesTitle}
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.advantagesList.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.applicationsTitle}
        </h2>
        <p className="mb-4 text-gray-600">{content.applicationsDescription}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.applicationsList.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.improveProcessTitle}
        </h2>
        <p className="text-gray-600">{content.improveProcessDescription}</p>
      </section>
    </div>
  );
};

export default SEOCorrect;
