 // Start of Selection
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje"; // Added import
const apiUrl = process.env.REACT_APP_API_URL;

export default function Bienvenido({ setShowNoTokenModal }) {
  const { currentLanguage } = useLanguage(); // Using the useLanguage hook
  const translations = useMemo(() => ({
    es: {
      steps: ["Datos", "Más info", "IA", "¡EMPIEZA YA!"],
      welcomeTitle: "¡Bienvenido a FotoExamen!",
      welcomeDescription:
        "Para mejorar tu experiencia con nuestra IA avanzada y que los resultados de tus exámenes sean mejores y más precisos. Por favor, proporcione la siguiente información necesaria:",
      form: {
        nombre: "Nombre*",
        edad: "Edad*",
        estudios: "Estudios*",
        ciudad: "Ciudad*",
        dedicacion: "¿A qué se dedica?*",
        otroDedicacion: "Por favor, especifique*",
        encontro: "¿Cómo nos encontró?*",
        otroEncontro: "Por favor, especifique*",
        atencion: "¿Qué herramienta le llama más la atención?*",
        otroAtencion: "Por favor, especifique*",
      },
      placeholders: {
        otroDedicacion: "Especifique su ocupación",
        otroEncontro: "Especifique como nos encontró",
        otroAtencion: "Especifique que le llama más de la aplicación",
      },
      buttons: {
        siguiente: "Siguiente",
        anterior: "Anterior",
        finalizar: "Finalizar",
      },
      alerts: {
        camposObligatorios: "Por favor, complete todos los campos obligatorios.",
        especificarOcupacion: "Por favor, especifique su ocupación.",
        especificarEncontro: "Por favor, especifique cómo nos encontró.",
        especificarAtencion: "Por favor, especifique qué le llama más de la aplicación.",
        errorRecuperandoDatos: "Error recuperando sus datos. Por favor, recarge la página.",
      },
      customizeIATitle: "Personaliza tu IA",
      customizeIADescription:
        "Actualmente en fase de desarrollo, puede que no note mucha personalidad y diferencia en las repuestas al ajustar estos parámetros, pero puedes indicarnos como quieres que sea tu IA ideal:",
      advantagesTitle: "¿Qué ventajas ofrece FotoExamen?",
      advantagesDescription:
        "Descubre las bueno de usar nuestra IA para mejorar tus calificaciones:",
      advantagesList: [
        {
          title: "Resolver exámenes:",
          description:
            "Usa tareas, exámenes o ejercicios de años anteriores para preparar tus próximos exámenes.",
        },
        {
          title: "Crear esquemas:",
          description:
            "Usa tu temario para crear tus propios esquemas y así estudiar de manera eficaz.",
        },
        {
          title: "Charla con tutores de IA:",
          description:
            "Tutores disponibles de matemáticas, biología, física... para consultar tus dudas.",
        },
        {
          title: "Crear un tutor personalizado de tus apuntes:",
          description:
            "Si tienes dudas de tus apuntes, súbele tu temario y hazle cualquier pregunta como si fuera tu profesor.",
        },
        {
          title: "Generar quizzes interactivos:",
          description:
            "Crea quizzes con varios tipos de preguntas para probar tus habilidades o para aprender de forma divertida.",
        },
        {
          title: "Tomar apuntes:",
          description:
            "Sube un audio de tu clase y obtén apuntes de forma automática.",
        },
        {
          title: "Resumir, subrayar y corregir:",
          description: "Entre muchas otras cosas más.",
        },
      ],
      finalizarMessage:
        "NO PIERDAS EL TIEMPO Y EMPIEZA YA A MEJORAR TUS CALIFICACIONES 🔟.",
      footer: {
        modificarDatos:
          'Puedes modificar todo los datos en cualquier momento desde "Tu perfil".',
      },
    },
    it: {
      steps: ["Dati", "Più info", "IA", "INIZIA ORA!"],
      welcomeTitle: "Benvenuto su RisolviCompiti!",
      welcomeDescription:
        "Per migliorare la tua esperienza con la nostra IA avanzata e garantire che i risultati dei tuoi esami siano migliori e più precisi. Si prega di fornire le seguenti informazioni necessarie:",
      form: {
        nombre: "Nome*",
        edad: "Età*",
        estudios: "Studi*",
        ciudad: "Città*",
        dedicacion: "A cosa ti dedichi?*",
        otroDedicacion: "Per favore, specifica*",
        encontro: "Come ci hai trovato?*",
        otroEncontro: "Per favore, specifica*",
        atencion: "Quale strumento ti interessa di più?*",
        otroAtencion: "Per favore, specifica*",
      },
      placeholders: {
        otroDedicacion: "Specifica la tua occupazione",
        otroEncontro: "Specifica come ci hai trovato",
        otroAtencion: "Specifica cosa ti interessa di più dell'app",
      },
      buttons: {
        siguiente: "Avanti",
        anterior: "Precedente",
        finalizar: "Fine",
      },
      alerts: {
        camposObligatorios: "Per favore, completa tutti i campi obbligatori.",
        especificarOcupacion: "Per favore, specifica la tua occupazione.",
        especificarEncontro: "Per favore, specifica come ci hai trovato.",
        especificarAtencion: "Per favore, specifica cosa ti interessa di più dell'app.",
        errorRecuperandoDatos: "Errore durante il recupero dei tuoi dati. Per favore, ricarica la pagina.",
      },
      customizeIATitle: "Personalizza la tua IA",
      customizeIADescription:
        "Attualmente in fase di sviluppo, potresti non notare molta personalità e differenza nelle risposte regolando questi parametri, ma puoi indicarci come vuoi che sia la tua IA ideale:",
      advantagesTitle: "Quali vantaggi offre RisolviCompiti?",
      advantagesDescription:
        "Scopri i vantaggi dell'utilizzo della nostra IA per migliorare i tuoi voti:",
      advantagesList: [
        {
          title: "Risolvere esami:",
          description:
            "Utilizza compiti, esami o esercizi di anni precedenti per prepararti ai tuoi prossimi esami.",
        },
        {
          title: "Creare schemi:",
          description:
            "Utilizza il tuo programma per creare i tuoi schemi e studiare in modo efficace.",
        },
        {
          title: "Chatta con tutor IA:",
          description:
            "Tutor disponibili di matematica, biologia, fisica... per consultare i tuoi dubbi.",
        },
        {
          title: "Crea un tutor personalizzato dai tuoi appunti:",
          description:
            "Se hai dubbi sui tuoi appunti, caricali e fai qualsiasi domanda come se fosse il tuo professore.",
        },
        {
          title: "Generare quiz interattivi:",
          description:
            "Crea quiz con diversi tipi di domande per testare le tue capacità o per imparare in modo divertente.",
        },
        {
          title: "Prendere appunti:",
          description:
            "Carica un audio della tua lezione e ottieni appunti automaticamente.",
        },
        {
          title: "Riassumere, sottolineare e correggere:",
          description: "Tra molte altre cose.",
        },
      ],
      finalizarMessage:
        "NON PERDERE TEMPO E INIZIA SUBITO A MIGLIORARE I TUOI VOTI 🔟.",
      footer: {
        modificarDatos:
          'Puoi modificare tutti i dati in qualsiasi momento da "Il tuo profilo".',
      },
    },
    en: {
      steps: ["Data", "More Info", "AI", "GET STARTED NOW!"],
      welcomeTitle: "Welcome to PhotoExamAI!",
      welcomeDescription:
        "To enhance your experience with our advanced AI and ensure your exam results are better and more accurate. Please provide the following necessary information:",
      form: {
        nombre: "Name*",
        edad: "Age*",
        estudios: "Education*",
        ciudad: "City*",
        dedicacion: "What do you do?*",
        otroDedicacion: "Please specify*",
        encontro: "How did you find us?*",
        otroEncontro: "Please specify*",
        atencion: "Which tool interests you the most?*",
        otroAtencion: "Please specify*",
      },
      placeholders: {
        otroDedicacion: "Specify your occupation",
        otroEncontro: "Specify how you found us",
        otroAtencion: "Specify what interests you most about the app",
      },
      buttons: {
        siguiente: "Next",
        anterior: "Previous",
        finalizar: "Finish",
      },
      alerts: {
        camposObligatorios: "Please complete all required fields.",
        especificarOcupacion: "Please specify your occupation.",
        especificarEncontro: "Please specify how you found us.",
        especificarAtencion: "Please specify what interests you most about the app.",
        errorRecuperandoDatos: "Error retrieving your data. Please reload the page.",
      },
      customizeIATitle: "Customize your AI",
      customizeIADescription:
        "Currently under development, you may not notice much personality and difference in responses when adjusting these parameters, but you can tell us how you want your ideal AI to be:",
      advantagesTitle: "What advantages does PhotoExamAI offer?",
      advantagesDescription:
        "Discover the benefits of using our AI to improve your grades:",
      advantagesList: [
        {
          title: "Solve exams:",
          description:
            "Use tasks, exams, or exercises from previous years to prepare for your upcoming exams.",
        },
        {
          title: "Create outlines:",
          description:
            "Use your syllabus to create your own outlines and study effectively.",
        },
        {
          title: "Chat with AI tutors:",
          description:
            "Available tutors in mathematics, biology, physics... to answer your doubts.",
        },
        {
          title: "Create a personalized tutor from your notes:",
          description:
            "If you have questions about your notes, upload your syllabus and ask any question as if it were your teacher.",
        },
        {
          title: "Generate interactive quizzes:",
          description:
            "Create quizzes with various types of questions to test your skills or learn in a fun way.",
        },
        {
          title: "Take notes:",
          description:
            "Upload an audio recording of your class and automatically get notes.",
        },
        {
          title: "Summarize, highlight, and correct:",
          description: "Among many other things.",
        },
      ],
      finalizarMessage:
        "DON'T WASTE TIME AND START IMPROVING YOUR GRADES NOW 🔟.",
      footer: {
        modificarDatos:
          'You can modify all data at any time from "Your Profile".',
      },
    },
  }), []);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nombre: "",
    edad: "",
    estudios: "",
    ciudad: "",
    dedicacion: "",
    otroDedicacion: "",
    encontro: "",
    otroEncontro: "",
    atencion: "",
    otroAtencion: "",
    simpatica: 3,
    directa: 3,
    empatica: 3,
    critica: 3,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const debounceTimeout = useRef(null);
  const [token, clearToken] = useAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      if (!token) {
        return;
      }

      fetch(apiUrl + "/api/easyexams/getprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setFormData(data?.data);
          setIsFirstLoad(false);
        })
        .catch((error) => {
          alert(
            translations[currentLanguage].alerts.errorRecuperandoDatos
          );
        });
    };
    fetchUserData();
  }, [token, setShowNoTokenModal, currentLanguage, translations]);

  useEffect(() => {
    if (isFirstLoad || !token) {
      return;
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const forms = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        forms.append(key, value);
      }
      fetch(apiUrl + "/api/easyexams/saveprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: forms,
        method: "POST",
      })
        .then((res) => res.json())
        .then((data) => console.log(data));
    }, 1000);
  }, [formData, token, isFirstLoad]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSliderChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const nextStep = () => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }
    // Validations for each step
    if (step === 1) {
      if (
        !formData.nombre ||
        !formData.edad ||
        !formData.estudios ||
        !formData.ciudad
      ) {
        alert(translations[currentLanguage].alerts.camposObligatorios);
        return;
      }
    } else if (step === 2) {
      if (
        !formData.dedicacion ||
        !formData.encontro ||
        !formData.atencion
      ) {
        alert(translations[currentLanguage].alerts.camposObligatorios);
        return;
      }
      if (
        (formData.dedicacion === "Otros" ||
          formData.dedicacion === "Otros Estudios") &&
        !formData.otroDedicacion
      ) {
        alert(translations[currentLanguage].alerts.especificarOcupacion);
        return;
      }
      if (formData.encontro === "Otros" && !formData.otroEncontro) {
        alert(translations[currentLanguage].alerts.especificarEncontro);
        return;
      }
      if (formData.atencion === "Otros" && !formData.otroAtencion) {
        alert(translations[currentLanguage].alerts.especificarAtencion);
        return;
      }
    }

    setStep((prevStep) => prevStep + 1);
    if (step + 1 === 5) {
      window.location.href = "/";
    }
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="container mx-auto p-4 my-8 md:p-16">
      <div className="flex justify-center mb-6">
        <div className="steps">
          {translations[currentLanguage].steps.map((stepName, index) => (
            <div
              key={index}
              data-content={
                index === 0
                  ? "📊"
                  : index === 1
                  ? "🔍"
                  : index === 2
                  ? "🤖"
                  : "🚀"
              }
              className={`step ${
                step >= index + 1 ? "step-neutral" : ""
              }`}
            >
              {stepName}
            </div>
          ))}
        </div>
      </div>

      {step === 1 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-1"
        >
          <h2 className="text-2xl font-bold mb-4">
            {translations[currentLanguage].welcomeTitle}
          </h2>
          <p className="mb-4">
            {translations[currentLanguage].welcomeDescription}
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.nombre}
              </label>
              <input
                type="text"
                name="nombre"
                className="input input-bordered w-full"
                value={formData.nombre}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.edad}
              </label>
              <input
                type="number"
                name="edad"
                className="input input-bordered w-full"
                value={formData.edad}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.estudios}
              </label>
              <input
                type="text"
                name="estudios"
                className="input input-bordered w-full"
                value={formData.estudios}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.ciudad}
              </label>
              <input
                type="text"
                name="ciudad"
                className="input input-bordered w-full"
                value={formData.ciudad}
                onChange={handleChange}
              />
            </div>
          </form>
          <button className="btn btn-primary" onClick={nextStep}>
            {translations[currentLanguage].buttons.siguiente}
          </button>
        </motion.div>
      )}

      {step === 2 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-2"
        >
          <h2 className="text-2xl font-bold mb-4">
            {translations[currentLanguage].moreInfoTitle ||
              "Más información"}
          </h2>
          <p className="mb-4">
            {translations[currentLanguage].moreInfoDescription ||
              "Esto nos ayuda a mejorar a nosotros:"}
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.dedicacion}
              </label>
              <select
                name="dedicacion"
                className="select select-bordered w-full"
                value={formData.dedicacion}
                onChange={handleChange}
              >
                <option value="">
                  {currentLanguage === "es"
                    ? "Seleccione una opción"
                    : "Select an option"}
                </option>
                <option value="Familiar">
                  {currentLanguage === "es"
                    ? "Padre, madre o familiar de un estudiante"
                    : "Parent, guardian, or family member of a student"}
                </option>
                <option value="Profesor">
                  {currentLanguage === "es" ? "Profesor" : "Teacher"}
                </option>
                <optgroup
                  label={
                    currentLanguage === "es" ? "Estudiante" : "Student"
                  }
                >
                  <option value="Master">
                    {currentLanguage === "es"
                      ? "Estudiante de Master"
                      : "Master's Student"}
                  </option>
                  <option value="Universidad">
                    {currentLanguage === "es"
                      ? "Estudiante de Universidad"
                      : "University Student"}
                  </option>
                  <option value="Formación Profesional">
                    {currentLanguage === "es"
                      ? "Estudiante de Formación Profesional"
                      : "Vocational Training Student"}
                  </option>
                  <option value="Instituto">
                    {currentLanguage === "es"
                      ? "Estudiante de Instituto"
                      : "Institute Student"}
                  </option>
                  <option value="Colegio">
                    {currentLanguage === "es"
                      ? "Estudiante de Colegio"
                      : "School Student"}
                  </option>
                  <option value="Otros Estudios">
                    {currentLanguage === "es"
                      ? "Otros Estudios"
                      : "Other Studies"}
                  </option>
                </optgroup>
                <option value="Otros">
                  {currentLanguage === "es" ? "Otros" : "Others"}
                </option>
              </select>
            </div>
            {(formData.dedicacion === "Otros" ||
              formData.dedicacion === "Otros Estudios") && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  {translations[currentLanguage].form.otroDedicacion}
                </label>
                <input
                  type="text"
                  name="otroDedicacion"
                  className="input input-bordered w-full"
                  value={formData.otroDedicacion}
                  onChange={handleChange}
                  placeholder={
                    translations[currentLanguage].placeholders.otroDedicacion
                  }
                />
              </div>
            )}

            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.encontro}
              </label>
              <select
                name="encontro"
                className="select select-bordered w-full"
                value={formData.encontro}
                onChange={handleChange}
              >
                <option value="">
                  {currentLanguage === "es"
                    ? "Seleccione una opción"
                    : "Select an option"}
                </option>
                <option value="Recomendación de un amigo">
                  {currentLanguage === "es"
                    ? "Recomendación de un amigo"
                    : "Recommendation from a friend"}
                </option>
                <option value="Busqueda en internet">
                  {currentLanguage === "es"
                    ? "Búsqueda en internet (Google, Bing, etc.)"
                    : "Internet search (Google, Bing, etc.)"}
                </option>
                <optgroup
                  label={
                    currentLanguage === "es"
                      ? "Redes Sociales"
                      : "Social Networks"
                  }
                >
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Tiktok">Tiktok</option>
                  <option value="Youtube">Youtube</option>
                </optgroup>
                <option value="Otros">
                  {currentLanguage === "es"
                    ? "Otros medios"
                    : "Other media"}
                </option>
              </select>
            </div>

            {formData.encontro === "Otros" && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  {translations[currentLanguage].form.otroEncontro}
                </label>
                <input
                  type="text"
                  name="otroEncontro"
                  className="input input-bordered w-full"
                  value={formData.otroEncontro}
                  onChange={handleChange}
                  placeholder={
                    translations[currentLanguage].placeholders.otroEncontro
                  }
                />
              </div>
            )}

            <div>
              <label className="block text-lg font-medium">
                {translations[currentLanguage].form.atencion}
              </label>
              <select
                name="atencion"
                className="select select-bordered w-full"
                value={formData.atencion}
                onChange={handleChange}
              >
                <option value="">
                  {currentLanguage === "es"
                    ? "Seleccione una opción"
                    : "Select an option"}
                </option>
                <option value="Examen">
                  {currentLanguage === "es"
                    ? "Resolver exámenes"
                    : "Solve exams"}
                </option>
                <option value="Esquema">
                  {currentLanguage === "es"
                    ? "Crear esquemas"
                    : "Create outlines"}
                </option>
                <option value="Tutor">
                  {currentLanguage === "es"
                    ? "Chatear con tutores"
                    : "Chat with tutors"}
                </option>
                <option value="Quiz">
                  {currentLanguage === "es"
                    ? "Crear un quiz"
                    : "Create a quiz"}
                </option>
                <option value="Resumen">
                  {currentLanguage === "es"
                    ? "Resumir texto"
                    : "Summarize text"}
                </option>
                <option value="Subrayar">
                  {currentLanguage === "es"
                    ? "Subrayar texto"
                    : "Highlight text"}
                </option>
                <option value="Corregir">
                  {currentLanguage === "es"
                    ? "Corregir exámenes"
                    : "Correct exams"}
                </option>
                <option value="Apuntes">
                  {currentLanguage === "es"
                    ? "Tomar apuntes"
                    : "Take notes"}
                </option>
                <option value="Otros">
                  {currentLanguage === "es"
                    ? "Otras características"
                    : "Other features"}
                </option>
              </select>
            </div>
            {formData.atencion === "Otros" && (
              <div className="mt-4">
                <label className="block text-lg font-medium">
                  {translations[currentLanguage].form.otroAtencion}
                </label>
                <input
                  type="text"
                  name="otroAtencion"
                  className="input input-bordered w-full"
                  value={formData.otroAtencion}
                  onChange={handleChange}
                  placeholder={
                    translations[currentLanguage].placeholders.otroAtencion
                  }
                />
              </div>
            )}
          </form>
          <div className="flex justify-between mt-4">
            <button className="btn btn-primary" onClick={prevStep}>
              {translations[currentLanguage].buttons.anterior}
            </button>
            <button className="btn btn-primary" onClick={nextStep}>
              {translations[currentLanguage].buttons.siguiente}
            </button>
          </div>
        </motion.div>
      )}

      {step === 3 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-3"
        >
          <h2 className="text-2xl font-bold mb-4">
            {translations[currentLanguage].customizeIATitle}
          </h2>
          <p className="mb-4">
            {translations[currentLanguage].customizeIADescription}
          </p>
          <form className="space-y-4">
            <div>
              <label className="block text-lg font-medium">
                {currentLanguage === "es" ? "Simpática" : "Friendly"}
              </label>
              <input
                type="range"
                name="simpatica"
                min="1"
                max="5"
                value={formData.simpatica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>
                  {currentLanguage === "es"
                    ? "1 (Nada simpática)"
                    : "1 (Not friendly)"}{" "}
                </span>
                <span>2</span>
                <span>
                  {currentLanguage === "es"
                    ? "3 (Normalmente simpática)"
                    : "3 (Usually friendly)"}
                </span>
                <span>4</span>
                <span>
                  {currentLanguage === "es"
                    ? "5 (Muy simpática)"
                    : "5 (Very friendly)"}
                </span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">
                {currentLanguage === "es" ? "Directa" : "Direct"}
              </label>
              <input
                type="range"
                name="directa"
                min="1"
                max="5"
                value={formData.directa}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>
                  {currentLanguage === "es"
                    ? "1 (Nada directa)"
                    : "1 (Not direct)"}
                </span>
                <span>2</span>
                <span>
                  {currentLanguage === "es"
                    ? "3 (Normalmente directa)"
                    : "3 (Usually direct)"}
                </span>
                <span>4</span>
                <span>
                  {currentLanguage === "es"
                    ? "5 (Muy directa)"
                    : "5 (Very direct)"}
                </span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">
                {currentLanguage === "es" ? "Empática" : "Empathetic"}
              </label>
              <input
                type="range"
                name="empatica"
                min="1"
                max="5"
                value={formData.empatica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>
                  {currentLanguage === "es"
                    ? "1 (Nada empática)"
                    : "1 (Not empathetic)"}
                </span>
                <span>2</span>
                <span>
                  {currentLanguage === "es"
                    ? "3 (Normalmente empática)"
                    : "3 (Usually empathetic)"}
                </span>
                <span>4</span>
                <span>
                  {currentLanguage === "es"
                    ? "5 (Muy empática)"
                    : "5 (Very empathetic)"}
                </span>
              </div>
            </div>
            <div>
              <label className="block text-lg font-medium">
                {currentLanguage === "es" ? "Crítica" : "Critical"}
              </label>
              <input
                type="range"
                name="critica"
                min="1"
                max="5"
                value={formData.critica}
                onChange={handleSliderChange}
                className="range"
              />
              <div className="w-full flex justify-between text-xs px-2">
                <span>
                  {currentLanguage === "es"
                    ? "1 (Nada crítica)"
                    : "1 (Not critical)"}
                </span>
                <span>2</span>
                <span>
                  {currentLanguage === "es"
                    ? "3 (Normalmente crítica)"
                    : "3 (Usually critical)"}
                </span>
                <span>4</span>
                <span>
                  {currentLanguage === "es"
                    ? "5 (Muy crítica)"
                    : "5 (Very critical)"}
                </span>
              </div>
            </div>
          </form>
          <div className="flex justify-between mt-4">
            <button className="btn btn-primary" onClick={prevStep}>
              {translations[currentLanguage].buttons.anterior}
            </button>
            <button className="btn btn-primary" onClick={nextStep}>
              {translations[currentLanguage].buttons.siguiente}
            </button>
          </div>
        </motion.div>
      )}

      {step === 4 && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="step-5"
        >
          <h2 className="text-2xl font-bold mb-4">
            {translations[currentLanguage].advantagesTitle}
          </h2>
          <p className="mb-4">
            {translations[currentLanguage].advantagesDescription}
          </p>
          <div className="bg-base-200 p-6 rounded-lg mb-4 shadow-md">
            <ul className="list-disc list-inside space-y-2">
              {translations[currentLanguage].advantagesList.map(
                (item, index) => (
                  <li key={index} className="p-2 rounded-lg">
                    <strong>{item.title}</strong> {item.description}
                  </li>
                )
              )}
            </ul>
          </div>
          <p className="mb-4 font-bold text-lg">
            {translations[currentLanguage].finalizarMessage}
          </p>
          <div className="flex justify-between mt-4">
            <button className="btn btn-primary" onClick={prevStep}>
              {translations[currentLanguage].buttons.anterior}
            </button>
            <Link className="btn btn-primary" to="/">
              {translations[currentLanguage].buttons.finalizar}
            </Link>
          </div>
        </motion.div>
      )}

      <p className="mt-4 text-sm">
        {translations[currentLanguage].footer.modificarDatos}
      </p>
    </div>
  );
}
