import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useEffect, useState } from 'react';

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkPlatform = () => {
      const platform = Capacitor.getPlatform();
      setIsMobile(platform === 'android' || platform === 'ios');
    };

    checkPlatform();

    App.addListener('appStateChange', (state) => {
      if (state.isActive) {
        checkPlatform();
      }
    });
  }, [isMobile]);

  return isMobile;
};

export default useMobile;
