import { useLanguage } from "../useLanguaje";

const SEOMarkmap = () => {
  const { currentLanguage } = useLanguage();

const texts = {
  es: {
    sections: [
      {
        heading: "Generador de mapas mentales",
        content: "Nuestra plataforma de vanguardia transforma tus archivos en representaciones visuales dinámicas. Carga tus documentos, ya sean en formato digital o capturas de imagen, y observa cómo se convierten en diagramas estructurados y comprensibles. Esta herramienta es perfecta para desglosar información compleja, sistematizar conceptos y potenciar tu metodología de estudio.",
        list: [
          "Convierte archivos digitales y gráficos en diagramas dinámicos",
          "Representa visualmente ideas abstractas",
          "Sistematiza conceptos y optimiza tu proceso de asimilación"
        ]
      },
      {
        heading: "Proceso de Creación de mapas conceptuales",
        list: [
          "Importa tus archivos (digitales o gráficos) - con un límite de 45 hojas por documento",
          "Nuestro sistema analiza y extrae los puntos clave",
          "Se genera automáticamente un diagrama estructurado y dinámico",
          "Adapta y modifica el diagrama según tus preferencias"
        ]
      },
      {
        heading: "Ventajas de los esquemas conceptuales con IA",
        list: [
          "Facilita la comprensión de temas intrincados",
          "Reduce el tiempo dedicado a la elaboración de síntesis",
          "Potencia la retención y revisión de ideas fundamentales",
          "Óptimo para la preparación de evaluaciones y exposiciones",
          "Estimula el aprendizaje visual y el pensamiento creativo"
        ]
      },
      {
        heading: "Usos Prácticos",
        content: "Nuestro Generador de Diagramas Cognitivos se adapta a diversas necesidades académicas y profesionales:",
        list: [
          "Alumnos: Optimiza tus notas y mejora tu técnica de estudio",
          "Docentes: Diseña recursos pedagógicos visualmente atractivos",
          "Académicos: Organiza y sintetiza datos de diversas fuentes",
          "Expertos: Elabora presentaciones claras y bien estructuradas"
        ]
      },
      {
        heading: "Funcionalidades Destacadas",
        list: [
          "Soporte para archivos digitales y diversos formatos de imagen",
          "Capacidad de procesar documentos de hasta 45 hojas",
          "Interfaz intuitiva de fácil manejo",
          "Diagramas adaptables y modificables",
          "Opción de exportar en varios formatos para una fácil distribución"
        ]
      },
      {
        heading: "Revoluciona tu Método de Estudio",
        content: "Nuestro Generador de Diagramas Cognitivos no es solo una herramienta, es un aliado en tu desarrollo académico. Redefine la manera en que procesas y asimilas información, haciendo que tu estudio sea más eficaz y productivo. Ya sea que estés preparándote para una evaluación, organizando un proyecto o buscando comprender mejor un tema complejo, nuestros diagramas interactivos te ayudarán a alcanzar tus metas educativas. ¡Comienza a crear representaciones visuales inteligentes hoy y eleva tu aprendizaje a un nuevo nivel!"
      }
    ]
  },
  it: {
    sections: [
      {
        heading: "Generatore di mappe mentali",
        content: "La nostra piattaforma all'avanguardia trasforma i tuoi file in rappresentazioni visive dinamiche. Carica i tuoi documenti, sia in formato digitale che immagini, e osserva come si trasformano in diagrammi strutturati e comprensibili. Questo strumento è perfetto per scomporre informazioni complesse, sistematizzare concetti e migliorare la tua metodologia di studio.",
        list: [
          "Converti file digitali e grafici in diagrammi dinamici",
          "Rappresenta visivamente idee astratte",
          "Sistematizza i concetti e ottimizza il tuo processo di assimilazione"
        ]
      },
      {
        heading: "Processo di creazione di mappe concettuali",
        list: [
          "Importa i tuoi file (digitali o grafici) - con un limite di 45 pagine per documento",
          "Il nostro sistema analizza ed estrae i punti chiave",
          "Viene generato automaticamente un diagramma strutturato e dinamico",
          "Adatta e modifica il diagramma in base alle tue preferenze"
        ]
      },
      {
        heading: "Vantaggi degli schemi concettuali con IA",
        list: [
          "Facilita la comprensione di argomenti intricati",
          "Riduce il tempo dedicato alla preparazione di sintesi",
          "Migliora la ritenzione e la revisione delle idee fondamentali",
          "Ottimo per la preparazione di esami e presentazioni",
          "Stimola l'apprendimento visivo e il pensiero creativo"
        ]
      },
      {
        heading: "Usi pratici",
        content: "Il nostro Generatore di Diagrammi Cognitivi si adatta a diverse esigenze accademiche e professionali:",
        list: [
          "Studenti: Ottimizza i tuoi appunti e migliora la tua tecnica di studio",
          "Docenti: Progetta risorse didattiche visivamente accattivanti",
          "Accademici: Organizza e sintetizza dati da diverse fonti",
          "Esperti: Crea presentazioni chiare e ben strutturate"
        ]
      },
      {
        heading: "Funzionalità principali",
        list: [
          "Supporto per file digitali e vari formati di immagine",
          "Capacità di elaborare documenti fino a 45 pagine",
          "Interfaccia intuitiva e facile da usare",
          "Diagrammi adattabili e modificabili",
          "Opzione di esportazione in vari formati per una facile distribuzione"
        ]
      },
      {
        heading: "Rivoluziona il tuo metodo di studio",
        content: "Il nostro Generatore di Diagrammi Cognitivi non è solo uno strumento, è un alleato nel tuo sviluppo accademico. Ridefinisci il modo in cui elabori e assimili le informazioni, rendendo il tuo studio più efficace e produttivo. Sia che ti stia preparando per un esame, organizzando un progetto o cercando di comprendere meglio un argomento complesso, i nostri diagrammi interattivi ti aiuteranno a raggiungere i tuoi obiettivi educativi. Inizia a creare rappresentazioni visive intelligenti oggi e porta il tuo apprendimento a un nuovo livello!"
      }
    ]
  },
  en: {
    sections: [
      {
        heading: "Mind Map Generator",
        content: "Our cutting-edge platform transforms your files into dynamic visual representations. Upload your documents, whether in digital format or image captures, and watch them turn into structured and comprehensible diagrams. This tool is perfect for breaking down complex information, systematizing concepts, and enhancing your study methodology.",
        list: [
          "Convert digital files and graphics into dynamic diagrams",
          "Visually represent abstract ideas",
          "Systematize concepts and optimize your assimilation process"
        ]
      },
      {
        heading: "Concept Map Creation Process",
        list: [
          "Import your files (digital or graphics) - with a limit of 45 pages per document",
          "Our system analyzes and extracts key points",
          "A structured and dynamic diagram is automatically generated",
          "Adapt and modify the diagram according to your preferences"
        ]
      },
      {
        heading: "Advantages of Conceptual Schemes with AI",
        list: [
          "Facilitates understanding of intricate topics",
          "Reduces time spent on synthesis preparation",
          "Enhances retention and review of fundamental ideas",
          "Optimal for exam preparation and presentations",
          "Stimulates visual learning and creative thinking"
        ]
      },
      {
        heading: "Practical Uses",
        content: "Our Cognitive Diagram Generator adapts to various academic and professional needs:",
        list: [
          "Students: Optimize your notes and improve your study technique",
          "Teachers: Design visually appealing educational resources",
          "Academics: Organize and synthesize data from various sources",
          "Experts: Create clear and well-structured presentations"
        ]
      },
      {
        heading: "Highlighted Features",
        list: [
          "Support for digital files and various image formats",
          "Ability to process documents up to 45 pages",
          "Intuitive and easy-to-use interface",
          "Adaptable and modifiable diagrams",
          "Option to export in various formats for easy distribution"
        ]
      },
      {
        heading: "Revolutionize Your Study Method",
        content: "Our Cognitive Diagram Generator is not just a tool, it's an ally in your academic development. Redefine the way you process and assimilate information, making your study more effective and productive. Whether you're preparing for an exam, organizing a project, or seeking to better understand a complex topic, our interactive diagrams will help you achieve your educational goals. Start creating smart visual representations today and elevate your learning to a new level!"
      }
    ]
  }
};


  const content = texts[currentLanguage] || texts.es;

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      {content.sections.map((section, index) => (
        <section className="mb-8" key={index}>
          <h2 className="text-3xl font-semibold mb-4 text-gray-700">
            {section.heading}
          </h2>
          {section.content && (
            <p className="mb-4 text-gray-600">{section.content}</p>
          )}
          {section.list && (
            <ul className="list-disc list-inside mb-4 text-gray-600">
              {section.list.map((item, idx) => (
                <li className="mb-2" key={idx}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </div>
  );
};

export default SEOMarkmap;
