import { useLanguage } from "../useLanguaje";

const SEOTutors = () => {
  const { currentLanguage } = useLanguage();

const texts = {
  es: {
    title1: "Asistentes Virtuales Personalizados para Estudiantes",
    description1:
      "Descubre una nueva forma de aprender con nuestros tutores virtuales impulsados por inteligencia artificial. Sube tu PDF con el temario y obtén respuestas instantáneas a todas tus preguntas. Nuestra plataforma de e-learning revoluciona el estudio personalizado.",
    list1: [
      "Tutorías online disponibles 24/7",
      "Aprendizaje adaptativo basado en IA",
      "Soporte académico personalizado para cada estudiante",
    ],
    title2: "Interactúa con Tutores IA Especializados",
    description2:
      "Selecciona entre una variedad de tutores IA, cada uno especializado en diferentes áreas académicas. Nuestro sistema de educación en línea te permite elegir el asistente virtual que mejor se adapte a tus necesidades de aprendizaje.",
    list2: [
      "Amplia gama de tutores IA para diferentes materias",
      "Asistencia académica personalizada en tiempo real",
      "Plataforma de aprendizaje interactivo y dinámico",
    ],
    title3: "Aprendizaje Basado en Tu Propio Material",
    description3:
      "Aprovecha al máximo tus recursos de estudio. Sube tu PDF con el temario y deja que nuestro tutor IA lo analice. Esta innovadora herramienta de e-learning te permite hacer preguntas específicas sobre tu material de estudio.",
    list3: [
      "Carga de PDFs con temarios personalizados",
      "Análisis inteligente de contenido académico",
      "Preguntas y respuestas sobre tu material específico",
    ],
    title4: "Beneficios del Aprendizaje con IA",
    list4: [
      "Mejora en la comprensión de conceptos complejos",
      "Aumento de la retención de información",
      "Flexibilidad en horarios de estudio",
      "Adaptación a diferentes estilos de aprendizaje",
      "Preparación eficiente para exámenes y evaluaciones",
    ],
    title5: "Características de Nuestros Tutores Virtuales",
    list5: [
      "Respuestas instantáneas a tus dudas académicas",
      "Explicaciones paso a paso de problemas complejos",
      "Sugerencias de recursos adicionales para profundizar",
      "Seguimiento del progreso de aprendizaje",
      "Adaptación continua a tu nivel de conocimiento",
    ],
    title6: "Transforma Tu Experiencia de Aprendizaje",
    description6:
      "Nuestros tutores IA representan el futuro de la educación en línea. Ya sea que estés preparándote para un examen, trabajando en un proyecto complejo o simplemente buscando expandir tus conocimientos, nuestro sistema de tutoría virtual está diseñado para ayudarte a alcanzar tus metas académicas. Experimenta el poder del aprendizaje personalizado y adaptativo con nuestros asistentes virtuales basados en IA. ¡Comienza tu viaje hacia el éxito académico hoy mismo!",
  },
  it: {
    title1: "Assistenti Virtuali Personalizzati per Studenti",
    description1:
      "Scopri un nuovo modo di imparare con i nostri tutor virtuali basati sull'intelligenza artificiale. Carica il tuo PDF con il programma e ottieni risposte istantanee a tutte le tue domande. La nostra piattaforma di e-learning rivoluziona lo studio personalizzato.",
    list1: [
      "Tutoraggio online disponibile 24/7",
      "Apprendimento adattivo basato sull'IA",
      "Supporto accademico personalizzato per ogni studente",
    ],
    title2: "Interagisci con Tutor IA Specializzati",
    description2:
      "Scegli tra una varietà di tutor IA, ognuno specializzato in diverse aree accademiche. Il nostro sistema di istruzione online ti consente di scegliere l'assistente virtuale più adatto alle tue esigenze di apprendimento.",
    list2: [
      "Ampia gamma di tutor IA per diverse materie",
      "Assistenza accademica personalizzata in tempo reale",
      "Piattaforma di apprendimento interattiva e dinamica",
    ],
    title3: "Apprendimento Basato sui Tuoi Materiali",
    description3:
      "Sfrutta al massimo le tue risorse di studio. Carica il tuo PDF con il programma e lascia che il nostro tutor IA lo analizzi. Questo innovativo strumento di e-learning ti consente di porre domande specifiche sul tuo materiale di studio.",
    list3: [
      "Caricamento di PDF con programmi personalizzati",
      "Analisi intelligente dei contenuti accademici",
      "Domande e risposte sul tuo materiale specifico",
    ],
    title4: "Benefici dell'Apprendimento con l'IA",
    list4: [
      "Miglioramento della comprensione di concetti complessi",
      "Aumento della ritenzione delle informazioni",
      "Flessibilità negli orari di studio",
      "Adattamento a diversi stili di apprendimento",
      "Preparazione efficiente per esami e valutazioni",
    ],
    title5: "Caratteristiche dei Nostri Tutor Virtuali",
    list5: [
      "Risposte istantanee ai tuoi dubbi accademici",
      "Spiegazioni passo passo di problemi complessi",
      "Suggerimenti di risorse aggiuntive per approfondire",
      "Monitoraggio dei progressi di apprendimento",
      "Adattamento continuo al tuo livello di conoscenza",
    ],
    title6: "Trasforma la Tua Esperienza di Apprendimento",
    description6:
      "I nostri tutor IA rappresentano il futuro dell'istruzione online. Che tu ti stia preparando per un esame, lavorando a un progetto complesso o semplicemente cercando di ampliare le tue conoscenze, il nostro sistema di tutoraggio virtuale è progettato per aiutarti a raggiungere i tuoi obiettivi accademici. Sperimenta la potenza dell'apprendimento personalizzato e adattivo con i nostri assistenti virtuali basati sull'IA. Inizia oggi stesso il tuo percorso verso il successo accademico!",
  },
  en: {
    title1: "Personalized Virtual Assistants for Students",
    description1:
      "Discover a new way of learning with our AI-powered virtual tutors. Upload your syllabus PDF and get instant answers to all your questions. Our e-learning platform revolutionizes personalized study.",
    list1: [
      "Online tutoring available 24/7",
      "AI-based adaptive learning",
      "Personalized academic support for each student",
    ],
    title2: "Interact with Specialized AI Tutors",
    description2:
      "Choose from a variety of AI tutors, each specialized in different academic areas. Our online education system allows you to select the virtual assistant that best suits your learning needs.",
    list2: [
      "Wide range of AI tutors for different subjects",
      "Real-time personalized academic assistance",
      "Interactive and dynamic learning platform",
    ],
    title3: "Learning Based on Your Own Material",
    description3:
      "Make the most of your study resources. Upload your syllabus PDF and let our AI tutor analyze it. This innovative e-learning tool allows you to ask specific questions about your study material.",
    list3: [
      "Upload PDFs with personalized syllabi",
      "Intelligent analysis of academic content",
      "Questions and answers about your specific material",
    ],
    title4: "Benefits of Learning with AI",
    list4: [
      "Improved understanding of complex concepts",
      "Increased information retention",
      "Flexibility in study schedules",
      "Adaptation to different learning styles",
      "Efficient preparation for exams and assessments",
    ],
    title5: "Features of Our Virtual Tutors",
    list5: [
      "Instant answers to your academic queries",
      "Step-by-step explanations of complex problems",
      "Suggestions for additional resources to deepen understanding",
      "Tracking of learning progress",
      "Continuous adaptation to your knowledge level",
    ],
    title6: "Transform Your Learning Experience",
    description6:
      "Our AI tutors represent the future of online education. Whether you're preparing for an exam, working on a complex project, or simply looking to expand your knowledge, our virtual tutoring system is designed to help you achieve your academic goals. Experience the power of personalized and adaptive learning with our AI-based virtual assistants. Start your journey to academic success today!",
  },
};

  const content = texts[currentLanguage] || texts.es;

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title1}
        </h2>
        <p className="mb-4 text-gray-600">{content.description1}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list1.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title2}
        </h2>
        <p className="mb-4 text-gray-600">{content.description2}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list2.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title3}
        </h2>
        <p className="mb-4 text-gray-600">{content.description3}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list3.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title4}
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list4.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title5}
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list5.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title6}
        </h2>
        <p className="text-gray-600">{content.description6}</p>
      </section>
    </div>
  );
};

export default SEOTutors;