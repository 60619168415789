import React from "react";
import { Link } from "react-router-dom";
import { useSubscription } from "./SubscriptionContext";
import { useLanguage } from "./useLanguaje";

export default function ModalNoToken({ setShowNoTokenModal }) {
  const { subscriptionType } = useSubscription();
  const { currentLanguage } = useLanguage();

  const routes = {
    es: {
      viewPrices: "/ver-precios",
      login: "/iniciar-sesion"
    },
    en: {
      viewPrices: "/view-prices",
      login: "/login"
    },
    it: {
      viewPrices: "/vedi-prezzi",
      login: "/accedi"
    }
  };

  const texts = {
    es: {
      accessRequired: "¡Ups! Acceso requerido",
      needSubscription:
        "Para utilizar esta herramienta, necesitas tener una suscripción activa.",
      startTrial: "¡Comienza ahora y disfruta de 3 días de prueba gratis!",
      loginRequired:
        "Para utilizar esta herramienta, necesitas iniciar sesión y tener una suscripción activa.",
      noAccount:
        "¿Aún no tienes una cuenta? ¡Regístrate ahora y disfruta de 3 días de prueba gratis!",
      startFreeTrial: "Comenzar prueba gratis",
      loginRegister: "Iniciar sesión / Registrarse",
    },
    en: {
      accessRequired: "Oops! Access required",
      needSubscription:
        "To use this tool, you need to have an active subscription.",
      startTrial: "Start now and enjoy a 3-day free trial!",
      loginRequired:
        "To use this tool, you need to log in and have an active subscription.",
      noAccount:
        "Don't have an account yet? Sign up now and enjoy a 3-day free trial!",
      startFreeTrial: "Start free trial",
      loginRegister: "Login / Register",
    },
    it: {
      accessRequired: "Oops! Accesso richiesto",
      needSubscription:
        "Per utilizzare questo strumento, devi avere un abbonamento attivo.",
      startTrial: "Inizia ora e goditi una prova gratuita di 3 giorni!",
      loginRequired:
        "Per utilizzare questo strumento, devi accedere e avere un abbonamento attivo.",
      noAccount:
        "Non hai ancora un account? Registrati ora e goditi una prova gratuita di 3 giorni!",
      startFreeTrial: "Inizia la prova gratuita",
      loginRegister: "Accedi / Registrati",
    },
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg border">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">
            {texts[currentLanguage].accessRequired}
          </p>
          <div
            className="cursor-pointer z-50 btn btn-ghost"
            onClick={() => setShowNoTokenModal(false)}
          >
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
            </svg>
          </div>
        </div>
        {subscriptionType === "No Subscription" ? (
          <>
            <p className="mb-4">{texts[currentLanguage].needSubscription}</p>
            <p className="mb-4">{texts[currentLanguage].startTrial}</p>
            <div className="flex flex-col space-y-2 pt-2">
              <Link
                className="w-full btn btn-primary"
                to={routes[currentLanguage].viewPrices}
                onClick={() => setShowNoTokenModal(false)}
              >
                {texts[currentLanguage].startFreeTrial}
              </Link>
            </div>
          </>
        ) : (
          <>
            <p className="mb-4">{texts[currentLanguage].loginRequired}</p>
            <p className="mb-4">{texts[currentLanguage].noAccount}</p>
            <div className="flex flex-col space-y-2 pt-2">
              <Link
                className="w-full btn btn-primary"
                to={routes[currentLanguage].login}
                onClick={() => setShowNoTokenModal(false)}
              >
                {texts[currentLanguage].loginRegister}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
