import React from 'react';
import { useSubscription } from './SubscriptionContext';
import UnlimitedButton from './UnlimitedButton';
import { useLanguage } from './useLanguaje';

const ModalTrialEnded = () => {
  const { subscriptionType } = useSubscription();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      title: "Créditos del Trial Agotados",
      message1: "¡Vaya! Parece que has agotado tus créditos de prueba.",
      message2: `Para seguir disfrutando de FotoExamen sin límites con el plan ${subscriptionType?.replaceAll("Trial", "")}, es hora de dar el siguiente paso.`,
      message3: "Continúa aprovechando todas las ventajas de FotoExamen.",
    },
    en: {
      title: "Trial Credits Exhausted",
      message1: "Oops! It seems you've exhausted your trial credits.",
      message2: `To continue enjoying PhotoExamAI without limits with the ${subscriptionType?.replaceAll("Trial", "")} plan, it's time to take the next step.`,
      message3: "Keep enjoying all the benefits of PhotoExamAI.",
    },
    it: {
      title: "Crediti Trial Esausti",
      message1: "Ops! Sembra che tu abbia esaurito i tuoi crediti di prova.",
      message2: `Per continuare a utilizzare RisolviCompiti senza limiti con il piano ${subscriptionType?.replaceAll("Trial", "")}, è ora di fare il passo successivo.`,
      message3: "Continua a sfruttare tutti i vantaggi di RisolviCompiti.",
    },
  };

  const currentTexts = texts[currentLanguage];

  return (
    <dialog id="trial_ended_modal" className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg">{currentTexts.title}</h3>
        <div className="py-4">
          <p className="mb-2">{currentTexts.message1}</p>
          <p className="mb-2">{currentTexts.message2}</p>
          <p className="">{currentTexts.message3}</p>
        </div>
        <div className="modal-action w-full">
          <UnlimitedButton />
        </div>
      </div>
    </dialog>
  );
};

export default ModalTrialEnded;