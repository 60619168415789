import { useLanguage } from "../useLanguaje";

const texts = {
  es: {
    title: "Hacer Tareas con IA: Revoluciona tu Aprendizaje",
    sections: [
      {
        heading: "Resolver Exámenes con IA: Tu Asistente Personal de Estudio",
        content:
          "FotoExamen utiliza inteligencia artificial avanzada para ayudarte a resolver exámenes de cualquier materia. Ya no tendrás que pasar horas frustrantes intentando descifrar problemas complejos. Nuestra IA analiza tus exámenes, proporciona soluciones paso a paso y te ayuda a comprender los conceptos subyacentes.",
        list: [
          "Resolución instantánea de exámenes de matemáticas, ciencias, humanidades y más",
          "Explicaciones detalladas que mejoran tu comprensión del tema",
          "Adaptado a todos los niveles educativos: secundaria, preparatoria y universidad",
        ],
      },
      {
        heading: "Exámenes de Años Anteriores: Prepárate con Confianza",
        content:
          "Con FotoExamen, puedes practicar con exámenes de años anteriores y estar mejor preparado para tus pruebas. Nuestra IA no solo te da las respuestas, sino que te muestra cómo llegar a ellas, ayudándote a dominar los patrones y estilos de preguntas comunes en tus exámenes.",
        list: [
          "Accede a una amplia biblioteca de exámenes pasados",
          "Recibe soluciones detalladas para cada pregunta",
          "Identifica tus áreas de mejora y enfoca tu estudio",
        ],
      },
      {
        heading: "Hacer Deberes con IA: Optimiza tu Tiempo de Estudio",
        content:
          "Los deberes ya no serán una carga con FotoExamen. Nuestra IA te guía a través de tus tareas, proporcionándote el apoyo que necesitas para completarlas de manera eficiente y efectiva. Ya sea un problema de matemáticas, un ensayo de literatura o un proyecto de ciencias, FotoExamen está aquí para ayudarte.",
        list: [
          "Asistencia en tiempo real para tus deberes diarios",
          "Sugerencias y correcciones para mejorar tus trabajos",
          "Aprende mientras haces: comprende mejor los conceptos a medida que los aplicas",
        ],
      },
      {
        heading: "¿Cómo Funciona FotoExamen?",
        list: [
          "Sube una foto de tu exameno un pdf, tarea o problema",
          "Nuestra IA analiza el contenido en segundos",
          "Recibe soluciones detalladas y explicaciones claras",
          "Aprende de las respuestas y mejora tu comprensión",
        ],
      },
      {
        heading: "Transforma tu Experiencia de Aprendizaje con FotoExamen",
        content:
          "Ya sea que estés luchando con exámenes difíciles, tratando de entender conceptos complejos, o simplemente buscando una forma más eficiente de hacer tus deberes, FotoExamen es tu solución. Nuestra IA está diseñada para adaptarse a tu estilo de aprendizaje y ayudarte a alcanzar tu máximo potencial académico. ¡Únete a miles de estudiantes que ya están experimentando el poder de la IA en su educación con FotoExamen!",
      },
    ],
  },
  it: {
    title: "Fai i Compiti con IA: Rivoluziona il Tuo Apprendimento",
    sections: [
      {
        heading: "Risolvi Esami con IA: Il Tuo Assistente Personale di Studio",
        content:
          "RisolviCompiti utilizza intelligenza artificiale avanzata per aiutarti a risolvere esami di qualsiasi materia. Non dovrai più passare ore frustranti cercando di decifrare problemi complessi. La nostra IA analizza i tuoi esami, fornisce soluzioni passo passo e ti aiuta a comprendere i concetti sottostanti.",
        list: [
          "Risoluzione istantanea di esami di matematica, scienze, umanistiche e altro",
          "Spiegazioni dettagliate che migliorano la tua comprensione dell'argomento",
          "Adattato a tutti i livelli educativi: scuola secondaria, superiore e università",
        ],
      },
      {
        heading: "Esami degli Anni Precedenti: Preparati con Fiducia",
        content:
          "Con RisolviCompiti, puoi esercitarti con esami degli anni precedenti e essere meglio preparato per le tue prove. La nostra IA non solo ti dà le risposte, ma ti mostra anche come arrivarci, aiutandoti a padroneggiare i modelli e gli stili di domande comuni nei tuoi esami.",
        list: [
          "Accedi a un'ampia libreria di esami passati",
          "Ricevi soluzioni dettagliate per ogni domanda",
          "Identifica le tue aree di miglioramento e concentra il tuo studio",
        ],
      },
      {
        heading: "Fai i Compiti con IA: Ottimizza il Tuo Tempo di Studio",
        content:
          "I compiti non saranno più un peso con RisolviCompiti. La nostra IA ti guida attraverso i tuoi compiti, fornendoti il supporto necessario per completarli in modo efficiente ed efficace. Che si tratti di un problema di matematica, un saggio di letteratura o un progetto di scienze, RisolviCompiti è qui per aiutarti.",
        list: [
          "Assistenza in tempo reale per i tuoi compiti quotidiani",
          "Suggerimenti e correzioni per migliorare i tuoi lavori",
          "Impara mentre fai: comprendi meglio i concetti mentre li applichi",
        ],
      },
      {
        heading: "Come Funziona RisolviCompiti?",
        list: [
          "Carica una foto del tuo esame, un pdf, compito o problema",
          "La nostra IA analizza il contenuto in pochi secondi",
          "Ricevi soluzioni dettagliate e spiegazioni chiare",
          "Impara dalle risposte e migliora la tua comprensione",
        ],
      },
      {
        heading:
          "Trasforma la Tua Esperienza di Apprendimento con RisolviCompiti",
        content:
          "Che tu stia lottando con esami difficili, cercando di capire concetti complessi, o semplicemente cercando un modo più efficiente per fare i tuoi compiti, RisolviCompiti è la tua soluzione. La nostra IA è progettata per adattarsi al tuo stile di apprendimento e aiutarti a raggiungere il tuo massimo potenziale accademico. Unisciti a migliaia di studenti che stanno già sperimentando il potere dell'IA nella loro educazione con RisolviCompiti!",
      },
    ],
  },
  en: {
    title: "Do Homework with AI: Revolutionize Your Learning",
    sections: [
      {
        heading: "Solve Exams with AI: Your Personal Study Assistant",
        content:
          "PhotoExamAI uses advanced artificial intelligence to help you solve exams in any subject. No more frustrating hours trying to decipher complex problems. Our AI analyzes your exams, provides step-by-step solutions, and helps you understand the underlying concepts.",
        list: [
          "Instant resolution of math, science, humanities exams, and more",
          "Detailed explanations that enhance your understanding of the topic",
          "Adapted to all educational levels: high school, college, and university",
        ],
      },
      {
        heading: "Past Exams: Prepare with Confidence",
        content:
          "With PhotoExamAI, you can practice with past exams and be better prepared for your tests. Our AI not only gives you the answers but also shows you how to arrive at them, helping you master common question patterns and styles in your exams.",
        list: [
          "Access a wide library of past exams",
          "Receive detailed solutions for each question",
          "Identify your areas for improvement and focus your study",
        ],
      },
      {
        heading: "Do Homework with AI: Optimize Your Study Time",
        content:
          "Homework will no longer be a burden with PhotoExamAI. Our AI guides you through your tasks, providing the support you need to complete them efficiently and effectively. Whether it's a math problem, a literature essay, or a science project, PhotoExamAI is here to help you.",
        list: [
          "Real-time assistance for your daily homework",
          "Suggestions and corrections to improve your work",
          "Learn as you go: better understand concepts as you apply them",
        ],
      },
      {
        heading: "How Does PhotoExamAI Work?",
        list: [
          "Upload a photo of your exam, a pdf, task, or problem",
          "Our AI analyzes the content in seconds",
          "Receive detailed solutions and clear explanations",
          "Learn from the answers and improve your understanding",
        ],
      },
      {
        heading: "Transform Your Learning Experience with PhotoExamAI",
        content:
          "Whether you're struggling with difficult exams, trying to understand complex concepts, or just looking for a more efficient way to do your homework, PhotoExamAI is your solution. Our AI is designed to adapt to your learning style and help you reach your full academic potential. Join thousands of students already experiencing the power of AI in their education with PhotoExamAI!",
      },
    ],
  },
};

const SEOExamSolver = () => {
  const { currentLanguage } = useLanguage();
  const content = texts[currentLanguage] || texts.es;

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-4xl font-bold mb-6 text-gray-800">{content.title}</h2>

      {content.sections.map((section, index) => (
        <section className="mb-8" key={index}>
          <h3 className="text-3xl font-semibold mb-4 text-gray-700">
            {section.heading}
          </h3>
          {section.content && (
            <p className="mb-4 text-gray-600">{section.content}</p>
          )}
          {section.list && (
            <ul className="list-disc list-inside mb-4 text-gray-600">
              {section.list.map((item, idx) => (
                <li className="mb-2" key={idx}>
                  {item}
                </li>
              ))}
            </ul>
          )}
        </section>
      ))}
    </div>
  );
};

export default SEOExamSolver;
