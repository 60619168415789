import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Precios() {
  const { currentLanguage } = useLanguage();
  const [activeTab, setActiveTab] = useState("monthly");
  const [email, setEmail] = useState("");
  const [token, clearToken] = useAuth();
  const [trakdeskClientId, setTrakdeskClientId] = useState(null);

  const navigate = useNavigate();

  const texts = {
    es: {
      monthly: "Mensual",
      annual: "🔥 Anual: 6+ meses gratis",
      basic: "Plan Básico",
      standard: "Plan Standard",
      pro: "Plan Pro",
      iaBasic: "IA NIVEL BASICA",
      iaMedium: "IA NIVEL MEDIA",
      iaAdvanced: "IA NIVEL AVANZADO",
      unlimitedUse: "USO SIN LÍMITES",
      featuresBasic: [
        "✔ Resolver y corregir exámenes o tareas",
        "✔ Escritor de ensayos",
        "✔ Chatear con los Tutores IA",
        "✔ Resumir textos",
        "✔ Subrayar documentos",
        "✔ Historial completo de examenes",
        "❌ Esquemas conceptuales",
        "❌ Quizzes",
        "❌ Tomar apuntes",
      ],
      featuresStandard: [
        "✔ Resolver y corregir exámenes o tareas",
        "✔ Escritor de ensayos",
        "✔ Chatear con los Tutores IA",
        "✔ Resumir textos",
        "✔ Subrayar documentos",
        "✔ Historial completo de examenes",
        "✔ Esquemas conceptuales",
        "✔ Quizzes",
        "✔ Más velocidad de procesamiento",
        "❌ Tomar apuntes",
        "❌ Acceso anticipado a nuevas funciones",
      ],
      featuresPro: [
        "✔ Resolver y corregir exámenes o tareas",
        "✔ Escritor de ensayos",
        "✔ Chatear con los Tutores IA",
        "✔ Resumir textos",
        "✔ Subrayar documentos",
        "✔ Historial completo de examenes",
        "✔ Esquemas conceptuales",
        "✔ Quizzes",
        "✔ Más velocidad de procesamiento",
        "✔ Tomar apuntes",
        "✔ Acceso anticipado a nuevas funciones",
      ],
      freeTrial: "Prueba gratis de 3 días ➤",
      freeTrialDesc: "Disfruta de mejoras constantes y nuestra IA sin límites. Prueba gratis de 3 días.",
    },
    en: {
      monthly: "Monthly",
      annual: "🔥 Annual: 6+ months free",
      basic: "Basic Plan",
      standard: "Standard Plan",
      pro: "Pro Plan",
      iaBasic: "AI BASIC LEVEL",
      iaMedium: "AI MEDIUM LEVEL",
      iaAdvanced: "AI ADVANCED LEVEL",
      unlimitedUse: "UNLIMITED USE",
      featuresBasic: [
        "✔ Solve and correct exams or assignments",
        "✔ Essay writer",
        "✔ Chat with AI Tutors",
        "✔ Summarize texts",
        "✔ Highlight documents",
        "✔ Complete exam history",
        "❌ Conceptual maps",
        "❌ Quizzes",
        "❌ Take notes",
      ],
      featuresStandard: [
        "✔ Solve and correct exams or assignments",
        "✔ Essay writer",
        "✔ Chat with AI Tutors",
        "✔ Summarize texts",
        "✔ Highlight documents",
        "✔ Complete exam history",
        "✔ Conceptual maps",
        "✔ Quizzes",
        "✔ Faster processing speed",
        "❌ Take notes",
        "❌ Early access to new features",
      ],
      featuresPro: [
        "✔ Solve and correct exams or assignments",
        "✔ Essay writer",
        "✔ Chat with AI Tutors",
        "✔ Summarize texts",
        "✔ Highlight documents",
        "✔ Complete exam history",
        "✔ Conceptual maps",
        "✔ Quizzes",
        "✔ Faster processing speed",
        "✔ Take notes",
        "✔ Early access to new features",
      ],
      freeTrial: "Free 3-day Trial ➤",
      freeTrialDesc: "Enjoy constant improvements and our unlimited AI. Free 3-day trial.",
    },
    it: {
      monthly: "Mensile",
      annual: "🔥 Annuale: 6+ mesi gratis",
      basic: "Piano Base",
      standard: "Piano Standard",
      pro: "Piano Pro",
      iaBasic: "IA LIVELLO BASE",
      iaMedium: "IA LIVELLO MEDIO",
      iaAdvanced: "IA LIVELLO AVANZATO",
      unlimitedUse: "UTILIZZO ILLIMITATO",
      featuresBasic: [
        "✔ Risolvere e correggere esami o compiti",
        "✔ Scrittore di saggi",
        "✔ Chatta con i tutor IA",
        "✔ Riepiloga testi",
        "✔ Evidenzia documenti",
        "✔ Cronologia completa degli esami",
        "❌ Mappe concettuali",
        "❌ Quiz",
        "❌ Prendi appunti",
      ],
      featuresStandard: [
        "✔ Risolvere e correggere esami o compiti",
        "✔ Scrittore di saggi",
        "✔ Chatta con i tutor IA",
        "✔ Riepiloga testi",
        "✔ Evidenzia documenti",
        "✔ Cronologia completa degli esami",
        "✔ Mappe concettuali",
        "✔ Quiz",
        "✔ Velocità di elaborazione più elevata",
        "❌ Prendi appunti",
        "❌ Accesso anticipato a nuove funzionalità",
      ],
      featuresPro: [
        "✔ Risolvere e correggere esami o compiti",
        "✔ Scrittore di saggi",
        "✔ Chatta con i tutor IA",
        "✔ Riepiloga testi",
        "✔ Evidenzia documenti",
        "✔ Cronologia completa degli esami",
        "✔ Mappe concettuali",
        "✔ Quiz",
        "✔ Velocità di elaborazione più elevata",
        "✔ Prendi appunti",
        "✔ Accesso anticipato a nuove funzionalità",
      ],
      freeTrial: "Prova gratuita di 3 giorni ➤",
      freeTrialDesc:
        "Goditi miglioramenti costanti e la nostra IA illimitata. Prova gratuita di 3 giorni.",
    },
  };

  const stripeLinks = {
    es: {
      monthly: {
        basic: "https://buy.stripe.com/9AQ5lEaST3Le7tK3ce",
        medium: "https://buy.stripe.com/7sIaFYf996Xq3du8wz",
        pro: "https://buy.stripe.com/00g6pI3qr1D6aFW3cg",
      },
      annual: {
        basic: "https://buy.stripe.com/dR68xQ5yzftW15maER",
        medium: "https://buy.stripe.com/3cscO6aST0z201i9AO",
        pro: "https://buy.stripe.com/00geWebWXepS01i7sH",
      },
    },
    it: {
      monthly: {
        basic: "https://buy.stripe.com/9AQ5lEaST3Le7tK3ce",
        medium: "https://buy.stripe.com/7sIaFYf996Xq3du8wz",
        pro: "https://buy.stripe.com/00g6pI3qr1D6aFW3cg",
      },
      annual: {
        basic: "https://buy.stripe.com/dR68xQ5yzftW15maER",
        medium: "https://buy.stripe.com/3cscO6aST0z201i9AO",
        pro: "https://buy.stripe.com/00geWebWXepS01i7sH",
      },
    },
    en: {
      monthly: {
        basic: "https://buy.stripe.com/5kAaFY0efepSbK0cMR",
        medium: "https://buy.stripe.com/aEU4hAf991D69BS3ci",
        pro: "https://buy.stripe.com/dR65lE8KL6XqeWcfZ5",
      },
      annual: {
        basic: "https://buy.stripe.com/eVabK23qr81u15m6oE",
        medium: "https://buy.stripe.com/cN29BU9OP95y01i5kB",
        pro: "https://buy.stripe.com/3cs5lE9OPchKbK05kC",
      },
    },
  };

  const prices = {
    es: {
      basic: activeTab === "monthly" ? "7€ / mes" : "3,5€ / mes",
      medium: activeTab === "monthly" ? "17€ / mes" : "8,5€ / mes",
      pro: activeTab === "monthly" ? "30€ / mes" : "15€ / mes",
    },
    it: {
      basic: activeTab === "monthly" ? "7€ / mes" : "3,5€ / mes",
      medium: activeTab === "monthly" ? "17€ / mes" : "8,5€ / mes",
      pro: activeTab === "monthly" ? "30€ / mes" : "15€ / mes",
    },
    en: {
      basic: activeTab === "monthly" ? "$9 / month" : "$4.5 / month",
      medium: activeTab === "monthly" ? "$21 / month" : "$10.5 / month",
      pro: activeTab === "monthly" ? "$42 / month" : "$21 / month",
    },
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "") return;

      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dataToken = await response.json();
      if (dataToken?.email) {
        localStorage.setItem("email", dataToken.email);
        setEmail(dataToken.email);
      }
    };
    fetchUserData();
  }, [token]);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    const getTrakdeskClientId = () => {
      const cookie = document.cookie.match(
        "(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)"
      );
      if (Array.isArray(cookie)) {
        try {
          const trakdeskCid = JSON.parse(cookie.pop());
          setTrakdeskClientId(trakdeskCid["cid"]);
        } catch (e) {
          console.log(e);
        }
      }
    };

    getTrakdeskClientId();
  }, []);

  const handleTrialClick = (e, plan) => {
    e.preventDefault();
    if (!token) {
      navigate(currentLanguage === "en" ? "/login" : currentLanguage === "it" ? "/accedi" : "/iniciar-sesion");
    } else {
      const stripeUrl = stripeLinks[currentLanguage][activeTab][plan];
      const url = new URL(stripeUrl);
      url.searchParams.set("prefilled_email", email);
      if (trakdeskClientId) {
        url.searchParams.set("client_reference_id", trakdeskClientId);
      }
      console.log("URL stripe", url);
      window.open(url.href, "_blank");
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="flex items-center mt-8 mb-2">
          <button
            className={`mr-2 ${
              activeTab === "monthly" ? "btn btn-primary" : "btn"
            }`}
            onClick={() => setActiveTab("monthly")}
          >
            {texts[currentLanguage].monthly}
          </button>
          <button
            className={`${
              activeTab === "annual" ? "btn btn-primary" : "btn"
            }`}
            onClick={() => setActiveTab("annual")}
          >
            {texts[currentLanguage].annual}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-stretch">
        {/* Basic Plan */}
        <div
          id="plan-card1"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          onClick={(e) => handleTrialClick(e, "basic")}
        >
          <div>
            <div id="price1" className="text-3xl font-bold">
              {prices[currentLanguage].basic}
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">
              {texts[currentLanguage].basic}
            </h2>
            <div className="badge badge-neutral mb-2 mr-2">
              {currentLanguage === "es"
                ? texts.es.iaBasic
                : currentLanguage === "en"
                ? texts.en.iaBasic
                : texts.it.iaBasic}
            </div>
            <div className="badge badge-neutral">
              {texts[currentLanguage].unlimitedUse}
            </div>
            <ul className="my-4 text-left">
              {(currentLanguage === "es"
                ? texts.es.featuresBasic
                : currentLanguage === "en"
                ? texts.en.featuresBasic
                : texts.it.featuresBasic
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            <button
              className="btn btn-primary w-full"
              onClick={(e) => handleTrialClick(e, "basic")}
            >
              {texts[currentLanguage].freeTrial}
            </button>
            <div className="text-xs mt-4">
              {texts[currentLanguage].freeTrialDesc}
            </div>
          </div>
        </div>
        {/* Standard Plan */}
        <div
          id="plan-card2"
          className="card bg-white p-6 shadow-xl rounded-lg border-2 border-primary flex flex-col justify-between my-8 hover:shadow-2xl transition-shadow duration-300 cursor-pointer"
          onClick={(e) => handleTrialClick(e, "medium")}
        >
          <div>
            <div id="price2" className="text-3xl font-bold flex justify-between">
              <div>
                {prices[currentLanguage].medium}
              </div>
              <span className="badge badge-primary text-white">
                {currentLanguage === "es" ? "Más popular" : "Most popular"}
              </span>
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">
              {texts[currentLanguage].standard}
            </h2>
            <div className="badge badge-neutral mb-2 mr-2">
              {currentLanguage === "es"
                ? texts.es.iaMedium
                : currentLanguage === "en"
                ? texts.en.iaMedium
                : texts.it.iaMedium}
            </div>
            <div className="badge badge-neutral">
              {texts[currentLanguage].unlimitedUse}
            </div>
            <ul className="my-4 text-left">
              {(currentLanguage === "es"
                ? texts.es.featuresStandard
                : currentLanguage === "en"
                ? texts.en.featuresStandard
                : texts.it.featuresStandard
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            <button
              className="btn btn-primary w-full"
              onClick={(e) => handleTrialClick(e, "medium")}
            >
              {texts[currentLanguage].freeTrial}
            </button>
            <div className="text-xs mt-4">
              {texts[currentLanguage].freeTrialDesc}
            </div>
          </div>
        </div>
        {/* Pro Plan */}
        <div
          id="plan-card3"
          className="card bg-white p-6 shadow-lg rounded-lg flex flex-col justify-between my-8 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          onClick={(e) => handleTrialClick(e, "pro")}
        >
          <div>
            <div id="price3" className="text-3xl font-bold">
              {prices[currentLanguage].pro}
            </div>
            <h2 className="text-2xl font-bold mt-4 mb-2">
              {texts[currentLanguage].pro}
            </h2>
            <div className="badge badge-neutral mb-2 mr-2">
              {currentLanguage === "es"
                ? texts.es.iaAdvanced
                : currentLanguage === "en"
                ? texts.en.iaAdvanced
                : texts.it.iaAdvanced}
            </div>
            <div className="badge badge-neutral">
              {texts[currentLanguage].unlimitedUse}
            </div>
            <ul className="my-4 text-left">
              {(currentLanguage === "es"
                ? texts.es.featuresPro
                : currentLanguage === "en"
                ? texts.en.featuresPro
                : texts.it.featuresPro
              ).map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            <button
              className="btn btn-primary w-full"
              onClick={(e) => handleTrialClick(e, "pro")}
            >
              {texts[currentLanguage].freeTrial}
            </button>
            <div className="text-xs mt-4">
              {texts[currentLanguage].freeTrialDesc}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
