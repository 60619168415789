import { useLanguage } from "../useLanguaje";

const SEOHighlight = () => {
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      title1: "Subrayador de Texto con IA",
      desc1: "Facilita tu proceso de estudio con nuestro Subrayador de Texto con IA. Sube un documento en PDF, varias imágenes o introduce texto directamente para recibir un subrayado automatizado que te ayudará a identificar rápidamente las ideas principales, datos importantes, definiciones y fechas clave. Esta herramienta está diseñada para estudiantes y profesionales que buscan optimizar su tiempo y mejorar su comprensión del material.",
      list1: [
        "Compatible con PDFs, imágenes y texto directo",
        "Subrayado automatizado y eficiente",
        "Ideal para mejorar técnicas de estudio y revisión",
      ],
      title2: "Esquema de Colores Inteligente",
      desc2: "Nuestro Subrayador de Texto con IA utiliza un esquema de colores inteligente para ayudarte a categorizar y diferenciar la información clave de tu material de estudio. El sistema emplea colores específicos para distintos tipos de contenido, permitiéndote enfocarte en lo más relevante de manera efectiva.",
      list2: [
        "Rosa para ideas principales",
        "Verde para datos importantes",
        "Azul para definiciones",
        "Rojo para fechas clave",
      ],
      title3: "Cómo Funciona",
      desc3: "Usar nuestro Subrayador de Texto con IA es muy sencillo. Solo necesitas subir tu documento en PDF, imágenes o introducir el texto directamente en nuestro editor. La IA procesará el contenido y aplicará el subrayado según el esquema de colores predefinido. Este proceso te permitirá identificar rápidamente las partes más importantes de tu material de estudio.",
      list3: [
        "Sube tu documento o imágenes de texto",
        "Deja que la IA analice y subraye el contenido",
        "Recibe un documento subrayado listo para revisar",
      ],
      title4: "Ventajas del Subrayado con IA",
      list4: [
        "Ahorro de tiempo al estudiar",
        "Mejora en la retención de información",
        "Identificación rápida de puntos clave",
        "Aumento en la eficiencia de la revisión",
        "Reducción del esfuerzo manual en el subrayado",
      ],
      title5: "Aplicaciones del Subrayador de Texto con IA",
      desc5: "El Subrayador de Texto con IA es una herramienta versátil que puede ser utilizada en una variedad de contextos académicos y profesionales. Ya sea que estés preparando un examen, realizando una investigación o simplemente revisando un documento importante, esta herramienta te permitirá enfocarte en lo más relevante de manera rápida y eficiente.",
      list5: [
        "Estudiantes en preparación de exámenes",
        "Profesionales revisando informes",
        "Investigadores analizando textos académicos",
        "Cualquiera que necesite optimizar su proceso de estudio",
      ],
      title6: "Mejora tu Estudio con Nuestro Subrayador de Texto con IA",
      desc6: "Nuestro Subrayador de Texto con IA está diseñado para transformar tu manera de estudiar y procesar información. Aprovecha esta innovadora herramienta para identificar y concentrarte en las partes más importantes de tu material de estudio. Ya sea que estés en la escuela, en la universidad o en el trabajo, nuestro subrayador con IA te ayudará a maximizar tu eficiencia y efectividad. ¡Comienza a usarlo hoy mismo y descubre una nueva forma de estudiar!",
    },
    it: {
      title1: "Evidenziatore di Testo con IA",
      desc1: "Semplifica il tuo processo di studio con il nostro Evidenziatore di Testo con IA. Carica un documento PDF, diverse immagini o inserisci direttamente il testo per ricevere un'evidenziazione automatica che ti aiuterà a identificare rapidamente le idee principali, i dati importanti, le definizioni e le date chiave. Questo strumento è progettato per studenti e professionisti che cercano di ottimizzare il loro tempo e migliorare la comprensione del materiale.",
      list1: [
        "Compatibile con PDF, immagini e testo diretto",
        "Evidenziazione automatica ed efficiente",
        "Ideale per migliorare le tecniche di studio e revisione",
      ],
      title2: "Schema di Colori Intelligente",
      desc2: "Il nostro Evidenziatore di Testo con IA utilizza uno schema di colori intelligente per aiutarti a categorizzare e differenziare le informazioni chiave del tuo materiale di studio. Il sistema utilizza colori specifici per diversi tipi di contenuto, permettendoti di concentrarti su ciò che è più rilevante in modo efficace.",
      list2: [
        "Rosa per le idee principali",
        "Verde per i dati importanti",
        "Blu per le definizioni",
        "Rosso per le date chiave",
      ],
      title3: "Come Funziona",
      desc3: "Usare il nostro Evidenziatore di Testo con IA è molto semplice. Devi solo caricare il tuo documento PDF, le immagini o inserire direttamente il testo nel nostro editor. L'IA elaborerà il contenuto e applicherà l'evidenziazione in base allo schema di colori predefinito. Questo processo ti permetterà di identificare rapidamente le parti più importanti del tuo materiale di studio.",
      list3: [
        "Carica il tuo documento o immagini di testo",
        "Lascia che l'IA analizzi ed evidenzi il contenuto",
        "Ricevi un documento evidenziato pronto per la revisione",
      ],
      title4: "Vantaggi dell'Evidenziazione con IA",
      list4: [
        "Risparmio di tempo nello studio",
        "Miglioramento nella ritenzione delle informazioni",
        "Identificazione rapida dei punti chiave",
        "Aumento dell'efficienza di revisione",
        "Riduzione dello sforzo manuale nell'evidenziazione",
      ],
      title5: "Applicazioni dell'Evidenziatore di Testo con IA",
      desc5: "L'Evidenziatore di Testo con IA è uno strumento versatile che può essere utilizzato in una varietà di contesti accademici e professionali. Sia che tu stia preparando un esame, conducendo una ricerca o semplicemente rivedendo un documento importante, questo strumento ti permetterà di concentrarti su ciò che è più rilevante in modo rapido ed efficiente.",
      list5: [
        "Studenti in preparazione agli esami",
        "Professionisti che revisionano i rapporti",
        "Ricercatori che analizzano testi accademici",
        "Chiunque abbia bisogno di ottimizzare il proprio processo di studio",
      ],
      title6: "Migliora il tuo Studio con il Nostro Evidenziatore di Testo con IA",
      desc6: "Il nostro Evidenziatore di Testo con IA è progettato per trasformare il tuo modo di studiare ed elaborare le informazioni. Sfrutta questo strumento innovativo per identificare e concentrarti sulle parti più importanti del tuo materiale di studio. Sia che tu sia a scuola, all'università o al lavoro, il nostro evidenziatore con IA ti aiuterà a massimizzare la tua efficienza ed efficacia. Inizia a usarlo oggi stesso e scopri un nuovo modo di studiare!",
    },
    en: {
      title1: "AI Text Highlighter",
      desc1: "Enhance your study process with our AI Text Highlighter. Upload a PDF document, multiple images, or enter text directly to receive an automated highlight that helps you quickly identify main ideas, important data, definitions, and key dates. This tool is designed for students and professionals looking to optimize their time and improve their understanding of the material.",
      list1: [
        "Compatible with PDFs, images, and direct text",
        "Automated and efficient highlighting",
        "Ideal for improving study and review techniques",
      ],
      title2: "Intelligent Color Scheme",
      desc2: "Our AI Text Highlighter uses an intelligent color scheme to help you categorize and differentiate key information in your study material. The system employs specific colors for different types of content, allowing you to focus on the most relevant information effectively.",
      list2: [
        "Pink for main ideas",
        "Green for important data",
        "Blue for definitions",
        "Red for key dates",
      ],
      title3: "How It Works",
      desc3: "Using our AI Text Highlighter is very simple. You just need to upload your PDF document, images, or enter the text directly into our editor. The AI will process the content and apply the highlighting according to the predefined color scheme. This process will allow you to quickly identify the most important parts of your study material.",
      list3: [
        "Upload your document or text images",
        "Let the AI analyze and highlight the content",
        "Receive a highlighted document ready for review",
      ],
      title4: "Advantages of AI Highlighting",
      list4: [
        "Time-saving while studying",
        "Improvement in information retention",
        "Quick identification of key points",
        "Increased review efficiency",
        "Reduction of manual highlighting effort",
      ],
      title5: "Applications of AI Text Highlighter",
      desc5: "The AI Text Highlighter is a versatile tool that can be used in a variety of academic and professional contexts. Whether you're preparing for an exam, conducting research, or simply reviewing an important document, this tool will allow you to focus on the most relevant information quickly and efficiently.",
      list5: [
        "Students preparing for exams",
        "Professionals reviewing reports",
        "Researchers analyzing academic texts",
        "Anyone needing to optimize their study process",
      ],
      title6: "Enhance Your Study with Our AI Text Highlighter",
      desc6: "Our AI Text Highlighter is designed to transform the way you study and process information. Take advantage of this innovative tool to identify and focus on the most important parts of your study material. Whether you're in school, university, or at work, our AI highlighter will help you maximize your efficiency and effectiveness. Start using it today and discover a new way to study!",
    },
  };

  const content = texts[currentLanguage] || texts.es;

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title1}</h2>
        <p className="mb-4 text-gray-600">{content.desc1}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list1.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title2}</h2>
        <p className="mb-4 text-gray-600">{content.desc2}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list2.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title3}</h2>
        <p className="mb-4 text-gray-600">{content.desc3}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list3.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title4}</h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list4.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title5}</h2>
        <p className="mb-4 text-gray-600">{content.desc5}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.list5.map((item, index) => (
            <li key={index} className="mb-2">{item}</li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">{content.title6}</h2>
        <p className="text-gray-600">{content.desc6}</p>
      </section>
    </div>
  );
};

export default SEOHighlight;