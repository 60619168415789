import React from 'react';
import { BlockMath, InlineMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeSanitize from 'rehype-sanitize';
import remarkMath from 'remark-math';

const MarkdownPreview = ({ content }) => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeSanitize, rehypeKatex]}
      remarkPlugins={[remarkMath]}
      components={{
        math: ({ value }) => <BlockMath math={value} />,
        inlineMath: ({ value }) => <InlineMath math={value} />,
      }}
      disallowedElements={["pre"]}
      unwrapDisallowed
    >
      {content}
    </ReactMarkdown>
  );
};

export default MarkdownPreview;