import { useLanguage } from "../useLanguaje";

const SEOQuizzes = () => {
  const { currentLanguage } = useLanguage();
  const texts = {
    es: {
      title: "Generador de Cuestionarios Inteligente",
      intro:
        "Revoluciona tu método de estudio con nuestro generador de tests automatizado. Crea exámenes personalizados a partir de tus propios materiales de estudio, ya sean documentos PDF, imágenes o texto. Evalúa tu comprensión del temario de manera eficiente y efectiva.",
      features: [
        "Creación de preguntas automáticas basadas en tu contenido",
        "Tests adaptados a tu material de estudio específico",
        "Evaluación personalizada de conocimientos",
      ],
      dataVersatilityTitle: "Versatilidad en la Entrada de Datos",
      dataVersatilityIntro:
        "Nuestro sistema de generación de cuestionarios acepta múltiples formatos de entrada, adaptándose a tus necesidades y preferencias de estudio:",
      dataVersatilityFeatures: [
        "Carga de documentos PDF con tus apuntes o libros de texto",
        "Subida de imágenes de notas manuscritas o diagramas",
        "Introducción directa de texto para crear preguntas al instante",
      ],
      benefitsTitle: "Beneficios del Autoevaluación Automatizada",
      benefits: [
        "Identificación rápida de áreas que requieren más estudio",
        "Refuerzo de conceptos clave a través de preguntas dirigidas",
        "Simulación de condiciones de examen para mejor preparación",
        "Ahorro de tiempo en la creación manual de cuestionarios",
        "Adaptación del nivel de dificultad según tu progreso",
      ],
      advancedFeaturesTitle:
        "Características Avanzadas de Nuestro Generador de Tests",
      advancedFeatures: [
        "Análisis de contenido mediante inteligencia artificial",
        "Generación de preguntas de opción múltiple, verdadero/falso y respuesta corta",
        "Personalización de la cantidad y tipo de preguntas",
        "Retroalimentación instantánea sobre respuestas correctas e incorrectas",
        "Seguimiento del progreso y áreas de mejora",
      ],
      practicalApplicationsTitle: "Aplicaciones Prácticas",
      practicalApplicationsIntro:
        "Nuestro generador de cuestionarios es ideal para diversas situaciones de aprendizaje:",
      practicalApplications: [
        "Preparación para exámenes académicos",
        "Repaso de material de estudio",
        "Evaluación continua en cursos online",
        "Autoevaluación para profesionales en formación continua",
        "Creación de actividades interactivas para educadores",
      ],
      conclusionTitle: "Potencia tu Aprendizaje con Tests Personalizados",
      conclusion:
        "Nuestro Generador de Cuestionarios Inteligente transforma la manera en que te preparas para exámenes y evalúas tu conocimiento. Al crear tests basados en tu propio material de estudio, garantizas una preparación enfocada y eficiente. Ya sea que estés estudiando para un examen importante, repasando para una certificación profesional, o simplemente reforzando tu comprensión de un tema, nuestra herramienta de generación automática de preguntas te ayudará a alcanzar tus objetivos de aprendizaje. ¡Comienza a crear tus cuestionarios personalizados hoy y lleva tu estudio al siguiente nivel!",
    },
    it: {
      title: "Generatore di Quiz Intelligente",
      intro:
        "Rivoluziona il tuo metodo di studio con il nostro generatore di test automatizzato. Crea esami personalizzati a partire dai tuoi materiali di studio, che siano documenti PDF, immagini o testo. Valuta la tua comprensione del programma in modo efficiente ed efficace.",
      features: [
        "Creazione di domande automatiche basate sul tuo contenuto",
        "Test adattati al tuo materiale di studio specifico",
        "Valutazione personalizzata delle conoscenze",
      ],
      dataVersatilityTitle: "Versatilità nell'Inserimento dei Dati",
      dataVersatilityIntro:
        "Il nostro sistema di generazione di quiz accetta più formati di input, adattandosi alle tue esigenze e preferenze di studio:",
      dataVersatilityFeatures: [
        "Caricamento di documenti PDF con i tuoi appunti o libri di testo",
        "Caricamento di immagini di appunti manoscritti o diagrammi",
        "Inserimento diretto di testo per creare domande all'istante",
      ],
      benefitsTitle: "Benefici dell'Autovalutazione Automatizzata",
      benefits: [
        "Identificazione rapida delle aree che richiedono più studio",
        "Rafforzamento dei concetti chiave attraverso domande mirate",
        "Simulazione delle condizioni d'esame per una migliore preparazione",
        "Risparmio di tempo nella creazione manuale di quiz",
        "Adattamento del livello di difficoltà in base al tuo progresso",
      ],
      advancedFeaturesTitle:
        "Funzionalità Avanzate del Nostro Generatore di Test",
      advancedFeatures: [
        "Analisi del contenuto tramite intelligenza artificiale",
        "Generazione di domande a scelta multipla, vero/falso e risposta breve",
        "Personalizzazione del numero e del tipo di domande",
        "Feedback istantaneo su risposte corrette e errate",
        "Monitoraggio dei progressi e aree di miglioramento",
      ],
      practicalApplicationsTitle: "Applicazioni Pratiche",
      practicalApplicationsIntro:
        "Il nostro generatore di quiz è ideale per diverse situazioni di apprendimento:",
      practicalApplications: [
        "Preparazione per esami accademici",
        "Revisione del materiale di studio",
        "Valutazione continua nei corsi online",
        "Autovalutazione per professionisti in formazione continua",
        "Creazione di attività interattive per educatori",
      ],
      conclusionTitle: "Potenzia il tuo Apprendimento con Test Personalizzati",
      conclusion:
        "Il nostro Generatore di Quiz Intelligente trasforma il modo in cui ti prepari per gli esami e valuti le tue conoscenze. Creando test basati sul tuo materiale di studio, garantisci una preparazione mirata ed efficiente. Che tu stia studiando per un esame importante, ripassando per una certificazione professionale, o semplicemente rafforzando la tua comprensione di un argomento, il nostro strumento di generazione automatica di domande ti aiuterà a raggiungere i tuoi obiettivi di apprendimento. Inizia a creare i tuoi quiz personalizzati oggi e porta il tuo studio al livello successivo!",
    },
    en: {
      title: "Intelligent Quiz Generator",
      intro:
        "Revolutionize your study method with our automated test generator. Create personalized exams from your own study materials, whether they are PDF documents, images, or text. Evaluate your understanding of the syllabus efficiently and effectively.",
      features: [
        "Automatic question creation based on your content",
        "Tests tailored to your specific study material",
        "Personalized knowledge assessment",
      ],
      dataVersatilityTitle: "Data Input Versatility",
      dataVersatilityIntro:
        "Our quiz generation system accepts multiple input formats, adapting to your study needs and preferences:",
      dataVersatilityFeatures: [
        "Upload PDF documents with your notes or textbooks",
        "Upload images of handwritten notes or diagrams",
        "Direct text input to create questions instantly",
      ],
      benefitsTitle: "Benefits of Automated Self-Assessment",
      benefits: [
        "Quick identification of areas needing more study",
        "Reinforcement of key concepts through targeted questions",
        "Simulation of exam conditions for better preparation",
        "Time-saving in manual quiz creation",
        "Difficulty level adaptation according to your progress",
      ],
      advancedFeaturesTitle: "Advanced Features of Our Test Generator",
      advancedFeatures: [
        "Content analysis through artificial intelligence",
        "Generation of multiple-choice, true/false, and short answer questions",
        "Customization of the number and type of questions",
        "Instant feedback on correct and incorrect answers",
        "Progress tracking and areas for improvement",
      ],
      practicalApplicationsTitle: "Practical Applications",
      practicalApplicationsIntro:
        "Our quiz generator is ideal for various learning situations:",
      practicalApplications: [
        "Preparation for academic exams",
        "Review of study material",
        "Continuous assessment in online courses",
        "Self-assessment for professionals in continuous training",
        "Creation of interactive activities for educators",
      ],
      conclusionTitle: "Enhance Your Learning with Personalized Tests",
      conclusion:
        "Our Intelligent Quiz Generator transforms the way you prepare for exams and evaluate your knowledge. By creating tests based on your own study material, you ensure focused and efficient preparation. Whether you're studying for an important exam, reviewing for a professional certification, or simply reinforcing your understanding of a topic, our automatic question generation tool will help you achieve your learning goals. Start creating your personalized quizzes today and take your study to the next level!",
    },
  };

  const content = texts[currentLanguage] || texts.es;

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.title}
        </h2>
        <p className="mb-4 text-gray-600">{content.intro}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.features.map((feature, index) => (
            <li key={index} className="mb-2">
              {feature}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.dataVersatilityTitle}
        </h2>
        <p className="mb-4 text-gray-600">{content.dataVersatilityIntro}</p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.dataVersatilityFeatures.map((feature, index) => (
            <li key={index} className="mb-2">
              {feature}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.benefitsTitle}
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.benefits.map((benefit, index) => (
            <li key={index} className="mb-2">
              {benefit}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.advancedFeaturesTitle}
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.advancedFeatures.map((feature, index) => (
            <li key={index} className="mb-2">
              {feature}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.practicalApplicationsTitle}
        </h2>
        <p className="mb-4 text-gray-600">
          {content.practicalApplicationsIntro}
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {content.practicalApplications.map((application, index) => (
            <li key={index} className="mb-2">
              {application}
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {content.conclusionTitle}
        </h2>
        <p className="text-gray-600">{content.conclusion}</p>
      </section>
    </div>
  );
};

export default SEOQuizzes;