// src/components/SubscriptionContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAuth from './useAuthToken';

const SubscriptionContext = createContext();

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [token] = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchSubscriptionData = async () => {
    if (!token) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/checkpremium`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubscriptionType(data.subscriptionType);
        setRemainingCredits(data.remainingCredits);
      } else {
        setError('Failed to fetch subscription data');
      }
    } catch (err) {
      setError('Network error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
  }, [token]);

  return (
    <SubscriptionContext.Provider value={{ subscriptionType, remainingCredits, loading, error, refreshSubscription: fetchSubscriptionData }}>
      {children}
    </SubscriptionContext.Provider>
  );
};