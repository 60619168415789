import { default as React, useState } from "react";
import CurrentPath from "./CurrentPath";
import Quiz from "./Quiz";
import SEOQuizzes from "./seo/SEOQuizzes";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";
const apiUrl = process.env.REACT_APP_API_URL;

const QuizFile = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [quiz, setQuiz] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, clearToken] = useAuth();
  const { currentLanguage } = useLanguage();

  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const mobile = useMobile();

  const texts = {
    es: {
      uploadPrompt: "Por favor, sube un archivo o escribe algún texto antes de generar un quiz.",
      noTokenError: "No authentication token found.",
      subscriptionError: "Necesitas una suscripción de Pro o Standard para acceder a los cuestionarios.",
      fileTypeError: "Tipo de archivo no admitido. Sube una imagen o un archivo PDF.",
      textTooLongError: "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: ",
      fetchError: "Error fetching the quiz.",
      generalError: "Error, pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros.",
      processing: "Procesando...",
      quizGenerator: "Generador de Quiz",
      uploadInstructions: "Sube un documento en PDF o varias imágenes o puedes introducir texto directamente para generar un quiz basado en los apuntes proporcionados.",
      textOrPdf: "Escribe un texto o sube un PDF",
      generateQuiz: "Generar Quiz",
    },
    en: {
      uploadPrompt: "Please upload a file or write some text before generating a quiz.",
      noTokenError: "No authentication token found.",
      subscriptionError: "You need a Pro or Standard subscription to access the quizzes.",
      fileTypeError: "Unsupported file type. Upload an image or a PDF file.",
      textTooLongError: "The text is too long. Please upload a smaller file or enter less text. Number of tokens exceeded you have uploaded: ",
      fetchError: "Error fetching the quiz.",
      generalError: "Error, try again or with another smaller file. If the problem persists, contact us.",
      processing: "Processing...",
      quizGenerator: "Quiz Generator",
      uploadInstructions: "Upload a PDF document or several images, or you can directly enter text to generate a quiz based on the provided notes.",
      textOrPdf: "Write text or upload a PDF",
      generateQuiz: "Generate Quiz",
    },
    it: {
      uploadPrompt: "Per favore, carica un file o scrivi del testo prima di generare un quiz.",
      noTokenError: "Nessun token di autenticazione trovato.",
      subscriptionError: "Hai bisogno di un abbonamento Pro o Standard per accedere ai quiz.",
      fileTypeError: "Tipo di file non supportato. Carica un'immagine o un file PDF.",
      textTooLongError: "Il testo è troppo lungo. Per favore, carica un file più piccolo o inserisci meno testo. Numero di token superato: ",
      fetchError: "Errore durante il recupero del quiz.",
      generalError: "Errore, riprova o con un altro file più piccolo. Se il problema persiste, contattaci.",
      processing: "Elaborazione...",
      quizGenerator: "Generatore di Quiz",
      uploadInstructions: "Carica un documento PDF o più immagini, oppure puoi inserire direttamente del testo per generare un quiz basato sugli appunti forniti.",
      textOrPdf: "Scrivi un testo o carica un PDF",
      generateQuiz: "Genera Quiz",
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText("");
    setQuiz("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null);
    setQuiz("");
  };

  const handleGenerateQuiz = async () => {
    if (!file && !text.trim()) {
      alert(texts[currentLanguage].uploadPrompt);
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error(texts[currentLanguage].noTokenError);
      return;
    }

    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!subscriptionType?.includes("Standard") && !subscriptionType?.includes("Pro")) {
      alert(texts[currentLanguage].subscriptionError);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    if (file) {
      if (file.type.startsWith("image/") || file.type === "application/pdf") {
        formData.append("files", file);
      } else {
        alert(texts[currentLanguage].fileTypeError);
        setIsLoading(false);
        return;
      }
    } else {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/quiz`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          texts[currentLanguage].textTooLongError +
          data?.total_tokens +
          " y el máximo son " +
          data?.max_tokens +
          " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || texts[currentLanguage].fetchError);
      }

      setQuiz(JSON.parse(data.quiz));
      refreshSubscription();
    } catch (error) {
      console.error("Error generating quiz:", error);
      setErrorMessage(texts[currentLanguage].generalError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={texts[currentLanguage].quizGenerator} />
      <div className="container max-w-4xl mx-auto p-0 mt-8 sm:p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          📄 {texts[currentLanguage].quizGenerator}
        </h1>
        <p className="mb-6">
          {texts[currentLanguage].uploadInstructions}
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          {texts[currentLanguage].textOrPdf}
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder="Escribe aquí tu texto..."
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button
          onClick={handleGenerateQuiz}
          className="btn btn-primary mt-4 mb-8 w-full sm:w-auto"
        >
          {texts[currentLanguage].generateQuiz}
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">{texts[currentLanguage].processing}</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {quiz && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
            <Quiz questions={quiz} />
          </div>
        )}
      </div>
      {!token && !mobile && <SEOQuizzes />}
    </div>
  );
};

export default QuizFile;
