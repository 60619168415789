import React, { useState } from "react";
import CurrentPath from "./CurrentPath";
import SEOHighlight from "./seo/SEOHighlight";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";

const apiUrl = process.env.REACT_APP_API_URL;

const Highlight = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [highlightedText, setHighlightedText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const mobile = useMobile();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      uploadPrompt: "Por favor, sube un archivo o escribe algún texto antes de subrayar.",
      noAuthToken: "No authentication token found.",
      noSubscription: "Necesitas una suscripción para usar el servicio.",
      trialEnded: "El periodo de prueba ha terminado.",
      unsupportedFile: "Tipo de archivo no admitido. Sube una imagen o un archivo PDF.",
      tooLong: "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: ",
      errorFetching: "Error pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros.",
      processing: "Procesando archivos...",
      highlightText: "Subrayar Texto",
      textPlaceholder: "Escribe aquí tu texto...",
      title: "📝 Subrayador de texto",
      description: "Sube un documento en PDF o varias imágenes o puedes introducir texto directamente para obtener un subrayado que te ayude a estudiar.",
      colorScheme: "Se usa el siguiente esquema de colores: rosa para ideas principales, verde para datos importantes, azul para definiciones y rojo para fechas clave.",
      subtitle: "Escribe un texto o sube un PDF",
    },
    en: {
      uploadPrompt: "Please upload a file or write some text before highlighting.",
      noAuthToken: "No authentication token found.",
      noSubscription: "You need a subscription to use the service.",
      trialEnded: "The trial period has ended.",
      unsupportedFile: "Unsupported file type. Upload an image or a PDF file.",
      tooLong: "The text is too long. Please upload a smaller file or enter less text. Tokens exceeded: ",
      errorFetching: "Error, please try again or with a smaller file. If the problem persists, contact us.",
      processing: "Processing files...",
      highlightText: "Highlight Text",
      textPlaceholder: "Write your text here...",
      title: "📝 Text Highlighter",
      description: "Upload a PDF document or multiple images, or you can enter text directly to get a highlight that helps you study.",
      colorScheme: "The following color scheme is used: pink for main ideas, green for important data, blue for definitions, and red for key dates.",
      subtitle: "Write text or upload a PDF",
    },
    it: {
      uploadPrompt: "Per favore, carica un file o scrivi del testo prima di evidenziare.",
      noAuthToken: "Nessun token di autenticazione trovato.",
      noSubscription: "Hai bisogno di un abbonamento per utilizzare il servizio.",
      trialEnded: "Il periodo di prova è terminato.",
      unsupportedFile: "Tipo di file non supportato. Carica un'immagine o un file PDF.",
      tooLong: "Il testo è troppo lungo. Per favore, carica un file più piccolo o inserisci meno testo. Token superati: ",
      errorFetching: "Errore, riprova o con un file più piccolo. Se il problema persiste, contattaci.",
      processing: "Elaborazione file...",
      highlightText: "Evidenzia testo",
      textPlaceholder: "Scrivi qui il tuo testo...",
      title: "📝 Evidenziatore di testo",
      description: "Carica un documento PDF o più immagini, oppure puoi inserire direttamente il testo per ottenere un'evidenziazione che ti aiuti a studiare.",
      colorScheme: "Viene utilizzato il seguente schema di colori: rosa per le idee principali, verde per i dati importanti, blu per le definizioni e rosso per le date chiave.",
      subtitle: "Scrivi testo o carica un PDF",
    }
  };

  const content = texts[currentLanguage] || texts.es;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText("");
    setHighlightedText("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null);
    setHighlightedText("");
  };

  const handleHighlight = async () => {
    if (!file && !text.trim()) {
      alert(content.uploadPrompt);
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error(content.noAuthToken);
      return;
    }

    if (subscriptionType === "No Subscription") {
      alert(content.noSubscription);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";

    if (file) {
      // if (file.type === "application/pdf") {
      //   try {
      //     const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file))
      //       .promise;
      //     let textContent = "";
      //     for (let i = 1; i <= pdf.numPages; i++) {
      //       const page = await pdf.getPage(i);
      //       const text = await page.getTextContent();
      //       text.items.forEach((item) => {
      //         textContent += item.str + " ";
      //       });
      //     }
      //     pdftext = textContent;
      //     formData.append("text", pdftext);
      //   } catch (error) {
      //     console.error("Error extracting text from PDF:", error);
      //     setErrorMessage(
      //       "Error al extraer el texto del PDF. Por favor, prueba con otro archivo."
      //     );
      //     setIsLoading(false);
      //     return;
      //   }

      if (file.type.startsWith("image/") || file.type === "application/pdf") {
        formData.append("files", file);
      } else {
        alert(content.unsupportedFile);
        setIsLoading(false);
        return;
      }
    }

    if (text) {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/highlight`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          content.tooLong +
          data?.total_tokens +
          " y el máximo son " + data?.max_tokens + " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching highlighted text.");
      }

      data.highlight = data.highlight.replace(/\\\"/g, '"');

      refreshSubscription();

      setHighlightedText(data.highlight);
    } catch (error) {
      console.error("Error highlighting text:", error);
      setErrorMessage(content.errorFetching);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={content.title} />
      <div className="container max-w-4xl mx-auto p-0 mt-8 sm:p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          {content.title}
        </h1>
        <p>{content.description}</p>
        <p className="mb-6 mt-2">{content.colorScheme}</p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          {content.subtitle}
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder={content.textPlaceholder}
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="input input-bordered w-full mt-4"
            />
          )}
        </div>

        <button
          onClick={handleHighlight}
          className="btn btn-primary w-full sm:w-auto mt-4"
        >
          {content.highlightText}
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">{content.processing}</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {highlightedText && (
          <div
            className="p-4 mt-4 rounded-box text-lg border border-base-300"
            dangerouslySetInnerHTML={{ __html: highlightedText }}
          ></div>
        )}
      </div>
      {!token && !mobile && <SEOHighlight />}
    </div>
  );
};

export default Highlight;
