import { Preferences } from "@capacitor/preferences";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
function useAuth() {
  const [token, setToken] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      const params = new URLSearchParams(location.search);
      const urlToken = params.get("token");

      if (urlToken) {
        // Guardar el token en el localStorage y estado
        localStorage.setItem("token", urlToken);
        setToken(urlToken);

        // Eliminar el token de la URL
        params.delete("token");
        navigate({ search: params.toString() }, { replace: true });
      } else {
        // Si no hay token en la URL, buscar en el localStorage
        const storedToken = localStorage.getItem("token");
        const ret = await Preferences.get({ key: "token" });

        if (storedToken) {
          setToken(storedToken);
        } else if (ret?.value) {
          setToken(ret?.value);
        } else setToken(null);
      }
    };
    fetchToken();
  }, [location, navigate]);

  // Función para limpiar el token del localStorage y estado
  const clearToken = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  return [token, clearToken];
}

export default useAuth;
