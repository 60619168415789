import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import biologicPaladinImage from "../assets/biologic-paladin.webp";
import generalTutor from "../assets/general-tutor.webp";
import historyHunter from "../assets/history-hunter.webp";
import mathMage from "../assets/math-mage.webp";
import CurrentPath from "./CurrentPath";
import MarkdownPreview from "./MarkdownPreview";
import SEOTutors from "./seo/SEOTutors";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje"; // Fixed typo from useLanguaje to useLanguage
import useMobile from "./useMobile";

const controller = new AbortController();
const { signal } = controller;

const apiUrl = process.env.REACT_APP_API_URL;

const texts = {
  es: {
    callingTutor: "Llamando al tutor...",
    initiateConversation: "Iniciar conversación",
    uploadPdf:
      "Sube un pdf con el temario que quieras y hazle preguntas (solo PDFs máximo 100Mb)",
    selectTutorError: "Es obligatorio seleccionar un tutor.",
    uploadPdfError: "Es obligatorio subir un PDF para iniciar la conversación.",
    returnToSelection: "Volver a seleccionar tutor",
    chatWithTutors: "Chatea con Tutores IA",
    selectTutorPrompt:
      "Selecciona un tutor IA para resolver todas tus dudas. Elige uno de los tutores predefinidos que se adapte a tus necesidades (¡Pronto se añadirán más!).",
    loadingResponse: "Cargando la respuesta...",
    send: "Enviar",
    writing: "Escribiendo...",
    sent: "Enviado",
    seen: "Visto",
    tutorLabel: "Tutor: ",
    yourLabel: "Tú",
    writeMessagePlaceholder: "Escribe tu mensaje aquí...",
    custom: "Personalizado", // Added 'custom' key for proper display
  },
  en: {
    callingTutor: "Calling the tutor...",
    initiateConversation: "Start conversation",
    uploadPdf:
      "Upload a pdf with the syllabus you want and ask questions (PDFs only, max 100Mb)",
    selectTutorError: "Selecting a tutor is mandatory.",
    uploadPdfError: "Uploading a PDF is mandatory to start the conversation.",
    returnToSelection: "Return to select tutor",
    chatWithTutors: "Chat with AI Tutors",
    selectTutorPrompt:
      "Select an AI tutor to solve all your doubts. Choose one of the predefined tutors that suits your needs (More will be added soon!).",
    loadingResponse: "Loading the response...",
    send: "Send",
    writing: "Writing...",
    sent: "Sent",
    seen: "Seen",
    tutorLabel: "Tutor: ",
    yourLabel: "You",
    writeMessagePlaceholder: "Write your message here...",
    custom: "Custom", // Added 'custom' key for proper display
  },
  it: {
    callingTutor: "Chiamata al tutor...",
    initiateConversation: "Avvia conversazione",
    uploadPdf:
      "Carica un PDF con il programma che desideri e fai le tue domande (solo PDF, massimo 100 MB)",
    selectTutorError: "È obbligatorio selezionare un tutor.",
    uploadPdfError: "È obbligatorio caricare un PDF per iniziare la conversazione.",
    returnToSelection: "Torna alla selezione del tutor",
    chatWithTutors: "Chatta con i tutor IA",
    selectTutorPrompt:
      "Seleziona un tutor IA per risolvere tutti i tuoi dubbi. Scegli uno dei tutor predefiniti che meglio si adatta alle tue esigenze (presto ne verranno aggiunti altri!).",
    loadingResponse: "Caricamento della risposta...",
    send: "Invia",
    writing: "Sto scrivendo...",
    sent: "Inviato",
    seen: "Visto",
    tutorLabel: "Tutor: ",
    yourLabel: "Tu",
    writeMessagePlaceholder: "Scrivi il tuo messaggio qui...",
    custom: "Personalizzato", // Added 'custom' key for proper display
  },
};

export const predefinedTutors = [
  // Exported predefinedTutors
  {
    id: 1,
    name: {
      es: "Tutor Maestro",
      en: "Master Tutor",
      it: "Tutor Maestro"
    },
    subject: {
      es: "Profesor general",
      en: "General Teacher",
      it: "Professore generale"
    },
    image: generalTutor,
  },
  {
    id: 2, 
    name: {
      es: "Mago de Matemáticas",
      en: "Math Mage",
      it: "Mago di Matematica"
    },
    subject: {
      es: "Profesor de Matemáticas",
      en: "Math Teacher",
      it: "Professore di Matematica"
    },
    image: mathMage,
  },
  {
    id: 3,
    name: {
      es: "Paladín Biológico",
      en: "Biologic Paladin",
      it: "Paladino Biologico"
    },
    subject: {
      es: "Profesor de Biología",
      en: "Biology Teacher",
      it: "Professore di Biologia"
    },
    image: biologicPaladinImage,
  },
  {
    id: 4,
    name: {
      es: "Cazador de Historia",
      en: "History Hunter",
      it: "Cacciatore di Storia"
    },
    subject: {
      es: "Profesor de Historia",
      en: "History Teacher",
      it: "Professore di Storia"
    },
    image: historyHunter,
  },
  {
    id: "custom",
    name: {
      es: "Custom",
      en: "Custom",
      it: "Custom"
    },
    subject: {
      es: "Sube un pdf con el temario que quieras",
      en: "Upload a pdf with the syllabus you want",
      it: "Carica un PDF con il programma che desideri"
    },
    image: generalTutor,
    isCustom: true,
  },
];

const TutorCard = ({
  id,
  name,
  subject,
  image,
  isCustom,
  onSelect,
  isSelected,
}) => {
  const { currentLanguage } = useLanguage();
  const text = texts[currentLanguage] || texts.es;

  const cardClasses = `
        transition-all duration-300 ease-in-out
        ${isSelected ? "scale-105 shadow-xl" : "scale-100 hover:scale-102"} 
        ${
          isCustom
            ? "border-4 border-yellow-400 bg-yellow-50"
            : "border-2 border-gray-200"
        }
        ${!isSelected && "opacity-70 hover:opacity-90"}
        bg-white rounded-xl p-6 m-3
        transform hover:-translate-y-1
        cursor-pointer
      `;

  return (
    <div className={cardClasses} onClick={() => onSelect(id)}>
      <div className="relative">
        <img
          src={image}
          alt={name[currentLanguage]}
          className="w-24 h-24 rounded-full mx-auto object-cover shadow-md hover:shadow-lg transition-shadow duration-200"
        />
        {isCustom && (
          <span className="absolute top-0 right-0 bg-yellow-400 text-xs font-bold px-2 py-1 rounded-full">
            {text.custom}
          </span>
        )}
      </div>
      <h3 className="text-2xl font-bold text-center mt-4 text-gray-800">
        {name[currentLanguage]}
      </h3>
      <p className="text-center text-gray-600 mt-2 font-medium">
        {subject[currentLanguage]}
      </p>
    </div>
  );
};

// Nuevo componente TypingMessage
const TypingMessage = ({ text }) => {
  const { currentLanguage } = useLanguage();
  const langTexts = texts[currentLanguage] || texts.es;

  return (
    <div className="p-2 my-1 rounded bg-green-200">
      {langTexts.tutorLabel}
      {text}
    </div>
  );
};

const Chat = React.forwardRef(({ assistantData, selectedTutor }, ref) => {
  const { currentLanguage } = useLanguage();
  const langTexts = texts[currentLanguage] || texts.es;

  const token = localStorage.getItem("token");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  //const [assistantData, setAssistantData] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(""); // Nuevo estado para el mensaje en construcción
  const [isFinal, setIsFinal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(apiUrl + "/api/easyexams/ocr", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.nowpay) {
          window.location.href = "https://www.fotoexamen.com/precios.html";
        } else {
          setNewMessage(responseData.texts[0]);
        }
      } else {
        alert(
          "No se pudo detectar el texto en la imagen.  Asegúrese de que su imagen tenga un tamaño inferior a 20 MB y tenga uno de los siguientes formatos: .png, .jpeg, .gif o .webp."
        );
      }
    } catch (error) {
      console.error("Error processing the image:", error);
    }
  };

  const fetchData = async () => {
    if (!assistantData || !token || isFetching) return;
    setIsFetching(true);
    let promptutor;
    if (assistantData.tutor_name === "Math Mage") {
      promptutor =
        "Eres un tutor de matemáticas, pregúntale al alumno qué necesita";
    } else if (assistantData.tutor_name === "History Hunter") {
      promptutor =
        "Eres un tutor de Historia, pregúntale al alumno qué necesita";
    } else if (assistantData.tutor_name === "Biologic Paladin") {
      promptutor =
        "Eres un tutor de Biología, pregúntale al alumno qué necesita";
    } else {
      promptutor = "Eres un tutor general, pregúntale al alumno qué necesita";
    }

    await fetchEventSource(
      `${apiUrl}/api/easyexams/tutors/stream/${assistantData.assistant_id}/${assistantData.thread_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: promptutor,
        }),
        onopen(res) {
          if (res.ok) console.log("Connection made", res);
          else console.error("Connection failed", res);
        },
        onmessage(event) {
          const { text_value, final } = JSON.parse(event.data);
          setCurrentMessage((prev) => prev + text_value);
          if (final) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: currentMessage + text_value, sender: "tutor" },
            ]);
            setCurrentMessage("");
          }
        },
        onclose() {},
        onerror(err) {
          console.error("There was an error from the server", err);
        },
        signal,
      }
    );

    setIsFetching(false);
  };
  useImperativeHandle(ref, () => ({
    fetchData,
  }));

  useEffect(() => {
    if (isFinal) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: currentMessage, sender: "tutor" },
      ]);
      setCurrentMessage(""); // Limpia el mensaje en construcción para el próximo mensaje
      setIsFinal(false); // Restablece isFinal para el próximo mensaje
    }
  }, [isFinal, currentMessage]);

  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;
    // Limpiamos el campo de entrada
    setIsLoading(true);
    setNewMessage("");
    // Agregamos el mensaje del usuario al estado de mensajes
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: messageText, sender: "user" },
    ]);

    await fetchEventSource(
      `${apiUrl}/api/easyexams/tutors/stream/${assistantData.assistant_id}/${assistantData.thread_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "text/event-stream",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: messageText,
        }),
        onopen(res) {
          if (res.ok) {
          } else {
            console.error("Connection failed", res);
          }
        },
        onmessage(event) {
          const { text_value, final } = JSON.parse(event.data);

          if (final) {
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: currentMessage + text_value, sender: "tutor" },
            ]);
            setCurrentMessage("");
          } else {
            setCurrentMessage((prev) => prev + text_value);
          }
        },
        onclose() {
          controller.abort();
        },
        onerror(err) {
          console.error("There was an error from the server", err);
        },
        signal,
      }
    );
    setIsLoading(false);
  };
  const handleSendMessage = () => {
    sendMessage(newMessage);
  };

  // Componente para mostrar el mensaje que se está escribiendo
  const TypingMessageComponent = ({ text }) => (
    <div className="p-2 my-1 rounded">
      <div className="chat-bubble">
        <MarkdownPreview content={text} />
        <div className="text-xs">{langTexts.writing}</div>
      </div>
    </div>
  );

  const handleOpenFileDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full mt-4 p-4 bg-white shadow rounded-lg">
      <div
        className="mb-4 p-2 rounded h-[32rem] overflow-auto"
        style={{ minHeight: "16rem" }}
      >
        {messages.length === 0 ? (
          <div className="text-gray-400 text-center">
            {langTexts.callingTutor}
          </div>
        ) : (
          <div className="overflow-auto h-full" ref={messagesEndRef}>
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat ${
                  msg.sender === "user" ? "chat-end" : "chat-start"
                }`}
              >
                <div className="chat-image avatar">
                  <div className="w-10 rounded-full">
                    {msg.sender !== "user" ? (
                      <img
                        alt="Avatar"
                        src={
                          selectedTutor &&
                          predefinedTutors.find((t) => t.id === selectedTutor)
                            .image
                        }
                      />
                    ) : (
                      <div className="avatar placeholder">
                        <div className="bg-neutral text-neutral-content rounded-full w-12 pr-2">
                          <span>🙋🏻‍♂️</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="chat-header text-white">
                  {selectedTutor && msg.sender !== "user"
                    ? predefinedTutors.find((t) => t.id === selectedTutor).name[
                        currentLanguage
                      ]
                    : langTexts.yourLabel}{" "}
                  <time className="text-xs opacity-50">
                    {new Date().toLocaleTimeString().slice(0, 5)}
                  </time>
                </div>
                <div className="chat-bubble">
                  <MarkdownPreview content={msg.text} />
                </div>
                <div className="chat-footer opacity-50 text-white">
                  {msg.sender === "user" ? langTexts.sent : langTexts.seen}
                </div>
              </div>
            ))}
            {currentMessage && <TypingMessageComponent text={currentMessage} />}
          </div>
        )}
      </div>
      <div className="flex">
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <button
          type="button"
          className="p-2 btn btn-ghost"
          onClick={handleOpenFileDialog}
          hidden={isLoading || messages.length === 0}
        >
          <FontAwesomeIcon icon={faCamera} className="w-6 h-6" />
        </button>
        <textarea
          ref={inputRef}
          className="border rounded p-2 flex-grow min-h-16"
          placeholder={langTexts.writeMessagePlaceholder}
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
          }}
          onKeyPress={(e) =>
            e.key === "Enter" && !e.shiftKey && handleSendMessage()
          }
          style={{ overflow: "hidden" }}
          hidden={isLoading || messages.length === 0}
        />
        {isLoading && (
          <div className="flex items-center space-x-2">
            <div className="p-2 my-1 rounded bg-green-100">
              {langTexts.loadingResponse}{" "}
            </div>
            <div className="loading"></div>
          </div>
        )}
        <button
          className="btn btn-primary ml-2"
          onClick={handleSendMessage}
          hidden={isLoading || messages.length === 0}
        >
          {langTexts.send}
        </button>
      </div>
    </div>
  );
});

const TutorsGallery = ({ setShowNoTokenModal }) => {
  const { currentLanguage } = useLanguage();
  const langTexts = texts[currentLanguage] || texts.es;

  const [selectedTutor, setSelectedTutor] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [assistantData, setAssistantData] = useState(null);
  const chatRef = useRef();
  const fileInputRef = useRef(null); // Referencia al input de archivos
  const [file, setFile] = useState(null); // Estado para guardar el archivo
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    subscriptionType,
    loading,
    error,
    refreshSubscription,
    remainingCredits,
  } = useSubscription();
  const [token, clearToken] = useAuth();
  const mobile = useMobile();

  const [loadingIniciarChat, setLoadingIniciarChat] = useState(false);

  const handleSelectTutor = (id) => {
    setSelectedTutor(id);
    setShowForm(true);
    setShowChat(false);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; // Renamed variable to avoid shadowing
    if (!selectedFile) {
      console.error("No se ha seleccionado ningún archivo");
      return;
    }
    setFile(selectedFile); // Guarda el archivo en el estado
  };

  const createAssistant = async (tutorName, file) => {
    if (!token) {
      console.error("No se encontró el token de autorización");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let body = null;

    if (tutorName === "Custom") {
      // Si el tutor es personalizado, se enviará el archivo
      const formData = new FormData();
      formData.append("file", file);
      formData.append("tutor_name", tutorName);
      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]); // Verifica si el archivo y otros datos se agregan correctamente
      }
      body = formData;
    } else {
      // Para otros tutores, no se envía el archivo
      const formData = new FormData();
      formData.append("tutor_name", tutorName);
      body = formData;
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/tutors`, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        console.error("Error al crear el asistente:", response.statusText);
        return;
      }

      const data = await response.json();
      setAssistantData({
        thread_id: data.thread_id,
        tutor_name: tutorName,
        assistant_id: data.assistant_id,
      });
    } catch (error) {
      console.error("Error al crear el asistente:", error);
    }
  };

  const handleStartConversation = () => {
    if (!token) {
      setShowNoTokenModal(true);
      return;
    }

    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      /* window.location.href="/ver-precios" */
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById("trial_ended_modal").showModal();
      return;
    }

    if (!selectedTutor) {
      setErrorMessage(langTexts.selectTutorError);
      return;
    }
    // Encuentra el tutor seleccionado en tu lista de tutores predefinidos
    const tutor = predefinedTutors.find((t) => t.id === selectedTutor);

    if (!tutor) {
      setErrorMessage(langTexts.selectTutorError);
      return;
    }

    if (tutor.name.en === "Custom") {
      // Changed '==' to '===' for strict comparison
      if (!file) {
        setErrorMessage(langTexts.uploadPdfError);
        return;
      }
    }

    setLoadingIniciarChat(true);
    // Llama a createAssistant con el nombre del tutor en inglés
    createAssistant(tutor.name.en, file)
      .then(() => {
        //chatRef.current.fetchData();
        setShowChat(true); // Asegúrate de mostrar el componente Chat después de crear el asistente
      })
      .finally(() => {
        setLoadingIniciarChat(false);
        refreshSubscription();
      });
  };

  useEffect(() => {
    if (showChat && chatRef.current) {
      chatRef.current.fetchData();
    }
  }, [showChat, chatRef.current]);

  const handleReturnToSelection = () => {
    setShowChat(false);
    setShowForm(false);
    setSelectedTutor(null);
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={langTexts.chatWithTutors} />
      {!showChat && (
        <>
          <div className="text-center max-w-xl mx-auto py-12">
            <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
              🤖 {langTexts.chatWithTutors}
            </h1>
            <p>{langTexts.selectTutorPrompt}</p>
          </div>
          <div className="flex flex-wrap mb-64 items-center justify-center w-full max-w-4xl mx-auto">
            {predefinedTutors.map((tutor) => (
              <TutorCard
                key={tutor.id}
                id={tutor.id}
                name={tutor.name}
                subject={tutor.subject}
                image={tutor.image}
                isCustom={tutor.isCustom}
                onSelect={handleSelectTutor}
                isSelected={selectedTutor === tutor.id}
              />
            ))}
            {showForm && (
              <div className="w-full p-4">
                {selectedTutor === "custom" && (
                  <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {langTexts.uploadPdf}
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      onChange={handleFileChange}
                    />
                  </div>
                )}
                {errorMessage && (
                  <div className="text-red-500 text-sm font-bold mb-2">
                    {errorMessage}
                  </div>
                )}
                <div className="flex items-center justify-center space-x-4">
                  <button
                    className="mt-4 btn btn-primary flex items-center"
                    onClick={handleStartConversation}
                    disabled={loadingIniciarChat} // Disable button while loading
                  >
                    {langTexts.initiateConversation}
                    {loadingIniciarChat && (
                      <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white-600 ml-2"></div> // Adjusted spinner size
                    )}
                  </button>
                </div>
              </div>
            )}
            {!token && !mobile && <SEOTutors />}
          </div>
        </>
      )}

      {showChat && (
        <>
          <button
            onClick={handleReturnToSelection}
            className="btn btn-primary mb-4"
          >
            {langTexts.returnToSelection}
          </button>
          <Chat
            assistantData={assistantData}
            ref={chatRef}
            selectedTutor={selectedTutor}
          />
        </>
      )}
    </div>
  );
};

export { Chat, TutorCard, TutorsGallery };
