import { Directory, Filesystem } from "@capacitor/filesystem";
import { Preferences } from "@capacitor/preferences";
import { FileOpener } from "@capawesome-team/capacitor-file-opener";
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  pdf,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import html2canvasPro from "html2canvas-pro";
import { jsPDF } from "jspdf";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilePdf } from "react-icons/fa";
import CurrentPath from "./CurrentPath";
import "./ExamList.css";
import MarkdownPreview from "./MarkdownPreview";
import { useLanguage } from "./useLanguaje";

const apiUrl = process.env.REACT_APP_API_URL;

const texts = {
  en: {
    generatedBy: "Generated by photoexamai.com",
    statement: "Statement:",
    result: "Result:",
    downloadingPDF: "Downloading PDF...",
    downloadPDF: "Download PDF",
    noExamsHistory: "No exams in history.",
    pageText: "Page {{currentPage}} of {{totalPages}}",
    downloadingPDFMobile: "Downloading PDF mobile...",
    errorSavingFileChrome: "Error saving file in chrome:",
    errorOpeningFile: "Error opening file:",
    errorGeneratingSavingPDF: "Error generating or saving PDF:",
    noAuthenticationToken: "No authentication token found.",
    loading: "Loading...",
    selectDatePlaceholder: "Select a date",
    historyOfResolvedExams: "History of resolved exams",
    historyOfExams: "History of exams",
    downloadPDFButton: "Download PDF",
    generatedByDescription: "Generated by photoexamai.com",
    statementLabel: "Statement:",
    resultLabel: "Result:",
    downloadPDFText: "Download PDF",
    downloadingPDFText: "Downloading PDF...",
  },
  es: {
    generatedBy: "Generado por fotoexamen.com",
    statement: "Enunciado:",
    result: "Resultado:",
    downloadingPDF: "Descargando PDF...",
    downloadPDF: "Descargar PDF",
    noExamsHistory: "No hay exámenes en el historial.",
    pageText: "Página {{currentPage}} de {{totalPages}}",
    downloadingPDFMobile: "Descargando PDF móvil...",
    errorSavingFileChrome: "Error al guardar el archivo en Chrome:",
    errorOpeningFile: "Error al abrir el archivo:",
    errorGeneratingSavingPDF: "Error al generar o guardar el PDF:",
    noAuthenticationToken: "No se encontró el token de autenticación.",
    loading: "Cargando...",
    selectDatePlaceholder: "Seleccionar una fecha",
    historyOfResolvedExams: "Historial de exámenes resueltos",
    historyOfExams: "Historial de exámenes",
    downloadPDFButton: "Descargar PDF",
    generatedByDescription: "Generado por fotoexamen.com",
    statementLabel: "Enunciado:",
    resultLabel: "Resultado:",
    downloadPDFText: "Descargar PDF",
    downloadingPDFText: "Descargando PDF...",
  },
  it: {
    generatedBy: "Generato da risolvicompiti.it",
    statement: "Enunciato:",
    result: "Risultato:",
    downloadingPDF: "Download PDF...",
    downloadPDF: "Scarica PDF",
    noExamsHistory: "Nessun esame nella cronologia.",
    pageText: "Pagina {{currentPage}} di {{totalPages}}",
    downloadingPDFMobile: "Download PDF mobile...",
    errorSavingFileChrome: "Errore durante il salvataggio del file in Chrome:",
    errorOpeningFile: "Errore durante l'apertura del file:",
    errorGeneratingSavingPDF: "Errore durante la generazione o il salvataggio del PDF:",
    noAuthenticationToken: "Token di autenticazione non trovato.",
    loading: "Caricamento...",
    selectDatePlaceholder: "Seleziona una data",
    historyOfResolvedExams: "Cronologia degli esami risolti",
    historyOfExams: "Cronologia degli esami",
    downloadPDFButton: "Scarica PDF",
    generatedByDescription: "Generato da risolvicompiti.it",
    statementLabel: "Enunciato:",
    resultLabel: "Risultato:",
    downloadPDFText: "Scarica PDF",
    downloadingPDFText: "Download PDF...",
  },
};

function removeMarkdown(markdown) {
  return (
    markdown
      // Remove code blocks
      .replace(/```[\s\S]*?```/g, "")
      // Remove inline code
      .replace(/`[^`]*`/g, "")
      // Remove images
      .replace(/!\[.*?\]\(.*?\)/g, "")
      // Remove links
      .replace(/\[([^\]]+)\]\((.*?)\)/g, "$1")
      // Remove blockquotes
      .replace(/^>\s+/gm, "")
      // Remove headers
      .replace(/^#{1,6}\s+(.*)$/gm, "$1")
      // Remove horizontal rules
      .replace(/^(-{3,}|_{3,}|\*{3,})$/gm, "")
      // Remove bold and italic
      .replace(/(\*\*|__)(.*?)\1/g, "$2")
      .replace(/(\*|_)(.*?)\1/g, "$2")
      // Remove strikethrough
      .replace(/~~(.*?)~~/g, "$1")
      // Remove lists
      .replace(/^\s*([-+*]|(\d+\.))\s+/gm, "")
  );
}

export const downloadPDF = async (exam, index, texts) => {
  if (localStorage.getItem("token")) {
    console.log("Downloading PDF", exam, index);

    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.height;
    const pageMargin = 20; // Margen superior e inferior de cada página

    // Configuración inicial del documento
    doc.setFont("helvetica", "normal");

    // URL del logo
    const logoUrl = "/logo512.png";

    // Cargar y agregar el logo en el centro
    doc.addImage(logoUrl, "PNG", 85, 10, 40, 40, undefined, "FAST");

    let yPosition = 60;

    // Encabezado debajo del logo
    doc.setFontSize(14);
    doc.setTextColor(40);
    doc.text(
      texts.generatedBy,
      105,
      yPosition,
      null,
      null,
      "center"
    );
    yPosition += 10;

    doc.setDrawColor(0);
    doc.setLineWidth(0.2);
    doc.line(10, yPosition, 200, yPosition);

    yPosition += 10;

    // Añadir el enunciado
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(texts.statement, 10, yPosition);
    yPosition += 10;

    doc.setFont("helvetica", "normal");
    const enunciadoLines = doc.splitTextToSize(exam.ocrText, 180);
    doc.text(enunciadoLines, 10, yPosition);
    yPosition += enunciadoLines.length * 7;

    // Comprobar si hay espacio suficiente para comenzar el resultado en la misma página
    if (yPosition + 40 > pageHeight - pageMargin) {
      doc.addPage();
      yPosition = pageMargin;
    } else {
      yPosition += 20; // Espacio adicional entre enunciado y resultado
    }

    // Añadir el texto del resultado
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(texts.result, 10, yPosition);
    yPosition += 10;

    // Procesar el resultado
    doc.setFont("helvetica", "normal");
    const resultText = exam.response;
    const mathRegex = /\$\$([\s\S]*?)\$\$|\$(.*?)\$/g;
    let match;
    let lastIndex = 0;

    let equationIndex = 0;
    const equationElements = document.getElementsByClassName("katex-html");

    console.log("Starting to process result text");
    console.log("Result text:", resultText);
    console.log("Number of equation elements:", equationElements.length);

    while ((match = mathRegex.exec(resultText)) !== null) {
      console.log("Found math expression:", match[0]);
      // Añadir el texto antes de la fórmula
      const beforeMath = removeMarkdown(
        resultText.slice(lastIndex, match.index)
      );
      const beforeMathLines = doc.splitTextToSize(beforeMath, 180);
      doc.text(beforeMathLines, 10, yPosition);
      yPosition += beforeMathLines.length * 7;

      console.log("Added text before math. New Y position:", yPosition);

      // Obtener la imagen de la ecuación ya renderizada
      if (equationElements[equationIndex]) {
        console.log("Rendering equation to canvas");
        const canvas = await html2canvasPro(equationElements[equationIndex], {
          scale: 2,
          useCORS: true,
          logging: true,
        });

        const imgData = canvas.toDataURL("image/png");
        const maxImgWidth = 180;
        const maxImgHeight = 100; // Ajusta este valor según sea necesario
        let imgWidth = maxImgWidth;
        let imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Si la altura es mayor que maxImgHeight, ajustamos el ancho proporcionalmente
        if (imgHeight > maxImgHeight) {
          imgHeight = maxImgHeight;
          imgWidth = (canvas.width * imgHeight) / canvas.height;
        }

        console.log("Image dimensions:", imgWidth, "x", imgHeight);

        if (yPosition + imgHeight > pageHeight - pageMargin) {
          console.log("Adding new page");
          doc.addPage();
          yPosition = pageMargin;
        }

        // Centrar las ecuaciones de bloque ($$) y alinear a la izquierda las ecuaciones en línea ($)
        const xPosition = match[0].startsWith("$$")
          ? (doc.internal.pageSize.width - imgWidth) / 2
          : 10;

        doc.addImage(
          imgData,
          "PNG",
          xPosition,
          yPosition,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        yPosition += imgHeight + (match[0].startsWith("$$") ? 10 : 5); // Más espacio para ecuaciones de bloque
        console.log("Added equation image. New Y position:", yPosition);
      }

      lastIndex = match.index + match[0].length;
      equationIndex++;
    }

    console.log("Finished processing result text");

    // Añadir el texto restante después de la última fórmula
    const remainingText = removeMarkdown(resultText.slice(lastIndex));
    const remainingLines = doc.splitTextToSize(remainingText, 180);

    if (yPosition + remainingLines.length * 7 > pageHeight - pageMargin) {
      doc.addPage();
      yPosition = pageMargin;
    }

    doc.text(remainingLines, 10, yPosition);

    // Guardar el PDF
    const examDate = new Date(exam?.lastOCR).toISOString().slice(0, 10);
    if (index !== undefined && index !== null && index !== "") {
      doc.save(`Examen-${examDate}-ID${index + 1}.pdf`);
    } else {
      doc.save(`Examen-${examDate}.pdf`);
    }
  } else {
    console.log("Downloading PDF mobile: ", exam, index);

    const styles = StyleSheet.create({
      page: {
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        padding: 10,
      },
      logo: { width: 40, height: 40, margin: "20px auto" },
      header: { textAlign: "center", margin: 10 },
      line: {
        marginTop: 5,
        marginBottom: 10,
        borderWidth: 0.5,
        borderColor: "#000",
      },
      text: { margin: 10, fontSize: 12 },
      title: { fontSize: 14, fontWeight: "bold", margin: 10, color: "#404040" },
    });

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <Image
            src="https://app.fotoexamen.com/logo512.png"
            style={styles.logo}
          />
          <Text style={styles.header}>{texts.generatedBy}</Text>
          <View style={styles.line} />
          <Text style={styles.title}>{texts.statementLabel}</Text>
          <Text style={styles.text}>{exam.ocrText}</Text>
        </Page>
        <Page size="A4" style={styles.page}>
          <Text style={styles.title}>{texts.resultLabel}</Text>
          <Text style={styles.text}>{removeMarkdown(exam.response)}</Text>
        </Page>
      </Document>
    );

    try {
      const blob = await pdf(<MyDocument />).toBlob();
      try {
        saveAs(
          blob,
          `Examen-${new Date(exam.lastOCR).toISOString().slice(0, 10)}-ID.pdf`
        );
      } catch (error) {
        console.error(texts.errorSavingFileChrome, error);
      }
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        const path = `Download/Examen-${new Date(exam.lastOCR)
          .toISOString()
          .slice(0, 10)}.pdf`;
        let file = await Filesystem.writeFile({
          path: path,
          data: base64data,
          directory: Directory.ExternalStorage,
        });
        console.log("File saved successfully");
        try {
          await FileOpener.openFile({
            path: file.uri,
            mimeType: "application/pdf",
          });
        } catch (openError) {
          console.error(texts.errorOpeningFile, openError);
        }
      };
    } catch (error) {
      console.error(texts.errorGeneratingSavingPDF, error);
    }
  }
};

export const formatText = (text) => {
  if (typeof text !== "string") {
    console.error("formatText was called with a non-string argument", text);
    return text; // Return an empty string or handle the error as appropriate
  }
  return text.replace(/\\r\\n|\\r|\\n/g, "\n");
};

const formatTextWithNewlines = (text) => {
  return text.split("\n").map((item, index) => (
    <React.Fragment key={index}>
      {item}
      <br />
    </React.Fragment>
  ));
};

const ExamList = ({ setShowNoTokenModal }) => {
  const { currentLanguage } = useLanguage();
  const [exams, setExams] = useState([]);
  const [currentExams, setCurrentExams] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const examsPerPage = 10;
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchExams = async () => {
    setLoading(true);
    setError(null);
    let token = localStorage.getItem("token");
    if (!token) {
      const ret = await Preferences.get({ key: "token" });
      if (ret?.value) {
        token = ret?.value;
      }
    }

    try {
      if (!token) {
        console.error(texts[currentLanguage].noAuthenticationToken);
        setShowNoTokenModal(true);
        return;
      }
      const response = await fetch(`${apiUrl}/api/easyexams/exams`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Network response was not ok.");
      const data = await response.json();
      setExams(data.sort((a, b) => new Date(b.lastOCR) - new Date(a.lastOCR)));
      setCurrentPage(1);
    } catch (error) {
      setError(texts[currentLanguage].noExamsHistory);
      setExams([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  useEffect(() => {
    const indexOfLastExam = currentPage * examsPerPage;
    const indexOfFirstExam = indexOfLastExam - examsPerPage;
    setCurrentExams(exams.slice(indexOfFirstExam, indexOfLastExam));
  }, [currentPage, exams]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date) {
      const targetDate = date.toLocaleDateString("en-CA"); // 'en-CA' uses the format yyyy-mm-dd
      const filteredExams = exams.filter((exam) => {
        const examDate = new Date(exam.lastOCR).toLocaleDateString("en-CA");
        return examDate === targetDate;
      });
      setExams(filteredExams);
      setCurrentPage(1);
    } else {
      fetchExams(); // Reload all exams if date is cleared
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(exams.length / examsPerPage);

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={texts[currentLanguage].historyOfExams} />
      <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
        🖇️ {texts[currentLanguage].historyOfResolvedExams}
      </h1>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="yyyy/MM/dd"
        isClearable
        placeholderText={texts[currentLanguage].selectDatePlaceholder}
        className="p-2 w-full rounded bg-white text-black custom-datepicker border rounded"
      />
      {loading && <p className="mb-4">{texts[currentLanguage].loading}</p>}
      {error && <p className="mb-4 text-black">{error}</p>}
      {!loading &&
        !error &&
        currentExams.map((exam, index) => (
          <div
            key={index}
            className="collapse collapse-plus bg-base-200 rounded-box shadow my-2"
          >
            <input type="checkbox" />
            <div className="collapse-title text-xl font-medium">
              Examen "
              {exam.ocrText.split(" ").slice(0, 2).join(" ").toLowerCase()}" -{" "}
              {new Date(exam.lastOCR).toLocaleDateString("es-ES")} -{" "}
              {new Date(exam.lastOCR).toLocaleTimeString("es-ES")}
            </div>
            <div className="collapse-content bg-base-100 text-black p-4">
              <div className="font-semibold">{texts[currentLanguage].statementLabel}</div>
              <br />
              <p>{exam.ocrText}</p>
              {/* separator */}
              <hr className="my-4" />
              <div className="font-semibold mt-2">{texts[currentLanguage].resultLabel}</div>
              <br />
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <MarkdownPreview content={exam?.response} />
              </div>
              {isDownloading ? (
                <div className="flex flex-col justify-center items-center mb-2 mt-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-600"></div>
                  <p className="text-sm font-semibold mt-2">{texts[currentLanguage].downloadingPDF}</p>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsDownloading(true);
                    downloadPDF(exam, exam?.image ? exam?.image : "", texts[currentLanguage])
                      .finally(() => setIsDownloading(false));
                  }}
                  className="btn btn-primary mt-4"
                >
                  <FaFilePdf size={24} className="mr-2" /> {texts[currentLanguage].downloadPDF}
                </button>
              )}
            </div>
          </div>
        ))}
      <div className="mt-4 flex justify-between">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn btn-primary"
        >
          Anterior
        </button>
        <p className="text-lg">
          {texts[currentLanguage].pageText.replace("{{currentPage}}", currentPage).replace("{{totalPages}}", totalPages)}
        </p>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn btn-primary"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};
export default ExamList;