import React, { useState } from 'react';
import useAuth from './useAuthToken';
import { useLanguage } from './useLanguaje';

const UnlimitedButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token] = useAuth();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      trialEnded: 'Tu prueba ha terminado ahora puedes usar FotoExamen de forma ilimitada. Comprueba tu perfil para revisar tu subscripción. ¡Que lo disfrutes!',
      cardValidationFailed: 'La validación de la tarjeta falló. Por favor, verifica tu información de pago.',
      trialNotFound: 'No se encontró una suscripción de prueba activa.',
      requestError: 'Ocurrió un error al procesar tu solicitud.',
      unexpectedError: 'Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.',
      processing: 'Procesando...',
      activateSubscription: 'ACTIVAR SUSCRIPCIÓN ILIMITADA'
    },
    en: {
      trialEnded: 'Your trial has ended, you can now use PhotoExamAI unlimitedly. Check your profile to review your subscription. Enjoy!',
      cardValidationFailed: 'Card validation failed. Please check your payment information.',
      trialNotFound: 'No active trial subscription found.',
      requestError: 'An error occurred while processing your request.',
      unexpectedError: 'An unexpected error occurred. Please try again later.',
      processing: 'Processing...',
      activateSubscription: 'ACTIVATE UNLIMITED SUBSCRIPTION'
    },
    it: {
      trialEnded: 'La tua prova è terminata, ora puoi utilizzare RisolviCompiti illimitatamente. Controlla il tuo profilo per rivedere il tuo abbonamento. Buon divertimento!',
      cardValidationFailed: 'La convalida della carta è fallita. Verifica le tue informazioni di pagamento.',
      trialNotFound: 'Nessun abbonamento di prova attivo trovato.',
      requestError: "Si è verificato un errore durante l'elaborazione della tua richiesta.",
      unexpectedError: 'Si è verificato un errore imprevisto. Riprova più tardi.',
      processing: 'Elaborazione...',
      activateSubscription: 'ATTIVA ABBONAMENTO ILLIMITATO'
    }
  };

  const handleEndTrial = async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/easyexams/end_trial`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (response.ok) {
        alert(texts[currentLanguage].trialEnded);
        window.location.href = '/perfil';
      } else if (response.status === 400) {
        setErrorMessage(data.message || texts[currentLanguage].cardValidationFailed);
      } else if (response.status === 404) {
        setErrorMessage(data.error || texts[currentLanguage].trialNotFound);
      } else {
        setErrorMessage(data.error || texts[currentLanguage].requestError);
      }
    } catch (error) {
      console.error('Error ending trial:', error);
      setErrorMessage(texts[currentLanguage].unexpectedError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <button
        className="btn btn-primary w-full"
        onClick={handleEndTrial}
        disabled={isLoading}
      >
        {isLoading ? texts[currentLanguage].processing : texts[currentLanguage].activateSubscription}
      </button>
      {errorMessage && <p className="text-red-500 mt-2 text-center">{errorMessage}</p>}
    </div>
  );
};

export default UnlimitedButton;
