import { Preferences } from "@capacitor/preferences";
import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";

const apiUrl = process.env.REACT_APP_API_URL;

const UserProfile = ({ setShowNoTokenModal }) => {
  const [user, setUser] = useState({
    email: "",
    dateSuscriptionFinish: 5,
    stripeLink: "",
    subscriptionType: "",
    remainingCredits: 5,
  });
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [token, clearToken] = useAuth();
  const { currentLanguage } = useLanguage();

  const translations = {
    es: {
      userProfile: "Perfil del Usuario",
      email: "Email",
      data: "Datos",
      editData: "Editar mis datos",
      opinion: "Tu opinión",
      rateApp: "⭐️ Valorar FotoExamen",
      subscription: "Suscripción",
      subscribeNow: "¡Suscríbete ahora!",
      notAvailable: "No disponible",
      trialCredits: "Créditos Trial",
      updateNow: "¡Actualizar ahora!",
      manageSubscription: "Gestión de Suscripción",
      manageMySubscription: "Gestionar mi suscripción",
      otherPlans: "otros planes de subscripción",
      logout: "Salir",
      logoutButton: "Cerrar Sesión",
      notMeetingNeeds: "¿No cumple FotoExamen con tus ejercicios? Recuerda que tenemos",
      morePowerfulModels: "con modelos IA más potentes que pueden ayudarte en problemas más complejos.",
    },
    en: {
      userProfile: "User Profile",
      email: "Email",
      data: "Data",
      editData: "Edit my data",
      opinion: "Your opinion",
      rateApp: "⭐️ Rate PhotoExamAI",
      subscription: "Subscription",
      subscribeNow: "Subscribe now!",
      notAvailable: "Not available",
      trialCredits: "Trial Credits",
      updateNow: "Update now!",
      manageSubscription: "Manage Subscription",
      manageMySubscription: "Manage my subscription",
      otherPlans: "other subscription plans",
      logout: "Logout",
      logoutButton: "Log Out",
      notMeetingNeeds: "Is PhotoExamAI not meeting your needs? Remember we have",
      morePowerfulModels: "with more powerful AI models that can help with more complex problems.",
    },
    it: {
      userProfile: "Profilo Utente",
      email: "Email",
      data: "Dati",
      editData: "Modifica i miei dati",
      opinion: "La tua opinione",
      rateApp: "⭐️ Vota RisolviCompiti",
      subscription: "Abbonamento",
      subscribeNow: "Iscriviti ora!",
      notAvailable: "Non disponibile",
      trialCredits: "Crediti Trial",
      updateNow: "Aggiorna ora!",
      manageSubscription: "Gestisci Abbonamento",
      manageMySubscription: "Gestisci il mio abbonamento",
      otherPlans: "altri piani di abbonamento",
      logout: "Esci",
      logoutButton: "Esci",
      notMeetingNeeds: "RisolviCompiti non soddisfa le tue esigenze? Ricorda che abbiamo",
      morePowerfulModels: "modelli IA più potenti che possono aiutarti con problemi più complessi.",
    },
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    Preferences.remove({ key: "token" }).then(() => {
      if (currentLanguage === "en") {
        window.location.href = "/login";
      } else if (currentLanguage === "it") {
        window.location.href = "/accedi";
      } else {
        window.location.href = "/iniciar-sesion";
      }
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (token === "") return;
      if (!token) {
        setShowNoTokenModal(true);
        return;
      }
      const response = await fetch(apiUrl + "/api/easyexams/checktoken", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dataToken = await response.json();
      let userData = {};
      if (dataToken?.email) localStorage.setItem("email", dataToken.email);

      if ("dateSuscriptionFinish" in dataToken) {
        userData = {
          email: dataToken.email,
          dateSuscriptionFinish: dataToken.dateSuscriptionFinish,
          stripeLink: "https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII",
        };
      } else {
        userData = {
          email: dataToken.email,
          dateSuscriptionFinish: "",
          stripeLink: "https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII",
        };
      }

      try {
        const subscriptionResponse = await fetch(
          `${apiUrl}/api/easyexams/checkpremium`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (
          subscriptionResponse.ok &&
          subscriptionResponse.headers
            .get("Content-Type")
            ?.includes("application/json")
        ) {
          try {
            const jsonResponse = await subscriptionResponse.json();
            const subscriptionType = jsonResponse.subscriptionType;
            userData.subscriptionType = subscriptionType;
            userData.remainingCredits = jsonResponse.remainingCredits;
          } catch (error) {
            console.error("Error al analizar la respuesta JSON:", error);
          }
        } else {
          console.error(
            "La respuesta no es JSON o el servidor devolvió un error:",
            subscriptionResponse.status,
            await subscriptionResponse.text()
          );
        }

        setUser(userData);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserData();
  }, [token, setShowNoTokenModal]);

  return (
    <div className="container mx-auto p-5 mb-20 mt-4">
      <div className="bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="px-6 py-12">
          <h3 className="text-3xl leading-8 font-bold flex items-center ml-4 mt-4">
            <FaUserCircle className="inline-block h-10 w-10 mr-4" />
            {translations[currentLanguage].userProfile}
            {loading && (
              <div className="flex justify-center items-center ml-4">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              </div>
            )}
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
              <dt className="text-sm font-medium text-gray-500">
                {translations[currentLanguage].email}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-semibold">
                {user.email}
              </dd>
            </div>
            <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
              <dt className="text-sm font-medium text-gray-500">
                {translations[currentLanguage].data}
              </dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                <Link to={currentLanguage === "es" ? "/bienvenida" : currentLanguage === "it" ? "/benvenuto" : "/welcome"} className="btn btn-primary">
                  {translations[currentLanguage].editData}
                </Link>
              </dd>
            </div>
            {user.subscriptionType !== "No Subscription" && (
              <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
                <dt className="text-sm font-medium text-gray-500">
                  {translations[currentLanguage].opinion}
                </dt>
                <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                  <button
                    className="btn btn-accent"
                    onClick={() =>
                      document.getElementById("my_modal_3").showModal()
                    }
                  >
                    {translations[currentLanguage].rateApp}
                  </button>
                </dd>
              </div>
            )}
            <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
              <dt className="text-sm font-medium text-gray-500">
                {translations[currentLanguage].subscription}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {user.subscriptionType === "No Subscription" ? (
                  <Link to={currentLanguage === "en" ? "/view-prices" : currentLanguage === "it" ? "/vedi-prezzi" : "/ver-precios"} className="btn btn-accent">
                    {translations[currentLanguage].subscribeNow}
                  </Link>
                ) : (
                  <span className="font-bold">
                    {user.subscriptionType
                      ? `👑 ${user.subscriptionType}`
                      : translations[currentLanguage].notAvailable}
                  </span>
                )}
              </dd>
            </div>
            {user.subscriptionType?.includes("Trial") && (
              <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
                <dt className="text-sm font-medium text-gray-500">
                  {translations[currentLanguage].trialCredits}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center">
                  <span className="font-semibold mr-4 text-blue-600">
                    {user?.remainingCredits}
                  </span>
                  {user?.remainingCredits === 0 && (
                    <button
                      onClick={() =>
                        document.getElementById("trial_ended_modal").showModal()
                      }
                      className="btn btn-accent"
                    >
                      {translations[currentLanguage].updateNow}
                    </button>
                  )}
                </dd>
              </div>
            )}
            <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
              <dt className="text-sm font-medium text-gray-500">
                {translations[currentLanguage].manageSubscription}
              </dt>
              <dd className="mt-1">
                <a
                  href="https://billing.stripe.com/p/login/fZe4iW1cSe8y2cwbII"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  {translations[currentLanguage].manageMySubscription}
                </a>
                <p className="mt-3 text-gray-600 max-w-md">
                  {translations[currentLanguage].notMeetingNeeds}{" "}
                  <Link
                    to={currentLanguage === "en" ? "/view-prices" : currentLanguage === "it" ? "/vedi-prezzi" : "/ver-precios"}
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    {translations[currentLanguage].otherPlans}
                  </Link>{" "}
                  {translations[currentLanguage].morePowerfulModels}
                </p>
              </dd>
            </div>
            <div className="px-6 py-6 sm:grid sm:grid-cols-3 sm:gap-4 hover:bg-gray-50 transition-colors duration-200">
              <dt className="text-sm font-medium text-gray-500">
                {translations[currentLanguage].logout}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <button className="btn btn-outline" onClick={handleLogout}>
                  {translations[currentLanguage].logoutButton}
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
