import { useLanguage } from "../useLanguaje";

const SEONotetaker = () => {
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      section1: {
        title: "Tomar Apuntes con IA",
        content:
          "Revoluciona tu manera de tomar apuntes con nuestra inteligencia artificial avanzada. Sube un archivo de audio de tu clase, deja que nuestra IA lo transcriba y genera apuntes concisos y claros automáticamente. Esta herramienta es perfecta para estudiantes y profesionales que necesitan maximizar su eficiencia y garantizar la precisión de sus notas.",
        list: [
          "Sube archivos de audio de tus clases",
          "Transcripción automática y precisa del audio",
          "Generación de apuntes concisos y bien estructurados",
          "Ideal para estudiantes y profesionales",
        ],
      },
      section2: {
        title: "Cómo Funciona",
        content:
          "Nuestro sistema de toma de apuntes con IA es fácil de usar y altamente eficiente. Sube tu archivo de audio a nuestra plataforma y la IA se encargará de transcribir el contenido de manera precisa. Luego, utiliza nuestra herramienta para generar apuntes estructurados que te ayudarán a estudiar y revisar el material de manera efectiva.",
        list: [
          "Sube el archivo de audio de tu clase",
          "La IA transcribe el contenido automáticamente",
          "Genera apuntes bien organizados y claros",
        ],
      },
      section3: {
        title: "Ventajas de Tomar Apuntes con IA",
        list: [
          "Ahorro de tiempo en la toma de apuntes",
          "Precisión y claridad en los apuntes generados",
          "Facilidad para revisar y estudiar el material",
          "Reducción del esfuerzo manual en la transcripción",
          "Acceso a apuntes bien estructurados en cualquier momento",
        ],
      },
      section4: {
        title: "Aplicaciones de la Toma de Apuntes con IA",
        content:
          "Nuestra herramienta de toma de apuntes con IA es versátil y útil en una variedad de contextos académicos y profesionales. Ya sea que estés en la universidad, en una conferencia o en una reunión importante, esta herramienta te permitirá obtener apuntes precisos y organizados sin esfuerzo.",
        list: [
          "Estudiantes en clases y conferencias",
          "Profesionales en reuniones y presentaciones",
          "Investigadores y académicos en seminarios",
          "Cualquiera que necesite apuntes precisos y bien estructurados",
        ],
      },
      section5: {
        title: "Mejora tu Toma de Apuntes con IA",
        content:
          "Nuestra herramienta de toma de apuntes con IA está diseñada para transformar la manera en que capturas y organizas información. Sube tu archivo de audio, permite que la IA lo transcriba y genere apuntes concisos y claros para ti. Ya sea que estés estudiando para un examen, asistiendo a una conferencia o participando en una reunión, esta herramienta te ayudará a obtener apuntes perfectos de manera rápida y eficiente. ¡Empieza a usar nuestra herramienta de toma de apuntes con IA hoy mismo y lleva tu estudio y trabajo al siguiente nivel!",
      },
    },
    it: {
      section1: {
        title: "Prendi Appunti con l'IA",
        content:
          "Rivoluziona il tuo modo di prendere appunti con la nostra intelligenza artificiale avanzata. Carica un file audio della tua lezione, lascia che la nostra IA lo trascriva e generi appunti concisi e chiari automaticamente. Questo strumento è perfetto per studenti e professionisti che hanno bisogno di massimizzare la loro efficienza e garantire l'accuratezza dei loro appunti.",
        list: [
          "Carica file audio delle tue lezioni",
          "Trascrizione automatica e precisa dell'audio",
          "Generazione di appunti concisi e ben strutturati",
          "Ideale per studenti e professionisti",
        ],
      },
      section2: {
        title: "Come Funziona",
        content:
          "Il nostro sistema di presa appunti con IA è facile da usare e altamente efficiente. Carica il tuo file audio sulla nostra piattaforma e l'IA si occuperà di trascrivere il contenuto in modo preciso. Quindi, utilizza il nostro strumento per generare appunti strutturati che ti aiuteranno a studiare e rivedere il materiale in modo efficace.",
        list: [
          "Carica il file audio della tua lezione",
          "L'IA trascrive il contenuto automaticamente",
          "Genera appunti ben organizzati e chiari",
        ],
      },
      section3: {
        title: "Vantaggi di Prendere Appunti con l'IA",
        list: [
          "Risparmio di tempo nella presa di appunti",
          "Precisione e chiarezza negli appunti generati",
          "Facilità di revisione e studio del materiale",
          "Riduzione dello sforzo manuale nella trascrizione",
          "Accesso ad appunti ben strutturati in qualsiasi momento",
        ],
      },
      section4: {
        title: "Applicazioni della Presa Appunti con l'IA",
        content:
          "Il nostro strumento di presa appunti con IA è versatile e utile in una varietà di contesti accademici e professionali. Che tu sia all'università, a una conferenza o in un'importante riunione, questo strumento ti permetterà di ottenere appunti precisi e organizzati senza sforzo.",
        list: [
          "Studenti in lezioni e conferenze",
          "Professionisti in riunioni e presentazioni",
          "Ricercatori e accademici in seminari",
          "Chiunque abbia bisogno di appunti precisi e ben strutturati",
        ],
      },
      section5: {
        title: "Migliora la Tua Presa Appunti con l'IA",
        content:
          "Il nostro strumento di presa appunti con IA è progettato per trasformare il modo in cui catturi e organizzi le informazioni. Carica il tuo file audio, lascia che l'IA lo trascriva e generi appunti concisi e chiari per te. Che tu stia studiando per un esame, assistendo a una conferenza o partecipando a una riunione, questo strumento ti aiuterà ad ottenere appunti perfetti in modo rapido ed efficiente. Inizia a utilizzare il nostro strumento di presa appunti con IA oggi stesso e porta il tuo studio e lavoro al livello successivo!",
      },
    },
    en: {
      section1: {
        title: "Take Notes with AI",
        content:
          "Revolutionize your way of taking notes with our advanced artificial intelligence. Upload an audio file of your class, let our AI transcribe it, and automatically generate concise and clear notes. This tool is perfect for students and professionals who need to maximize their efficiency and ensure the accuracy of their notes.",
        list: [
          "Upload audio files from your classes",
          "Automatic and accurate audio transcription",
          "Generation of concise and well-structured notes",
          "Ideal for students and professionals",
        ],
      },
      section2: {
        title: "How It Works",
        content:
          "Our AI note-taking system is easy to use and highly efficient. Upload your audio file to our platform, and the AI will accurately transcribe the content. Then, use our tool to generate structured notes that will help you study and review the material effectively.",
        list: [
          "Upload your class audio file",
          "The AI transcribes the content automatically",
          "Generates well-organized and clear notes",
        ],
      },
      section3: {
        title: "Advantages of Taking Notes with AI",
        list: [
          "Time-saving in note-taking",
          "Accuracy and clarity in generated notes",
          "Ease of reviewing and studying the material",
          "Reduction of manual effort in transcription",
          "Access to well-structured notes at any time",
        ],
      },
      section4: {
        title: "Applications of AI Note-Taking",
        content:
          "Our AI note-taking tool is versatile and useful in a variety of academic and professional contexts. Whether you're at university, in a conference, or in an important meeting, this tool will allow you to obtain precise and organized notes effortlessly.",
        list: [
          "Students in classes and conferences",
          "Professionals in meetings and presentations",
          "Researchers and academics in seminars",
          "Anyone needing precise and well-structured notes",
        ],
      },
      section5: {
        title: "Enhance Your Note-Taking with AI",
        content:
          "Our AI note-taking tool is designed to transform the way you capture and organize information. Upload your audio file, let the AI transcribe it, and generate concise and clear notes for you. Whether you're studying for an exam, attending a conference, or participating in a meeting, this tool will help you obtain perfect notes quickly and efficiently. Start using our AI note-taking tool today and take your study and work to the next level!",
      },
    },
  };

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {texts[currentLanguage].section1.title}
        </h2>
        <p className="mb-4 text-gray-600">
          {texts[currentLanguage].section1.content}
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {texts[currentLanguage].section1.list.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {texts[currentLanguage].section2.title}
        </h2>
        <p className="mb-4 text-gray-600">
          {texts[currentLanguage].section2.content}
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {texts[currentLanguage].section2.list.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {texts[currentLanguage].section3.title}
        </h2>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {texts[currentLanguage].section3.list.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {texts[currentLanguage].section4.title}
        </h2>
        <p className="mb-4 text-gray-600">
          {texts[currentLanguage].section4.content}
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          {texts[currentLanguage].section4.list.map((item, index) => (
            <li key={index} className="mb-2">
              {item}
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4 text-gray-700">
          {texts[currentLanguage].section5.title}
        </h2>
        <p className="text-gray-600">
          {texts[currentLanguage].section5.content}
        </p>
      </section>
    </div>
  );
};

export default SEONotetaker;
