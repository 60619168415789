import { useState } from "react";
import { useLanguage } from "../useLanguaje";

const texts = {
  es: {
    title:
      "Crea Ensayos, Trabajos y Tesis con IA: Tu Compañero de Escritura Inteligente",
    sections: [
      {
        heading:
          "Generación de Ensayos con IA: Tu Asistente Personal de Escritura",
        content:
          "¿Atascado con tu ensayo? ¡No te preocupes! FotoExamen usa inteligencia artificial de última generación para ayudarte a crear ensayos sobre cualquier tema. Olvídate de las horas frustrantes intentando estructurar tus ideas. Nuestra IA analiza tu tema, te proporciona un esquema detallado y te ayuda a desarrollar un ensayo coherente y bien argumentado. ¡Es como tener un tutor personal disponible 24/7!",
        list: [
          "Generación instantánea de ensayos sobre cualquier tema",
          "Estructuras claras y argumentos bien desarrollados",
          "Adaptado a todos los niveles: desde secundaria hasta doctorado",
        ],
      },
      {
        heading: "Trabajos de Clase: De la Idea a la Entrega en Tiempo Récord",
        content:
          "¿Se acerca la fecha límite de tu trabajo y aún no has empezado? Tranquilo, nuestra IA está aquí para echarte una mano. Ya sea un análisis literario, un informe científico o un estudio de caso, nuestra herramienta te ayudará a crear trabajos de clase impresionantes en un abrir y cerrar de ojos. ¡Tus profesores quedarán boquiabiertos!",
        list: [
          "Creación rápida de trabajos para cualquier asignatura",
          "Investigación y síntesis de información en segundos",
          "Adaptación al estilo y formato requerido por tu profesor",
        ],
      },
      {
        heading: "Proyectos Fin de Carrera: Tu Pasaporte al Éxito Académico",
        content:
          "El proyecto fin de carrera puede ser intimidante, ¡pero no con FotoExamen a tu lado! Nuestra IA te ayudará a conceptualizar, estructurar y desarrollar tu proyecto de manera eficiente. Desde la introducción hasta la conclusión, pasando por la metodología y el análisis de resultados, te guiaremos en cada paso del camino. ¡Prepárate para brillar en tu presentación final!",
        list: [
          "Asistencia en la elección y delimitación del tema",
          "Generación de esquemas detallados y marcos teóricos",
          "Ayuda en la redacción de cada sección del proyecto",
        ],
      },
      {
        heading: "Elaboración de Tesis: Tu Doctorado al Alcance de un Clic",
        content:
          "¿Sueñas con obtener tu doctorado pero te abruma la idea de escribir una tesis? ¡No desesperes! FotoExamen está aquí para convertir ese sueño en realidad. Nuestra IA te ayudará a navegar por el complejo proceso de elaboración de una tesis doctoral, desde la revisión de literatura hasta la discusión de resultados. ¡Prepárate para revolucionar tu campo de estudio!",
        list: [
          "Asistencia en la formulación de hipótesis y objetivos de investigación",
          "Ayuda en la estructuración de capítulos y secciones",
          "Sugerencias para la argumentación y defensa de tus ideas",
        ],
      },
      {
        heading: "¿Por Qué Elegir FotoExamen para Tus Trabajos Académicos?",
        content:
          "FotoExamen no es solo otra herramienta de escritura, ¡es tu compañero de estudios definitivo! Imagina tener un genio de la escritura en tu bolsillo, listo para ayudarte en cualquier momento. Desde ensayos rápidos hasta tesis doctorales, nuestra IA está aquí para hacer tu vida académica más fácil, divertida y exitosa.",
        list: [
          "Ahorra tiempo y reduce el estrés académico",
          "Mejora tus habilidades de escritura y argumentación",
          "Obtén inspiración y supera el bloqueo del escritor",
          "Adapta el contenido a tu estilo personal",
        ],
      },
      {
        heading: "Más Allá de la Escritura: Tu Asistente Académico Integral",
        content:
          "FotoExamen no se detiene en la generación de texto. ¿Necesitas ayuda con las referencias bibliográficas? ¿Buscas ideas para tu próxima presentación? ¿Quieres revisar la gramática y el estilo de tu trabajo? Nuestra IA multifacética está aquí para cubrir todas tus necesidades académicas. ¡Es como tener un equipo completo de profesores y tutores a tu disposición!",
        list: [
          "Generación automática de bibliografías en diversos estilos",
          "Sugerencias para presentaciones impactantes",
          "Revisión y corrección de estilo y gramática",
          "Generación de resúmenes y palabras clave",
        ],
      },
    ],
    faqs: [
      {
        question: "¿Cómo funciona FotoExamen para crear ensayos?",
        answer:
          "FotoExamen utiliza inteligencia artificial avanzada para analizar tu tema, generar un esquema detallado y desarrollar un ensayo coherente y bien argumentado. Solo necesitas proporcionar el tema y algunos detalles, ¡y la IA se encarga del resto!",
      },
      {
        question: "¿Es legal usar FotoExamen para mis trabajos académicos?",
        answer:
          "FotoExamen es una herramienta de asistencia para la escritura y el aprendizaje. Te recomendamos que consultes las políticas de tu institución sobre el uso de herramientas de IA. Siempre es importante usar FotoExamen como un apoyo para tu propio trabajo y no como un sustituto de tu esfuerzo personal.",
      },
      {
        question: "¿Puede FotoExamen detectar plagio?",
        answer:
          "Aunque FotoExamen no es primariamente una herramienta de detección de plagio, nuestro sistema está diseñado para generar contenido original. Sin embargo, siempre recomendamos que revises y personalices el contenido generado, y que lo pases por un detector de plagio si tu institución lo requiere.",
      },
      {
        question: "¿Qué tipos de trabajos puedo crear con FotoExamen?",
        answer:
          "FotoExamen es versátil y puede ayudarte con una amplia gama de trabajos académicos, incluyendo ensayos, trabajos de clase, proyectos de fin de carrera, tesis doctorales, y más. Desde breves informes hasta extensas investigaciones, ¡FotoExamen está listo para asistirte!",
      },
      {
        question:
          "¿Cómo asegura FotoExamen la calidad de los trabajos generados?",
        answer:
          "Nuestra IA está entrenada con una vasta base de conocimientos académicos y sigue las mejores prácticas de escritura. Además, constantemente actualizamos y mejoramos nuestros algoritmos para garantizar la calidad y relevancia del contenido generado.",
      },
    ],
    lastpart:
      "¿Listo para revolucionar tu vida académica? ¡Prueba FotoExamen hoy y descubre el poder de la IA en tus estudios!",
  },
  it: {
    title:
      "Crea Saggi, Lavori e Tesi con IA: Il Tuo Compagno di Scrittura Intelligente",
    sections: [
      {
        heading:
          "Generazione di Saggi con IA: Il Tuo Assistente Personale di Scrittura",
        content:
          "Bloccato con il tuo saggio? Non preoccuparti! RisolviCompiti utilizza intelligenza artificiale all'avanguardia per aiutarti a creare saggi su qualsiasi argomento. Dimentica le ore frustranti a cercare di strutturare le tue idee. La nostra IA analizza il tuo argomento, fornisce uno schema dettagliato e ti aiuta a sviluppare un saggio coerente e ben argomentato. È come avere un tutor personale disponibile 24/7!",
        list: [
          "Generazione istantanea di saggi su qualsiasi argomento",
          "Strutture chiare e argomenti ben sviluppati",
          "Adattato a tutti i livelli: dalla scuola superiore al dottorato",
        ],
      },
      {
        heading: "Compiti in Classe: Dall'Idea alla Consegna in Tempo Record",
        content:
          "La scadenza del tuo compito si avvicina e non hai ancora iniziato? Rilassati, la nostra IA è qui per darti una mano. Che si tratti di un'analisi letteraria, un rapporto scientifico o uno studio di caso, il nostro strumento ti aiuterà a creare compiti in classe impressionanti in un batter d'occhio. I tuoi insegnanti rimarranno stupiti!",
        list: [
          "Creazione rapida di compiti per qualsiasi materia",
          "Ricerca e sintesi delle informazioni in pochi secondi",
          "Adattamento allo stile e al formato richiesto dal tuo insegnante",
        ],
      },
      {
        heading:
          "Progetti di Fine Anno: Il Tuo Passaporto per il Successo Accademico",
        content:
          "Il progetto di fine anno può essere scoraggiante, ma non con RisolviCompiti al tuo fianco! La nostra IA ti aiuterà a concettualizzare, strutturare e sviluppare il tuo progetto in modo efficiente. Dall'introduzione alla conclusione, inclusa la metodologia e l'analisi dei risultati, ti guideremo in ogni fase del percorso. Preparati a brillare nella tua presentazione finale!",
        list: [
          "Assistenza nella selezione e delimitazione dell'argomento",
          "Generazione di schemi dettagliati e quadri teorici",
          "Aiuto nella scrittura di ogni sezione del progetto",
        ],
      },
      {
        heading: "Scrittura di Tesi: Il Tuo Dottorato a Portata di Click",
        content:
          "Sogni di ottenere il tuo dottorato ma sei sopraffatto dall'idea di scrivere una tesi? Non disperare! RisolviCompiti è qui per trasformare quel sogno in realtà. La nostra IA ti aiuterà a navigare nel complesso processo di scrittura di una tesi di dottorato, dalla revisione della letteratura alla discussione dei risultati. Preparati a rivoluzionare il tuo campo di studio!",
        list: [
          "Assistenza nella formulazione di ipotesi e obiettivi di ricerca",
          "Aiuto nella strutturazione di capitoli e sezioni",
          "Suggerimenti per l'argomentazione e la difesa delle tue idee",
        ],
      },
      {
        heading:
          "Perché Scegliere RisolviCompiti per i Tuoi Lavori Accademici?",
        content:
          "RisolviCompiti non è solo un altro strumento di scrittura, è il tuo compagno di studi definitivo! Immagina di avere un genio della scrittura in tasca, pronto ad aiutarti in qualsiasi momento. Dai saggi rapidi alle tesi di dottorato, la nostra IA è qui per rendere la tua vita accademica più facile, divertente e di successo.",
        list: [
          "Risparmia tempo e riduci lo stress accademico",
          "Migliora le tue abilità di scrittura e argomentazione",
          "Ottieni ispirazione e supera il blocco dello scrittore",
          "Adatta il contenuto al tuo stile personale",
        ],
      },
      {
        heading: "Oltre la Scrittura: Il Tuo Assistente Accademico Integrale",
        content:
          "RisolviCompiti non si ferma alla generazione di testo. Hai bisogno di aiuto con le referenze bibliografiche? Cerchi idee per la tua prossima presentazione? Vuoi rivedere la grammatica e lo stile del tuo lavoro? La nostra IA multifunzionale è qui per soddisfare tutte le tue esigenze accademiche. È come avere un intero team di professori e tutor a tua disposizione!",
        list: [
          "Generazione automatica di bibliografie in vari stili",
          "Suggerimenti per presentazioni d'impatto",
          "Revisione e correzione di stile e grammatica",
          "Generazione di riassunti e parole chiave",
        ],
      },
    ],
    faqs: [
      {
        question: "Come funziona RisolviCompiti per creare saggi?",
        answer:
          "RisolviCompiti utilizza intelligenza artificiale avanzata per analizzare il tuo argomento, generare uno schema dettagliato e sviluppare un saggio coerente e ben argomentato. Devi solo fornire l'argomento e alcuni dettagli, e l'IA si occupa del resto!",
      },
      {
        question: "È legale usare RisolviCompiti per i miei lavori accademici?",
        answer:
          "RisolviCompiti è uno strumento di assistenza per la scrittura e l'apprendimento. Ti consigliamo di consultare le politiche della tua istituzione sull'uso di strumenti di IA. È sempre importante usare RisolviCompiti come supporto per il tuo lavoro e non come sostituto del tuo impegno personale.",
      },
      {
        question: "RisolviCompiti può rilevare il plagio?",
        answer:
          "Sebbene RisolviCompiti non sia principalmente uno strumento di rilevamento del plagio, il nostro sistema è progettato per generare contenuti originali. Tuttavia, consigliamo sempre di rivedere e personalizzare il contenuto generato e di passarlo attraverso un rilevatore di plagio se la tua istituzione lo richiede.",
      },
      {
        question: "Che tipi di lavori posso creare con RisolviCompiti?",
        answer:
          "RisolviCompiti è versatile e può aiutarti con una vasta gamma di lavori accademici, inclusi saggi, compiti in classe, progetti di fine anno, tesi di dottorato e altro. Dai brevi rapporti alle ricerche estese, RisolviCompiti è pronto ad assisterti!",
      },
      {
        question:
          "Come assicura RisolviCompiti la qualità dei lavori generati?",
        answer:
          "La nostra IA è addestrata con una vasta base di conoscenze accademiche e segue le migliori pratiche di scrittura. Inoltre, aggiorniamo e miglioriamo costantemente i nostri algoritmi per garantire la qualità e la rilevanza del contenuto generato.",
      },
    ],
    lastpart:
      "Pronto a rivoluzionare la tua vita accademica? Prova RisolviCompiti oggi e scopri il potere dell'IA nei tuoi studi!",
  },
  en: {
    title:
      "Create Essays, Papers, and Theses with AI: Your Intelligent Writing Companion",
    sections: [
      {
        heading: "Essay Generation with AI: Your Personal Writing Assistant",
        content:
          "Stuck with your essay? Don't worry! PhotoExamAI uses cutting-edge artificial intelligence to help you create essays on any topic. Forget about the frustrating hours trying to structure your ideas. Our AI analyzes your topic, provides a detailed outline, and helps you develop a coherent and well-argued essay. It's like having a personal tutor available 24/7!",
        list: [
          "Instant essay generation on any topic",
          "Clear structures and well-developed arguments",
          "Adapted to all levels: from high school to PhD",
        ],
      },
      {
        heading: "Class Assignments: From Idea to Submission in Record Time",
        content:
          "Is your assignment deadline approaching and you haven't started yet? Relax, our AI is here to lend a hand. Whether it's a literary analysis, a scientific report, or a case study, our tool will help you create impressive class assignments in no time. Your teachers will be amazed!",
        list: [
          "Quick creation of assignments for any subject",
          "Research and information synthesis in seconds",
          "Adaptation to the style and format required by your teacher",
        ],
      },
      {
        heading: "Final Year Projects: Your Passport to Academic Success",
        content:
          "The final year project can be daunting, but not with PhotoExamAI by your side! Our AI will help you conceptualize, structure, and develop your project efficiently. From introduction to conclusion, including methodology and results analysis, we'll guide you every step of the way. Get ready to shine in your final presentation!",
        list: [
          "Assistance in topic selection and delimitation",
          "Generation of detailed outlines and theoretical frameworks",
          "Help in writing each section of the project",
        ],
      },
      {
        heading: "Thesis Writing: Your PhD at the Click of a Button",
        content:
          "Dreaming of earning your PhD but overwhelmed by the idea of writing a thesis? Don't despair! PhotoExamAI is here to turn that dream into reality. Our AI will help you navigate the complex process of writing a doctoral thesis, from literature review to results discussion. Get ready to revolutionize your field of study!",
        list: [
          "Assistance in formulating hypotheses and research objectives",
          "Help in structuring chapters and sections",
          "Suggestions for argumentation and defense of your ideas",
        ],
      },
      {
        heading: "Why Choose PhotoExamAI for Your Academic Work?",
        content:
          "PhotoExamAI is not just another writing tool, it's your ultimate study companion! Imagine having a writing genius in your pocket, ready to help you at any moment. From quick essays to doctoral theses, our AI is here to make your academic life easier, more fun, and successful.",
        list: [
          "Save time and reduce academic stress",
          "Improve your writing and argumentation skills",
          "Get inspiration and overcome writer's block",
          "Adapt content to your personal style",
        ],
      },
      {
        heading: "Beyond Writing: Your Comprehensive Academic Assistant",
        content:
          "PhotoExamAI doesn't stop at text generation. Need help with bibliographic references? Looking for ideas for your next presentation? Want to review the grammar and style of your work? Our multifaceted AI is here to cover all your academic needs. It's like having a full team of teachers and tutors at your disposal!",
        list: [
          "Automatic bibliography generation in various styles",
          "Suggestions for impactful presentations",
          "Style and grammar review and correction",
          "Generation of summaries and keywords",
        ],
      },
    ],
    faqs: [
      {
        question: "How does PhotoExamAI work for creating essays?",
        answer:
          "PhotoExamAI uses advanced artificial intelligence to analyze your topic, generate a detailed outline, and develop a coherent and well-argued essay. You just need to provide the topic and some details, and the AI takes care of the rest!",
      },
      {
        question: "Is it legal to use PhotoExamAI for my academic work?",
        answer:
          "PhotoExamAI is a writing and learning assistance tool. We recommend that you check your institution's policies on the use of AI tools. It's always important to use PhotoExamAI as a support for your own work and not as a substitute for your personal effort.",
      },
      {
        question: "Can PhotoExamAI detect plagiarism?",
        answer:
          "While PhotoExamAI is not primarily a plagiarism detection tool, our system is designed to generate original content. However, we always recommend that you review and customize the generated content, and run it through a plagiarism detector if your institution requires it.",
      },
      {
        question: "What types of work can I create with PhotoExamAI?",
        answer:
          "PhotoExamAI is versatile and can help you with a wide range of academic work, including essays, class assignments, final year projects, doctoral theses, and more. From brief reports to extensive research, PhotoExamAI is ready to assist you!",
      },
      {
        question:
          "How does PhotoExamAI ensure the quality of the generated work?",
        answer:
          "Our AI is trained with a vast base of academic knowledge and follows best writing practices. Additionally, we constantly update and improve our algorithms to ensure the quality and relevance of the generated content.",
      },
    ],
    lastpart:
      "Ready to revolutionize your academic life? Try PhotoExamAI today and discover the power of AI in your studies!",
  },
};

const SEOEssayCreator = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  const { currentLanguage } = useLanguage();
  const content = texts[currentLanguage] || texts.es;

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(index);
    }
  };

  return (
    <div className="mt-8 p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-4xl font-bold mb-6 text-gray-800">{content.title}</h2>

      {content.sections.map((section, index) => (
        <section className="mb-8" key={index}>
          <h3 className="text-3xl font-semibold mb-4 text-gray-700">
            {section.heading}
          </h3>
          <p className="mb-4 text-gray-600">{section.content}</p>
          <ul className="list-disc list-inside mb-4 text-gray-600">
            {section.list.map((item, idx) => (
              <li className="mb-2" key={idx}>
                {item}
              </li>
            ))}
          </ul>
        </section>
      ))}

      <section className="mt-12">
        <h3 className="text-3xl font-semibold mb-6 text-gray-700">
          Preguntas Frecuentes sobre FotoExamen
        </h3>
        <div className="space-y-4">
          {content.faqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 pb-4">
              <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => toggleFAQ(index)}
              >
                <span className="text-lg font-medium text-gray-900">
                  {faq.question}
                </span>
                <span className="ml-6 flex-shrink-0">
                  {openFAQ === index ? (
                    <svg
                      className="h-6 w-6 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 15l7-7 7 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="h-6 w-6 text-gray-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </span>
              </button>
              {openFAQ === index && (
                <div className="mt-2 pr-12">
                  <p className="text-base text-gray-500">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      <p className="text-xl font-semibold text-gray-700">
        {texts[currentLanguage].lastpart}
      </p>
    </div>
  );
};

export default SEOEssayCreator;
