import React from 'react';
import { useLanguage } from "./useLanguaje";

export default function FAQ() {
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      title: "Preguntas frecuentes",
      questions: [
        "¿Cómo puedo cancelar mi suscripción?",
        "¿Cuáles son los métodos de pago aceptados?",
        "¿Ofrecen reembolsos?",
        "¿Qué incluye mi suscripción?",
        "¿Con qué frecuencia se factura?",
        "¿Hay un período de prueba gratuito?",
        "¿Hay alguna ventaja en elegir la suscripción anual?",
        "¿Cómo puedo gestionar mi suscripción?",
        "¿Es seguro el proceso de pago?"
      ],
      answers: [
        "Puede cancelar su suscripción en cualquier momento desde la configuración de su cuenta. La cancelación será efectiva al final del período de facturación actual.",
        "Aceptamos tarjetas de crédito y débito de las principales marcas, así como PayPal.",
        "Sí, ofrecemos un reembolso completo si cancela dentro de los primeros 15 días de su suscripción.",
        "Su suscripción incluye acceso completo a todas nuestras funciones premium, actualizaciones regulares y soporte al cliente prioritario.",
        "Ofrecemos opciones de facturación mensual y anual. Puede elegir la que mejor se adapte a sus necesidades.",
        "Si ofrecemos un período de prueba gratuito de 3 días y además puede solicitar un reembolso en los 15 primeros días si no está satisfecho con nuestro servicio.",
        "Sí, al suscribirse al plan anual, el precio es más de un 50% más barato, lo que equivale a más de 6 meses gratis en comparación con el precio mensual regular. Asegúrese de seleccionar el plan anual para ahorrar una cantidad significativa de dinero. Esta es nuestra forma de agradecerle su confianza en nuestro servicio y nos permite reinvertir su dinero para mejorar el producto.",
        "Puede gestionar su suscripción en cualquier momento. Vaya a la parte superior de la página cuando haya iniciado sesión y haga clic en 'Facturación'. Será redirigido al portal de facturación de Stripe donde podrá cambiar de plan, descargar facturas y cancelar su suscripción si lo desea.",
        "Sí, nuestro servicio de pago es seguro. Utilizamos Stripe para el procesamiento financiero y no almacenamos ninguna información sobre su tarjeta. Stripe garantiza estándares de seguridad a nivel bancario."
      ]
    },
    en: {
      title: "Frequently Asked Questions",
      questions: [
        "How can I cancel my subscription?",
        "What payment methods are accepted?",
        "Do you offer refunds?",
        "What does my subscription include?",
        "How often is billing?",
        "Is there a free trial period?",
        "Is there any advantage to choosing the annual subscription?",
        "How can I manage my subscription?",
        "Is the payment process secure?"
      ],
      answers: [
        "You can cancel your subscription at any time from your account settings. The cancellation will be effective at the end of the current billing period.",
        "We accept major credit and debit cards, as well as PayPal.",
        "Yes, we offer a full refund if you cancel within the first 15 days of your subscription.",
        "Your subscription includes full access to all our premium features, regular updates, and priority customer support.",
        "We offer monthly and annual billing options. You can choose the one that best suits your needs.",
        "Yes, we offer a 3-day free trial and you can also request a refund within the first 15 days if you are not satisfied with our service.",
        "Yes, by subscribing to the annual plan, the price is more than 50% cheaper, which is equivalent to more than 6 months free compared to the regular monthly price. Be sure to select the annual plan to save a significant amount of money. This is our way of thanking you for your trust in our service and allows us to reinvest your money to improve the product.",
        "You can manage your subscription at any time. Go to the top of the page when logged in and click on 'Billing'. You will be redirected to the Stripe billing portal where you can change plans, download invoices, and cancel your subscription if you wish.",
        "Yes, our payment service is secure. We use Stripe for financial processing and do not store any information about your card. Stripe guarantees bank-level security standards."
      ]
    },
    it: {
      title: "Domande frequenti",
      questions: [
        "Come posso cancellare il mio abbonamento?",
        "Quali metodi di pagamento sono accettati?",
        "Offrite rimborsi?",
        "Cosa include il mio abbonamento?",
        "Con quale frequenza viene effettuata la fatturazione?",
        "Esiste un periodo di prova gratuito?",
        "C'è qualche vantaggio nello scegliere l'abbonamento annuale?",
        "Come posso gestire il mio abbonamento?",
        "Il processo di pagamento è sicuro?"
      ],
      answers: [
        "Puoi cancellare il tuo abbonamento in qualsiasi momento dalle impostazioni del tuo account. La cancellazione sarà effettiva alla fine del periodo di fatturazione corrente.",
        "Accettiamo le principali carte di credito e di debito, nonché PayPal.",
        "Sì, offriamo un rimborso completo se annulli entro i primi 15 giorni del tuo abbonamento.",
        "Il tuo abbonamento include l'accesso completo a tutte le nostre funzionalità premium, aggiornamenti regolari e supporto clienti prioritario.",
        "Offriamo opzioni di fatturazione mensile e annuale. Puoi scegliere quella più adatta alle tue esigenze.",
        "Sì, offriamo un periodo di prova gratuito di 3 giorni e puoi anche richiedere un rimborso entro i primi 15 giorni se non sei soddisfatto del nostro servizio.",
        "Sì, sottoscrivendo il piano annuale, il prezzo è più del 50% più conveniente, il che equivale a più di 6 mesi gratuiti rispetto al prezzo mensile regolare. Assicurati di selezionare il piano annuale per risparmiare una somma significativa di denaro. Questo è il nostro modo di ringraziarti per la fiducia nel nostro servizio e ci consente di reinvestire i tuoi soldi per migliorare il prodotto.",
        "Puoi gestire il tuo abbonamento in qualsiasi momento. Vai nella parte superiore della pagina quando hai effettuato l'accesso e fai clic su 'Fatturazione'. Verrai reindirizzato al portale di fatturazione di Stripe dove potrai cambiare piano, scaricare fatture e cancellare il tuo abbonamento se lo desideri.",
        "Sì, il nostro servizio di pagamento è sicuro. Utilizziamo Stripe per l'elaborazione finanziaria e non memorizziamo alcuna informazione sulla tua carta. Stripe garantisce standard di sicurezza di livello bancario."
      ]
    }
  };

  const content = texts[currentLanguage];

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">{content.title}</h2>
        <div className="space-y-4">
          {content.questions.map((question, index) => (
            <div key={index} className="collapse collapse-plus bg-base-200">
              <input type="radio" name="my-accordion-3" />
              <div className="collapse-title text-xl font-medium">
                {question}
              </div>
              <div className="collapse-content">
                <p>{content.answers[index]}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
