import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import { downloadPDF } from "./ExamList.js";
import MarkdownPreview from "./MarkdownPreview.js";
import SEOCorrect from "./seo/SEOCorrect.js";
import { useSubscription } from "./SubscriptionContext.js";
import useAuth from "./useAuthToken.js";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile.js";

const apiUrl = process.env.REACT_APP_API_URL;

const texts = {
  title: {
    es: "Corrige tu examen",
    en: "Correct Your Exam",
    it: "Correggi il tuo esame",
  },
  description: {
    es: "Utiliza nuestra inteligencia artificial personalizada para corregir todos tus exámenes, sube una imagen o un PDF de tu examen resuelto. Edita el texto detectado si encuentras algún error y presiona corregir examen cuando estés listo. Puedes ver el resultado o descargarlo en formato PDF.",
    en: "Use our personalized artificial intelligence to correct all your exams, upload an image or a PDF of your completed exam. Edit the detected text if you find any errors and press correct exam when you are ready. You can view the result or download it in PDF format.",
    it: "Utilizza la nostra intelligenza artificiale personalizzata per correggere tutti i tuoi esami, carica un'immagine o un PDF del tuo esame completato. Modifica il testo rilevato se trovi errori e premi correggi esame quando sei pronto. Puoi visualizzare il risultato o scaricarlo in formato PDF.",
  },
  uploadExam: {
    es: "Sube tu examen resuelto:",
    en: "Upload your completed exam:",
    it: "Carica il tuo esame completato:",
  },
  takePhoto: {
    es: "Tomar Foto",
    en: "Take Photo",
    it: "Scatta una foto",
  },
  uploadFile: {
    es: "Subir Archivo",
    en: "Upload File",
    it: "Carica file",
  },
  detectedText: {
    es: "Texto detectado:",
    en: "Detected Text:",
    it: "Testo rilevato:",
  },
  detectingText: {
    es: "Detectando el texto del archivo o imagen...",
    en: "Detecting text from file or image...",
    it: "Rilevamento del testo dal file o dall'immagine...",
  },
  correctingExam: {
    es: "Corrigiendo examen con nuestra IA avanzada...",
    en: "Correcting exam with our advanced AI...",
    it: "Correzione esame con la nostra IA avanzata...",
  },
  correctExam: {
    es: "Corregir Examen",
    en: "Correct Exam",
    it: "Correggi Esame",
  },
  usageRecommendations: {
    es: "Recomendaciones de uso",
    en: "Usage Recommendations",
    it: "Raccomandazioni d'uso",
  },
  correctionResult: {
    es: "Resultado de la corrección:",
    en: "Correction Result:",
    it: "Risultato della correzione:",
  },
  downloadPDF: {
    es: "Descargar PDF",
    en: "Download PDF",
    it: "Scarica PDF",
  },
  downloadingPDF: {
    es: "Descargando PDF...",
    en: "Downloading PDF...",
    it: "Download PDF...",
  },
  spentCredits: {
    es: "Gastaste tus créditos gratis",
    en: "You have spent your free credits",
    it: "Hai speso i tuoi crediti gratuiti",
  },
  wantToContinue: {
    es: "¿Quieres seguir usando Corrección de Examen? Descubre los beneficios increíbles de nuestros planes premium:",
    en: "Do you want to continue using Exam Correction? Discover the incredible benefits of our premium plans:",
    it: "¿Vuoi continuare a utilizzare la Correzione Esami? Scopri gli incredibili vantaggi dei nostri piani premium:",
  },
  premiumBenefits: {
    es: [
      "🚀 Acceso ilimitado a correcciones de exámenes.",
      "💡 Soporte prioritario para ayudarte cuando más lo necesitas.",
      "🔐 Procesamiento prioritario de tus solicitudes.",
      "🎓 Herramientas avanzadas para un aprendizaje más eficaz.",
      "💼 Recursos adicionales para prepararte para tus exámenes.",
    ],
    en: [
      "🚀 Unlimited access to exam corrections.",
      "💡 Priority support to help you when you need it most.",
      "🔐 Priority processing of your requests.",
      "🎓 Advanced tools for more effective learning.",
      "💼 Additional resources to prepare for your exams.",
    ],
    it: [
      "🚀 Accesso illimitato alle correzioni degli esami.",
      "💡 Supporto prioritario per aiutarti quando più ne hai bisogno.",
      "🔐 Elaborazione prioritaria delle tue richieste.",
      "🎓 Strumenti avanzati per un apprendimento più efficace.",
      "💼 Risorse aggiuntive per prepararti agli esami.",
    ],
  },
  investInSuccess: {
    es: "¡Invierte en tu éxito académico, elige un plan premium hoy y siente la diferencia!",
    en: "Invest in your academic success, choose a premium plan today and feel the difference!",
    it: "Investi nel tuo successo accademico, scegli un piano premium oggi e senti la differenza!",
  },
  viewPlans: {
    es: "Ver planes 💲",
    en: "View Plans 💲",
    it: "Visualizza i piani 💲",
  },
  tipsForPlatform: {
    es: "Tips para usar la plataforma",
    en: "Tips for using the platform",
    it: "Consigli per utilizzare la piattaforma",
  },
  close: {
    es: "Cerrar",
    en: "Close",
    it: "Chiudi",
  },
  tipsList: {
    es: [
      "Fotografía clara: Asegúrate de tomar fotos claras donde el texto esté completamente horizontal. Esto mejora la precisión con la que la plataforma puede interpretar y procesar la información. Además la imagen no debe superar los 20Mb y los formatos aceptados son: 'png', 'jpeg', 'gif', 'webp'",
      "Repetir el proceso: No dudes en corregir varias veces la misma foto. A veces, la plataforma puede ofrecer resultados ligeramente diferentes, por lo que es útil hacer varias pruebas para obtener la mejor respuesta.",
      "Razonamiento en matemáticas: En exámenes de matemáticas, es crucial no solo fiarse de las correcciones automáticas. Toma los razonamientos que te ofrece la plataforma y luego verifica los cálculos manualmente para asegurarte de que son correctos.",
      'Usa los <Link to="/tutores" className="text-blue-600 hover:text-blue-800 underline hover:underline decoration-blue-500 hover:decoration-blue-700 transition duration-300 ease-in-out">tutores</Link>: Si tienes dudas sobre alguna respuesta, aprovecha la sección de tutores de la plataforma. Puedes subir el ejercicio en cuestión y hacer todas las preguntas que necesites para aclarar tus dudas y entender mejor el material.',
    ],
    en: [
      "Clear photograph: Make sure to take clear photos where the text is completely horizontal. This improves the accuracy with which the platform can interpret and process information. Additionally, the image must not exceed 20MB and the accepted formats are: 'png', 'jpeg', 'gif', 'webp'",
      "Repeat the process: Don't hesitate to correct the same photo multiple times. Sometimes, the platform may offer slightly different results, so it's useful to conduct several tests to obtain the best response.",
      "Mathematical reasoning: In math exams, it's crucial not to rely solely on automatic corrections. Take the reasoning provided by the platform and then manually verify the calculations to ensure they are correct.",
      'Use the <Link to="/tutores" className="text-blue-600 hover:text-blue-800 underline hover:underline decoration-blue-500 hover:decoration-blue-700 transition duration-300 ease-in-out">tutors</Link>: If you have doubts about any answer, take advantage of the platform’s tutors section. You can upload the exercise in question and ask all the questions you need to clarify your doubts and better understand the material.',
    ],
    it: [
      "Foto chiara: Assicurati di scattare foto chiare in cui il testo sia completamente orizzontale. Questo migliora la precisione con cui la piattaforma può interpretare ed elaborare le informazioni. Inoltre, l'immagine non deve superare i 20 MB e i formati accettati sono: 'png', 'jpeg', 'gif', 'webp'",
      "Ripeti il processo: Non esitare a correggere più volte la stessa foto. A volte, la piattaforma può offrire risultati leggermente diversi, quindi è utile effettuare diversi test per ottenere la migliore risposta.",
      "Ragionamento matematico: Negli esami di matematica, è fondamentale non affidarsi solo alle correzioni automatiche. Prendi i ragionamenti offerti dalla piattaforma e poi verifica manualmente i calcoli per assicurarti che siano corretti.",
      'Utilizza i <Link to="/tutores" className="text-blue-600 hover:text-blue-800 underline hover:underline decoration-blue-500 hover:decoration-blue-700 transition duration-300 ease-in-out">tutor</Link>: Se hai dubbi su una risposta, approfitta della sezione tutor della piattaforma. Puoi caricare l\'esercizio in questione e fare tutte le domande necessarie per chiarire i tuoi dubbi e comprendere meglio il materiale.',
    ],
  },
  pdfTooLarge: {
    es: "El PDF de tu exámen debe tener menos de 45 páginas ya que es demasiado grande para procesar.",
    en: "Your exam PDF must have fewer than 45 pages as it is too large to process.",
    it: "Il PDF del tuo esame deve avere meno di 45 pagine perché è troppo grande per essere elaborato.",
  },
  ocrError: {
    es: "No se pudo detectar el texto en la imagen. Asegúrese de que su imagen tenga un tamaño inferior a 20 MB y tenga uno de los siguientes formatos: .png, .jpeg, .gif o .webp.",
    en: "Could not detect text in the image. Please ensure your image is under 20 MB and is in one of the following formats: .png, .jpeg, .gif, or .webp.",
    it: "Impossibile rilevare il testo nell'immagine. Assicurati che la tua immagine abbia una dimensione inferiore a 20 MB e abbia uno dei seguenti formati: .png, .jpeg, .gif o .webp.",
  },
  unsupportedFileType: {
    es: "Tipo de archivo no admitido. Sube una imagen o un archivo PDF.",
    en: "Unsupported file type. Please upload an image or a PDF file.",
    it: "Tipo di file non supportato. Carica un'immagine o un file PDF.",
  },
  socketConnectionError: {
    es: "No se pudo establecer la conexión. Por favor, vuelve a intentarlo.",
    en: "Could not establish connection. Please try again.",
    it: "Impossibile stabilire la connessione. Riprova.",
  },
};

const ExamCorrector = ({ setShowNoTokenModal }) => {
  const { currentLanguage } = useLanguage();
  const [image, setImage] = useState(null);
  const [imagebin, setImagebin] = useState(null);
  const [ocrText, setOcrText] = useState("");
  const [examResult, setExamResult] = useState("");
  const [isStripeModalVisible, setIsStripeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [isLoadingOcr, setIsLoadingOcr] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const [isDownloading, setIsDownloading] = useState(false);
  const mobile = useMobile();

  const location = useLocation();
  const stripeModalRef = useRef(null);
  const socketRef = useRef(null);
  const messageCount = useRef(0);

  const connectSocket = (onFormResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("form_response", (data) => {
      onFormResponse(data);
    });

    socket.on("form_end", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  useEffect(() => {
    setExamData({
      ocrText: ocrText,
      response: examResult,
      lastOCR: new Date(),
    });
  }, [ocrText, examResult]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        stripeModalRef.current &&
        !stripeModalRef.current.contains(event.target)
      ) {
        setIsStripeModalVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageChange = async (e) => {
    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      /* window.location.href="/ver-precios" */
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById("trial_ended_modal").showModal();
      return;
    }

    setIsLoadingOcr(true);
    const selectedFile = e.target.files[0];

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoadingOcr(false);
      return;
    }

    try {
      if (selectedFile.type === "application/pdf") {
        setImage(null);
        setImagebin(null);
        setPdfUrl(selectedFile);

        const pdf = await pdfjsLib.getDocument(
          URL.createObjectURL(selectedFile)
        ).promise;

        if (pdf.numPages > 45) {
          setIsLoading(false);
          setIsLoadingOcr(false);
          alert(
            texts.pdfTooLarge[currentLanguage]
          );
          return;
        }

        let textContent = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const text = await page.getTextContent();
          text.items.forEach((item) => {
            textContent += item.str + " ";
          });
        }
        setOcrText(textContent);
        setPdfPreview(URL.createObjectURL(selectedFile));
        setIsLoadingOcr(false);
      } else if (selectedFile.type.startsWith("image/")) {
        setPdfUrl(null);
        setPdfPreview(null);
        setImage(URL.createObjectURL(selectedFile));
        setImagebin(selectedFile);

        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(apiUrl + "/api/easyexams/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.nowpay) {
            window.location.href = "https://www.fotoexamen.com/precios.html";
          } else {
            setIsLoadingOcr(false);
            setOcrText(responseData.texts);
          }
        } else {
          setIsLoadingOcr(false);
          alert(
            texts.ocrError[currentLanguage]
          );
        }
      } else {
        alert(texts.unsupportedFileType[currentLanguage]);
        setIsLoadingOcr(false);
      }
    } catch (error) {
      console.error("Error processing the file:", error);
      setIsLoadingOcr(false);
    }
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200); // Reintentar después de 200ms
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleCorrectExam = async () => {
    setIsLoading(true);
    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    socketRef.current = connectSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setExamResult(data?.form_response);
        }
        if (data?.thinking) {
          setExamResult(data?.thinking);
        }
      },
      (data) => {
        setExamResult(data?.form_end);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
      refreshSubscription();
    } catch (error) {
      alert(
        texts.socketConnectionError[currentLanguage]
      );
      setIsLoading(false);
      return;
    }

    const data = {
      token: token,
      form: {
        ocrText: ocrText,
      },
      files: {},
    };

    if (imagebin) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.image = base64Data;
        socketRef.current.emit("form_correct", data);
      };
      reader.readAsDataURL(imagebin);
    } else if (pdfUrl) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1]; // Get the base64 string without the prefix
        data.files.pdf = base64Data;
        socketRef.current.emit("form_correct", data);
      };
      reader.readAsDataURL(pdfUrl);
    } else {
      socketRef.current.emit("form_correct", data);
    }
  };

  const showStripeModal = () => {
    setIsStripeModalVisible(true);
  };

  const toggleTipsModal = () => {
    setShowTipsModal(!showTipsModal);
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={texts.title[currentLanguage]} />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            ✍🏻 {texts.title[currentLanguage]}
          </h1>
          <p>
            {texts.description[currentLanguage]}
          </p>
        </div>

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">{texts.uploadExam[currentLanguage]}</h2>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <input
              type="file"
              accept="image/*,application/pdf"
              capture="environment"
              className="hidden"
              onChange={handleImageChange}
              id="photo-capture"
            />
            <label
              htmlFor="photo-capture"
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faCamera} className="fa-lg mr-2" />
              {texts.takePhoto[currentLanguage]}
            </label>

            <input
              type="file"
              accept="image/*,application/pdf"
              onChange={handleImageChange}
              className="file-input file-input-bordered w-full max-w-xs"
            />
          </div>
        </div>

        {(image || pdfPreview) && (
          <div className="flex items-center justify-center mb-16">
            <dialog id="previewModal" className="modal">
              <div className="modal-box">
                {image && (
                  <img
                    src={image}
                    alt={texts.title[currentLanguage]}
                    className="max-w-full h-auto"
                  />
                )}
                {pdfPreview && (
                  <iframe
                    src={pdfPreview}
                    title="PDF Preview"
                    className="w-full h-96"
                  ></iframe>
                )}
                <div className="modal-action">
                  <button
                    className="btn btn-outline"
                    onClick={() =>
                      document.getElementById("previewModal").close()
                    }
                  >
                    {texts.close[currentLanguage]}
                  </button>
                </div>
              </div>
            </dialog>
            <div
              onClick={() =>
                document.getElementById("previewModal").showModal()
              }
              className="btn mt-3 rounded h-64 w-full object-cover object-top flex items-center justify-center overflow-hidden"
              style={{ background: "#f0f0f0" }}
            >
              {image ? (
                <img
                  src={image}
                  alt={texts.title[currentLanguage]}
                  className="max-w-full h-auto"
                />
              ) : (
                <FaFilePdf size={64} />
              )}
            </div>
          </div>
        )}

        {ocrText !== "" && (
          <div className="mb-8">
            <h2 className="text-2xl font-bold mb-4">{texts.detectedText[currentLanguage]}</h2>
            <textarea
              className="w-full h-64 border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line"
              value={ocrText}
              onChange={(e) => setOcrText(e.target.value)}
            ></textarea>
          </div>
        )}

        {isLoadingOcr && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              {texts.detectingText[currentLanguage]}
            </p>
          </div>
        )}
        {isLoading && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              {texts.correctingExam[currentLanguage]}
            </p>
          </div>
        )}
        <div className="divider" />
        <div className="mb-8">
          <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <button
              className={`btn btn-primary ${
                (!ocrText.trim() || isLoading) && "opacity-50 cursor-not-allowed"
              }`}
              onClick={handleCorrectExam}
              disabled={!ocrText.trim() || isLoading}
            >
              {texts.correctExam[currentLanguage]}
            </button>
            <button
              onClick={toggleTipsModal}
              className="btn btn-outline"
            >
              {texts.usageRecommendations[currentLanguage]}
            </button>
          </div>

          {examResult !== "" && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">
                {texts.correctionResult[currentLanguage]}
              </h2>
              {isDownloading ? (
                <div className="flex flex-col justify-center items-center mb-2 mt-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-red-600"></div>
                  <p className="text-sm font-semibold mt-2">
                    {texts.downloadingPDF[currentLanguage]}
                  </p>
                </div>
              ) : (
                <button
                  onClick={() => {
                    setIsDownloading(true);
                    downloadPDF(examData).finally(() => setIsDownloading(false));
                  }}
                  className="btn btn-primary mb-4"
                >
                  <FaFilePdf size={24} className="mr-2" /> {texts.downloadPDF[currentLanguage]}
                </button>
              )}
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line">
                <MarkdownPreview content={examResult} />                
              </div>
            </div>
          )}
        </div>
        {isStripeModalVisible && (
          <div
            id="stripeModal"
            ref={stripeModalRef}
            className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
              <div className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
                  <h2 className="text-lg sm:text-xl font-bold mb-4">
                    {texts.spentCredits[currentLanguage]}
                  </h2>
                  <p className="mb-4 text-sm sm:text-base">
                    {texts.wantToContinue[currentLanguage]}
                  </p>
                  <ul className="list-disc list-inside text-left mb-4 text-sm sm:text-base">
                    {texts.premiumBenefits[currentLanguage].map((benefit, index) => (
                      <li key={index} className="mb-2">
                        {benefit}
                      </li>
                    ))}
                  </ul>
                  <p className="mb-4 font-bold text-sm sm:text-base">
                    {texts.investInSuccess[currentLanguage]}
                  </p>
                  <button
                    id="view-prices"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded transition duration-300 ease-in-out shadow-lg transform hover:scale-105"
                    onClick={showStripeModal}
                  >
                    {texts.viewPlans[currentLanguage]}
                  </button>
                </div>
              </div>

              <button onClick={showStripeModal}>{texts.viewPlans[currentLanguage]}</button>
            </div>
          </div>
        )}

        {showTipsModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg shadow-lg">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">
                  {texts.tipsForPlatform[currentLanguage]}
                </p>
                <div className="cursor-pointer z-50" onClick={toggleTipsModal}>
                  <svg
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
                  </svg>
                </div>
              </div>
              <ul className="list-disc list-inside text-left text-md lg:text-lg mb-4 leading-relaxed">
                {texts.tipsList[currentLanguage].map((tip, index) => (
                  <li key={index}>{tip}</li>
                ))}
              </ul>
              <div className="flex justify-end pt-2">
                <button
                  className="px-4 btn btn-primary"
                  onClick={toggleTipsModal}
                >
                  {texts.close[currentLanguage]}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!token && !mobile && <SEOCorrect />}
    </div>
  );
};

export default ExamCorrector;